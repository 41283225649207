import axios from 'axios';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import Icon, { Icons } from '@app/common/components/Icon';
import useComponentId from '@app/hooks/useComponentId';
import { LEASE_STATUS_DRAFT } from '../../../../actions/dashboard';
import { setCurrentDocument, setPdfGeneratedState } from '../../../../actions/document';
import { IconSize } from '../../../../common/components/Icon.tsx';
import ToggleButtonV2, { LabelPosition } from '../../../../common/components/ToggleButtonV2.tsx';
import Tooltip from '../../../../common/components/tooltips/Tooltip.tsx';
import { FormEmail } from '../../../../components/form/FormEmail';
import { FormPhone } from '../../../../components/form/FormPhone';
import FormSection from '../../../../components/form/FormSection';
import { FormTextRegular, HiddenInput } from '../../../../components/form/FormText';
import ContactSearch from '../../../../components/search/ContactSearch';
import { CONTACT_TYPE_AUTO_SAVE, DOCUMENT_CUSTOM, DOCUMENT_DELIVERY_TYPE_SMS } from '../../../../config';
import { PDF_GEN_PENDING } from '../../../../constants/constants';
import { isDocumentEditMode, isDocumentStatusDraft } from '../../../../utils/generalUtils';
import { reloadAnnotationsAfterDeletingClient } from '../../../PdfViewer/utils.ts';

export const RecipientSection = ({
    currentDocument,
    values,
    shouldDisableAddingRecipients,
    isCustomPlaceholdersEnabled,
    isReadOnly,
    isSubmitting,
    documentEditMode,
    setFormData,
    setIsDirty,
    setIsSubmitting,
    uploadedDocumentStateItems,
    customDocumentId,
    isSequentialSigningEnabled
}) => {
    const id = useComponentId();
    const CUSTOM_DOCUMENT_URL = `api/document/custom-document/${customDocumentId}`;

    const dispatch = useDispatch();

    function addNewEmptyClient(values) {
        const data = cloneDeep(values);
        dispatch(setPdfGeneratedState(PDF_GEN_PENDING));
        data.clients.push({
            name: '',
            email: '',
            Phone: '',
            source: CONTACT_TYPE_AUTO_SAVE
        });
        setFormData(data);
        setIsDirty(true);
    }

    function handleSearchSelect(result, values) {
        const data = cloneDeep(values);
        if (data.clients && data.clients[0] && data.clients[0].name === '') {
            data.clients[0] = {
                name: result.fullName || result.corporationName,
                email: result.email,
                phone: result.mobile
            };
        } else {
            data.clients.push({
                name: result.fullName || result.corporationName,
                email: result.email,
                phone: result.mobile
            });
        }
        setFormData(data);
        setIsDirty(true);
    }

    const removeClient = (values, client, index) => {
        if (client.id) {
            setIsSubmitting(true);
            const url = `${CUSTOM_DOCUMENT_URL}/remove-client/${client.id}`;
            axios.post(url).then(response => {
                setIsSubmitting(false);
                if (response.data.customDocument) {
                    reloadAnnotationsAfterDeletingClient(
                        response.data.customDocument.uploadedDocuments,
                        uploadedDocumentStateItems
                    );
                    dispatch(setCurrentDocument(response.data.customDocument));
                }
            });
        } else {
            let newState = cloneDeep(values);
            setIsDirty(true);
            if (index === 0 && newState.clients && newState.clients.length === 1) {
                newState.clients[0] = { name: '', email: '', phone: '+61' };
                return setFormData(newState);
            }
            return setFormData({
                ...newState,
                clients: [...newState.clients.slice(0, index), ...newState.clients.slice(index + 1)]
            });
        }
    };

    return (
        <>
            {currentDocument && isDocumentStatusDraft(currentDocument.status) && (
                <ContactSearch
                    searchBarClassName="contact-search"
                    onSelect={result => handleSearchSelect(result, values)}
                    allowStaffSearch={true}
                    disabled={shouldDisableAddingRecipients}
                    contactType={DOCUMENT_CUSTOM}
                    toolTipText={
                        shouldDisableAddingRecipients
                            ? 'You have used placeholders for the recipient to enter information. This feature is currently only available with 1 recipient only.'
                            : 'Type to search all contacts imported'
                    }
                />
            )}
            <br />
            {isSequentialSigningEnabled && (
                <div className="enforce-signing-order">
                    <ToggleButtonV2
                        name="enforceSequentialSigning"
                        labelPosition={LabelPosition.End}
                        label="Sign documents in Sequential Order"
                        disabled={isReadOnly}
                    />

                    <Icon
                        icon={Icons.INFO}
                        size={IconSize.SMALL}
                        className="enforce-signing-order__info-icon"
                        data-tip="Your recipients listed below will be receiving and signing their documents one at a a time depending on the order."
                        data-for={`info-tooltip-${id}`}
                    />
                    <Tooltip id={`info-tooltip-${id}`} effect="solid" place="bottom" />
                </div>
            )}
            <br />
            <div className="client-section">
                {values.clients.map((client, index) => (
                    <FormSection
                        key={index}
                        title={`${isCustomPlaceholdersEnabled ? 'Recipient' : 'Client'} ${index + 1}`}
                        showRemoveButton={!isReadOnly}
                        removeButtonProps={{
                            onClick: () => {
                                removeClient(values, client, index);
                            },
                            label: 'Remove',
                            loading: isSubmitting
                        }}
                    >
                        <HiddenInput name="source" />
                        <FormTextRegular
                            name={`clients[${index}].name`}
                            label={`Name of ${isCustomPlaceholdersEnabled ? 'recipient' : 'client'}`}
                            disabled={isReadOnly}
                            required
                        />
                        <FormEmail
                            name={`clients[${index}].email`}
                            label="Email"
                            disabled={isReadOnly && currentDocument.status !== LEASE_STATUS_DRAFT}
                            required
                        />
                        {values.deliveryType === DOCUMENT_DELIVERY_TYPE_SMS && (
                            <FormPhone
                                name={`clients[${index}].phone`}
                                className={`clients-${index}-phone`}
                                label="Phone"
                                disabled={isReadOnly && currentDocument.status !== LEASE_STATUS_DRAFT}
                                required
                            />
                        )}
                    </FormSection>
                ))}
                {isDocumentEditMode(documentEditMode) && values.clients && values.clients.length < 4 && (
                    <div className="button" data-tip={true}>
                        <button
                            data-tip="You have used placeholders for the recipient to enter information. This feature is currently only available with 1 recipient."
                            data-for="signer-placeholders-tooltip"
                            disabled={isReadOnly || shouldDisableAddingRecipients}
                            className="add-client custom-document__add-client-button"
                            type="button"
                            onClick={() => {
                                addNewEmptyClient(values);
                            }}
                        >
                            {isCustomPlaceholdersEnabled ? 'Add recipient' : 'Add client'}
                        </button>
                        {shouldDisableAddingRecipients && (
                            <Tooltip id="signer-placeholders-tooltip" place="bottom" effect="solid" />
                        )}
                    </div>
                )}
            </div>
            <br />
        </>
    );
};
