import React from 'react';
import cx from 'classnames';
import Card, { CardShadow, CardStyles } from '../common/components/cards/Card';
import Icon, { Icons } from '../common/components/Icon';
import Tooltip from '../common/components/tooltips/Tooltip';
import { FormTextMultiline } from './form/FormText.js';
import useComponentId from '../hooks/useComponentId';
import styles from './ClientAcknowledgement.module.scss';

type ClientAcknowledgementProps = {
    shouldShowMandatoryStatement?: boolean;
    isReadOnly?: boolean;
    className?: string;
    isCustomPlaceholdersEnabled?: boolean;
};

const MANDATORY_STATEMENT_TOOLTIP_TEXT = 'We add this statement to the end of the signing experience by default';

const ClientAcknowledgement: React.FC<ClientAcknowledgementProps> = ({
    shouldShowMandatoryStatement,
    isReadOnly,
    className,
    isCustomPlaceholdersEnabled
}) => {
    const tooltipId = useComponentId().toString();
    const headingTextClient = isCustomPlaceholdersEnabled ? 'Recipient' : 'Client'; 
    const headingText = shouldShowMandatoryStatement
        ? `${headingTextClient} acknowledgement statements`
        : `${headingTextClient} acknowledgement statement`;

    return (
        <section className={cx(styles.container, className)}>
            <h2 className={styles.heading}>{headingText}</h2>
            {shouldShowMandatoryStatement && (
                <>
                    <h3 className={styles.subheading}>Mandatory statement</h3>
                    <Card className={styles.mandatoryStatement} style={CardStyles.SQUARE} shadow={CardShadow.SMALL}>
                        <span>I agree to sign this document electronically</span>
                        <Icon
                            icon={Icons.HELP}
                            className={styles.icon}
                            data-for={tooltipId}
                            data-tip={MANDATORY_STATEMENT_TOOLTIP_TEXT}
                        />
                        <Tooltip place="top" effect="solid" id={tooltipId} className={styles.tooltip} />
                    </Card>
                    <h3 className={styles.subheading}>Add acknowledgement statement</h3>
                </>
            )}
            <FormTextMultiline name="acknowledgementText" disabled={isReadOnly} />
        </section>
    );
};

export default ClientAcknowledgement;
