import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '../../common/components/ModalDialog';
import '../../sass/conditions.scss';
import { FormTextMultiline } from '../form/FormText';
import { Form } from 'react-final-form';
import Button from '../../common/components/Button';
import { getDocumentToCancel, getIsCancelReasonModalOpen, getIsCancelClone } from '../../selectors/document';
import { closeCancelReasonModal, cancelDocumentSuccess, openDocumentForm } from '../../actions/document';
import axios from 'axios';
import { useRollbarProvider } from '../../components/providers/RollbarProvider';
import { DOCUMENT_CREATE_MODE } from '../../config';
import { cloneDeep } from 'lodash';
import { LEASE_STATUS_DRAFT } from '../../actions/dashboard';
import { useHistory } from 'react-router-dom';
import { ToastTypes } from '../../common/components/Toast';
import useToast from '../../hooks/useToast';

const ModalReasonCancelDocument = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isCancelReasonModalOpen = useSelector(getIsCancelReasonModalOpen);
    const documentToCancel = useSelector(getDocumentToCancel);
    const isCancelClone = useSelector(getIsCancelClone);
    const Rollbar = useRollbarProvider();
    const { addNewToast } = useToast();

    const closeModal = () => {
        dispatch(closeCancelReasonModal());
    };

    const cancelDocumentRequest = (documentId, reason) => {
        return axios.post(`/api/document/cancel/${documentId}`, reason);
    };

    const handleCancelDocument = values => {
        if (values.reason) {
            return cancelDocumentRequest(documentToCancel.id, { reason: values.reason })
                .then(result => {
                    dispatch(cancelDocumentSuccess());
                    addNewToast(`Your ${documentToCancel.docType} has been cancelled.`, ToastTypes.SUCCESS);
                    if (isCancelClone) {
                        const data = cloneDeep(documentToCancel);
                        delete data.id;
                        history.push(`/dashboard/documents/${data.docType}/${LEASE_STATUS_DRAFT}`);
                        dispatch(openDocumentForm(data.docType, data, DOCUMENT_CREATE_MODE));
                    }
                })
                .catch(error => {
                    Rollbar.error(
                        'Failed to cancel Basic document',
                        {
                            error_message: error.message,
                            status: 'error',
                            env: ENVIRONMENT
                        },
                        error
                    );
                });
        } else {
            let errors = {};
            errors.reason = 'Reason is required';
            return errors;
        }
    };

    return (
        <ModalDialog
            title="Cancel Reason"
            isOpen={isCancelReasonModalOpen}
            closeModal={closeModal}
            shouldCloseOnOverlayClick={false}
            zIndex={20}
        >
            <Form initialValues={{}} onSubmit={handleCancelDocument}>
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <FormTextMultiline name="reason" label="Reason" />
                            <div className="warning-message-block">
                                <div>
                                    <Button primary type="submit">
                                        <span>Cancel Document</span>
                                    </Button>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>

    );
};
export default memo(ModalReasonCancelDocument);
