import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { includes, isEmpty } from 'lodash';
import { getUserInfo, getAgencyLocation } from '../selectors/user';
import { getAgencyInfo, getAgencyPropertyMeIntegration, getAgencyPropertyTreeIntegration } from '../selectors/agency';
import { openLeaseAgreementForm } from '../actions/lease';
import { openDocumentForm } from '../actions/document';
import { openFlkAKeyForm } from '../actions/flkAKey';
import { syncAccountIntegration } from '../actions/account';
import * as dashboard from '../actions/dashboard';
import { isAgencyUserAccount } from '../utils/userUtils';
import { useAccountProvider } from '../components/providers/AccountProvider';
import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_SALES,
    getAvailableStates,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_TERMINATION_NOTICE,
    DOCUMENT_RENT_INCREASE,
    STATUS_ACTIVE,
    DOCUMENT_CUSTOM,
    DISCLOSURE_DOCUMENT,
    DOCUMENT_CREATE_A_FLK,
    BREACH_NOTICE,
    DOCUMENT_CREATE_MODE,
    SUB_LEASE_TYPE_LONG_TERM_RENEWAL,
    SUB_LEASE_TYPE_SHORT_TERM_RENEWAL,
    LEASE_TYPE_COMMERCIAL_LEASE,
    ENTRY_NOTICE,
    INTENTION_TO_SELL_DOCUMENT
} from '../config';

type LeaseAgreementType = LEASE_TYPE_PROPERTY_MANAGEMENT | LEASE_TYPE_RESIDENTIAL | LEASE_TYPE_SALES;
type SubLeaseType = SUB_LEASE_TYPE_LONG_TERM_RENEWAL | SUB_LEASE_TYPE_SHORT_TERM_RENEWAL;

type DocumentType =
    | BREACH_NOTICE
    | DOCUMENT_CREATE_A_FLK
    | DOCUMENT_CUSTOM
    | DOCUMENT_RENT_INCREASE
    | DOCUMENT_RENT_REDUCTION
    | DOCUMENT_TERMINATION_NOTICE
    | DISCLOSURE_DOCUMENT
    | ENTRY_NOTICE
    | INTENTION_TO_SELL_DOCUMENT;

const useOpenCreationModal = (afterOpenCreationModal?: () => void) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const accountType = useAccountProvider();
    const loggedInUser = useSelector(getUserInfo);
    const agency = useSelector(getAgencyInfo);
    const agencyLocation = useSelector(getAgencyLocation);
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);

    const isLeaseTypeEnabled = (documentType: LeaseAgreementType | DocumentType): boolean => {
        if (documentType && isAgencyUserAccount(accountType)) {
            return includes(getAvailableStates(documentType), agencyLocation);
        } else {
            return true;
        }
    };
    const isActiveUser = (): boolean => {
        const isActiveUser = loggedInUser.status === STATUS_ACTIVE;
        const isActiveSubscription = agency && !isEmpty(agency.tariffPlan) && agency.tariffPlan.active;
        return isActiveUser && isActiveSubscription;
    };

    const syncIntegrations = () => {
        // We intentionally do not sync managed
        if (propertyMeIntegration || propertyTreeIntegration) {
            dispatch(syncAccountIntegration());
        }
    };

    const openLeaseAgreementCreationModal = (leaseAgreementType: LeaseAgreementType, keepSameUrl: boolean) => {
        if (!isLeaseTypeEnabled(leaseAgreementType) || !isActiveUser()) {
            return;
        }
        if (afterOpenCreationModal) afterOpenCreationModal();
        // Navigate to creation modal
        if (!keepSameUrl) {
            history.push(
                `/dashboard/agreements/${leaseAgreementType}/${dashboard.agreementStatuses[leaseAgreementType][0]}`
            );
        }
        dispatch(openLeaseAgreementForm(leaseAgreementType));
        if (leaseAgreementType === LEASE_TYPE_RESIDENTIAL || leaseAgreementType === LEASE_TYPE_COMMERCIAL_LEASE) {
            syncIntegrations();
        }
    };

    const openDocumentCreationModal = (documentType: DocumentType, files = []) => {
        if (!isLeaseTypeEnabled(documentType) || !isActiveUser()) {
            return;
        }
        if (afterOpenCreationModal) {
            afterOpenCreationModal();
        }
        if (documentType === DOCUMENT_CUSTOM || documentType === DOCUMENT_CREATE_A_FLK) {
            // We use a query for Create a FLK and FLK a PDF so we can open the modal by navigating to the route
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${documentType}/${dashboard.agreementStatuses[documentType][0]}?create=${documentType}`
            );
        } else {
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${documentType}/${dashboard.agreementStatuses[documentType][0]}`
            );
        }
        dispatch(openDocumentForm(documentType, null, DOCUMENT_CREATE_MODE, false, files));
        syncIntegrations();
    };

    const openFlkAKeyCreationModal = () => {
        // Same but for Flk a key
        if (afterOpenCreationModal) afterOpenCreationModal();
        history.push(`/dashboard/flk-a-key/${dashboard.FLK_A_KEY_STATUS_DRAFT}`);
        dispatch(openFlkAKeyForm());
        syncIntegrations();
    };

    const handleOpenSubLeaseAgreementForm = (subLeaseType: SubLeaseType) => {
        if (!isLeaseTypeEnabled(subLeaseType) || !isActiveUser()) {
            return;
        }
        if (afterOpenCreationModal) afterOpenCreationModal();
        // navigate to relevant section
        history.push(
            `/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${dashboard.agreementStatuses[LEASE_TYPE_RESIDENTIAL][0]}`
        );
        dispatch(openLeaseAgreementForm(subLeaseType));
    };

    return {
        openLeaseAgreementCreationModal,
        openDocumentCreationModal,
        openFlkAKeyCreationModal,
        isLeaseTypeEnabled,
        isActiveUser,
        handleOpenSubLeaseAgreementForm
    };
};

export default useOpenCreationModal;
