import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmEmergencyContacts } from '../../../../selectors/lease/pmLease';

import { getEmergencyContactList } from '../../../../actions/emergencyContacts';
import { FormTextRegular } from '../../../../components/form/FormText';
import ContactServiceGroup from '../../../../components/lease/mainScreen/common/ContactServiceGroup';
import plusIcon from '../../../../../assets/images/icons/plus.svg';

import '../../../../sass/contacts.scss';
import { isLeaseLocationSA } from '../../../../utils/agreementUtils';

const PM_EMERGENCY_CONTACTS_FORM = 'pmEmergencyContactsForm';
const initState = {
    limitOnCostsOfRepairs: '$',
    contacts: [],
};

function PmContacts(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmEmergencyContacts = useSelector(getPmEmergencyContacts);
    const emergencyContacts = useSelector((state) => state.contacts.contactList);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmEmergencyContacts, setPmEmergencyContacts] = useState(reduxPmEmergencyContacts || initState);
    const [addModal, setAddModal] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document
                .getElementById(PM_EMERGENCY_CONTACTS_FORM)
                .dispatchEvent(new Event('submit', { cancelable: true }));
        },
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = (values) => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        values.isDefaultSettings = false;
        values.isLeasePmAndQld = true;
        return axios
            .post(`/api/agency/lease/${props.leaseId}/emergency-contacts`, values)
            .then((result) => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch((error) => {
                if (has(error, 'response.data.errors.pmEmergencyContacts')) {
                    return error.response.data.errors.pmEmergencyContacts;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    useEffect(() => {
        dispatch(getEmergencyContactList());
    }, []);

    const getContactList = (emergencyContacts) => {
        return emergencyContacts.filter((item) => {
            if (!pmEmergencyContacts || !pmEmergencyContacts.contacts) {
                return true;
            }
            return undefined === pmEmergencyContacts.contacts.find((contact) => contact.tradeType === item.tradeType);
        });
    };

    const toggleAddModal = (values) => {
        const contactList = getContactList(emergencyContacts);
        if (contactList.length === 0) {
            addPerson(values, { tradeType: 'Custom' });
            setAddModal(false);
        } else {
            setAddModal(!addModal);
        }
    };

    const addContactPersonContact = (values, { index, type }) => {
        let contact = cloneDeep(values);
        contact.contacts[index].extraContacts.push({ contactType: type, contactValue: '' });
        setPmEmergencyContacts(contact);
    };

    const removeContactPersonContact = (values, emergencyContactIndex, contactIndex) => {
        let contact = cloneDeep(values);

        contact.contacts[emergencyContactIndex].extraContacts = [
            ...contact.contacts[emergencyContactIndex].extraContacts.slice(0, contactIndex),
            ...contact.contacts[emergencyContactIndex].extraContacts.slice(contactIndex + 1),
        ];
        setPmEmergencyContacts(contact);
    };

    const removeContactPerson = (values, index) => {
        let contact = cloneDeep(values);

        contact.contacts = [...contact.contacts.slice(0, index), ...contact.contacts.slice(index + 1)];
        setPmEmergencyContacts(contact);
    };

    const addPerson = (values, contact) => {
        const contactInfo = {
            tradeType: '',
            firstName: '',
            secondName: '',
            phone: '',
            company: '',
            extraContacts: [],
            ...contact,
        };

        setAddModal(!addModal);
        setPmEmergencyContacts({
            ...values,
            contacts: [...values.contacts, contactInfo],
        });
    };

    let contactList = getContactList(emergencyContacts);

    return (
        <div className="contacts">
            <Form
                onSubmit={submitForm}
                initialValues={pmEmergencyContacts}
                mutators={{
                    ...arrayMutators,
                }}
            >
                {({
                    handleSubmit,
                    form,
                    form: {
                        mutators: { push },
                    },
                    values,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_EMERGENCY_CONTACTS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={(state) => handleFormDirtyChange(state.values, form)}
                            />
                            {!isLeaseLocationSA(location) && (
                                <FormTextRegular
                                    name="limitOnCostsOfRepairs"
                                    label="The maximum value of repairs and maintenance to be paid by the agent without prior approval by the client is"
                                    required
                                />
                            )}
                            <p>Nominated tradespeople for emergency repairs:</p>
                            {pmEmergencyContacts && (
                                <FieldArray name="contacts" initialValue={pmEmergencyContacts.contacts}>
                                    {({ fields }) => (
                                        <React.Fragment>
                                            {fields.map((name, index) => {
                                                return (
                                                    <ContactServiceGroup
                                                        push={push}
                                                        location={location}
                                                        addContactPersonContact={(data) =>
                                                            addContactPersonContact(values, data)
                                                        }
                                                        removeContactPersonContact={(
                                                            values,
                                                            emergencyContactIndex,
                                                            contactIndex
                                                        ) =>
                                                            removeContactPersonContact(
                                                                values,
                                                                emergencyContactIndex,
                                                                contactIndex
                                                            )
                                                        }
                                                        key={index}
                                                        index={index}
                                                        contact={fields.value[index]}
                                                        fieldName="contacts"
                                                        removeContactPerson={(index) =>
                                                            removeContactPerson(values, index)
                                                        }
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            )}
                            <div className="button">
                                <button
                                    type="button"
                                    className={'mobile add-item test-add-contact'}
                                    onClick={() => toggleAddModal(values)}
                                >
                                    <img src={plusIcon} className="for-sm-modal" />
                                    Add
                                </button>
                                <div className={addModal ? 'addModal' : 'addModal hide'}>
                                    <div className="choose-items">
                                        {!!contactList.length &&
                                            contactList.map((contact, index) => (
                                                <div
                                                    key={index}
                                                    className="choose"
                                                    onClick={() => addPerson(values, contact)}
                                                >
                                                    {contact.tradeType}
                                                </div>
                                            ))}
                                        <div
                                            className="choose"
                                            onClick={() => addPerson(values, { tradeType: 'Custom' })}
                                        >
                                            Custom
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(PmContacts));
