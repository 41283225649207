import * as Lease from '../reducers/lease';
import axios from 'axios';
import * as Dashboard from '../reducers/dashboard';
import { formatDocumentsForApi } from '../utils/formUtils';
import * as Account from '../reducers/account';

export function openAgreementTemplateForm(leaseType, cloneTemplate) {
    return {
        type: Lease.OPEN_AGREEMENT_TEMPLATE_FORM,
        leaseType,
        cloneTemplate
    };
}

export function closeAgreementTemplateForm() {
    return {
        type: Lease.CLOSE_AGREEMENT_TEMPLATE_FORM
    };
}

export function createPmTemplate(data) {
    return {
        types: [Lease.CREATE_PM_LEASE_REQUEST, Lease.CREATE_PM_LEASE_SUCCESS, Lease.CREATE_PM_LEASE_FAILURE],
        promise: axios.post('/api/template', data)
    };
}

export function createSalesTemplate(data) {
    return {
        types: [Lease.CREATE_SALES_LEASE_REQUEST, Lease.CREATE_SALES_LEASE_SUCCESS, Lease.CREATE_SALES_LEASE_FAILURE],
        promise: axios.post('/api/template', data)
    };
}

export function getTemplates(templateType, page, limit, field, direction) {
    return {
        types: [
            Dashboard.GET_TEMPLATE_LIST_REQUEST,
            Dashboard.GET_TEMPLATE_LIST_SUCCESS,
            Dashboard.GET_TEMPLATE_LIST_FAILURE
        ],
        promise: axios.get(`/api/template/templates`, {
            params: {
                templateType,
                page,
                limit,
                field,
                direction
            }
        })
    };
}

export function getTemplatesForSelect(templateType, location) {
    return {
        types: [
            Dashboard.GET_ALLOWED_TEMPLATE_LIST_REQUEST,
            Dashboard.GET_ALLOWED_TEMPLATE_LIST_SUCCESS,
            Dashboard.GET_ALLOWED_TEMPLATE_LIST_FAILURE
        ],
        promise: axios.get(`/api/template/templates`, {
            params: {
                location: location,
                fieldsToReturn: 'id,templateName',
                templateType: `${templateType}`
            }
        })
    };
}

export function updateAgreementTemplate(leaseId, data) {
    return dispatch => {
        let documents = formatDocumentsForApi(data.documents);
        delete data.documents;

        dispatch({
            type: Lease.FINISH_EDITING_TEMPLATE_AGREEMENT_REQUEST
        });

        return axios
            .post(`/api/agency/lease/${leaseId}/template-documents`, documents)
            .then(() => {
                return axios.put(`/api/agency/lease/${leaseId}`, data);
            })
            .then(leaseResult => {
                dispatch({
                    type: Lease.FINISH_EDITING_TEMPLATE_AGREEMENT_SUCCESS,
                    result: leaseResult
                });
            })
            .then(() => {
                dispatch(getTemplates());
            })
            .catch(error => {
                dispatch({
                    type: Lease.LEASE_FORM_FAIL,
                    error,
                    data
                });
                throw error;
            });
    };
}

export function deleteAgreementTemplate(leaseId, leaseType) {
    return {
        types: [
            Dashboard.DELETE_TEMPLATE_REQUEST,
            Dashboard.DELETE_TEMPLATE_SUCCESS,
            Dashboard.DELETE_TEMPLATE_FAILURE
        ],
        promise: axios.delete(`/api/template/${leaseId}`, {
            params: {
                templateType: leaseType
            }
        })
    };
}

export function editAgreementTemplate(user, agreementInfo) {
    return {
        type: Lease.EDIT_LEASE_AGREEMENT_FORM,
        payload: { user, agreementInfo, step: 2 }
    };
}

export function updateUsedTemplates(defaultPaymentsTemplatesToUpdate) {
    return {
        types: [Account.UPDATE_TEMPLATES_REQUEST, Account.UPDATE_TEMPLATES_SUCCESS, Account.UPDATE_TEMPLATES_FAILURE],
        promise: axios.put(`/api/template/templates`, defaultPaymentsTemplatesToUpdate)
    };
}

export function addOrReplaceTemplateInList(template) {
    return {
        type: Dashboard.ADD_OR_REPLACE_TEMPLATE_IN_LIST,
        payload: { template }
    };
}
