import useComponentId from '@app/hooks/useComponentId';
import cx from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import '../../sass/buttons.scss';
import Tooltip from './tooltips/Tooltip';

const Button = ({
    primary,
    secondary,
    tertiary,
    quaternary,
    dark,
    newSecondary,
    danger,
    loading,
    suggestion,
    link,
    signUp,
    error,
    className,
    spinnerClassName,
    onClick,
    bold,
    width,
    height,
    type,
    disabled,
    startIcon,
    endIcon,
    href,
    dataTest,
    tooltip,
    toolTipId,
    style,
    children,
    ...rest
}) => {
    const id = useComponentId().toString();
    const innerTooltipId = toolTipId || id;

    const ComponentToRender = href ? 'a' : 'button';
    return (
        <>
            {tooltip && <Tooltip id={innerTooltipId} className="tooltip" place="bottom" effect="solid" />}
            <ComponentToRender
                className={cx(
                    'button-default',
                    { 'button-primary': primary },
                    { 'button-signup': signUp },
                    { 'button-secondary': secondary },
                    { 'button-quaternary': quaternary },
                    { dark: dark },
                    { 'button-danger': danger },
                    { 'new-button-secondary': newSecondary },
                    { 'button-tertiary': tertiary },
                    { 'button-suggestion': suggestion },
                    { 'button-error': error },
                    { 'button-disabled': disabled },
                    { 'button-link': link },
                    { 'button-icon': startIcon || endIcon },
                    { loading: loading },
                    { bold: bold },
                    className
                )}
                data-test={dataTest}
                style={{
                    width: width,
                    height: height ? height : primary || secondary ? 48 : 'auto',
                    ...(style || {})
                }}
                onClick={onClick}
                type={type}
                disabled={disabled || loading}
                href={href}
                {...rest}
                data-tip={tooltip ? tooltip : null}
                data-for={innerTooltipId}
            >
                {startIcon}
                {children}
                {endIcon}
                {loading && (
                    <div className="spinner-container">
                        <div className={spinnerClassName || 'button-spinner'} />
                    </div>
                )}
            </ComponentToRender>
        </>
    );
};

Button.propTypes = {
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    quaternary: PropTypes.bool,
    dark: PropTypes.bool,
    loading: PropTypes.bool,
    link: PropTypes.bool,
    signUp: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    bold: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.number || PropTypes.string,
    height: PropTypes.number,
    type: PropTypes.string,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element
};

export default Button;
