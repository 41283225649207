import React, { useEffect, useRef, useState } from 'react';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cookie from 'react-cookie';
import { values, flatten, findIndex, toLower } from 'lodash';

import * as login from '../actions/login';
import * as authorization from '../actions/authorization';
import * as dashboard from '../actions/dashboard';
import * as user from '../actions/user';
import * as notification from '../actions/notification';
import PopupAccountSettings from '../components/popups/PopupAccountSettings';
import PopupNotificationCount from '../components/popups/PopupNotificationCount';
import SearchBar from '../components/search/SearchBar';
import { getAgreementList } from '../selectors/dashboard/agreementList';
import logoImage from '../../assets/images/logo.svg';
import AgentLogo from '../components/AgentLogo';
import FlkAKeyCheckOutHistoryModal from '../containers/dashboard/flkAKey/FlkAKeyCheckOutHistoryModal';
import {
    isLeftMenuOpen as isLeftMenuOpenSelector,
    isNotificationPanelOpen as isNotificationPanelOpenSelector
} from '../selectors/dashboard';

import '../sass/header.scss';
import {
    DOCUMENT_CUSTOM,
    DOCUMENT_EDIT_MODE,
    DOCUMENT_READ_ONLY_MODE,
    DOCUMENT_RENT_REDUCTION,
    LEASE_TYPE_RESIDENTIAL,
    DOCUMENT_CREATE_A_FLK
} from '../config';
import { LEASE_STATUS_DRAFT } from '../actions/dashboard';
import { openDocumentForm } from '../actions/document';
import { isDocumentStatusDraft } from '../utils/generalUtils';
import appHistory from '../AppHistory';
import { isBusinessUserAccount } from '../utils/userUtils';
import { ALL, DOCUMENTS, FLK_A_KEY, HOLIDAY_LETTING_SUBAPP_PATH } from '../constants/constants';
import cx from 'classnames';
import { useOpenAgreement } from '../hooks/useOpenAgreement';
import { isLeaseTypeHolidayLetting } from '../utils/agreementUtils';
import { openSubapp } from '../utils/openSubapp';
import { FLK_B_MOBILE_RESPONSIVE } from '../constants/featureFlags';

const Header = () => {
    const dispatch = useDispatch();
    const { openModalInfoAgreement } = useOpenAgreement();
    const isFlkBMobileResponsivenessEnabled = useFeatureFlag(FLK_B_MOBILE_RESPONSIVE);

    const popupAccountSettingsRef = useRef(null);
    const popupAgenciesRef = useRef(null);

    const isLeftMenuOpen = useSelector(isLeftMenuOpenSelector);
    const userInfo = useSelector(state => state.user.userInfo);
    const agencies = useSelector(state => state.user.agencies);
    const requestingAgencies = useSelector(state => state.user.requestingAgencies);
    const agreementList = useSelector(getAgreementList);
    const flkAKeyList = useSelector(state => state.dashboard.flkAKeyList);
    const notificationsCount = useSelector(state => state.notification.notificationsCount);
    const isNotificationPanelOpen = useSelector(isNotificationPanelOpenSelector);

    const [isAccountSettingsPopupOpen, setIsAccountSettingsPopupOpen] = useState(false);
    const [isAgenciesPopupOpen, setIsAgenciesPopupOpen] = useState(false);
    const [viewCheckOutHistory, setViewCheckOutHistory] = useState(false);
    const [currentKey, setCurrentKey] = useState(null);

    useEffect(() => {
        document.addEventListener('click', documentClick);
        document.addEventListener('click', documentClickAgencies);

        /*
         *  These calls may fail with a 406, which will redirect
         *   to the signup page if the user is in the middle of registration
         */
        dispatch(notification.getRequestsToChange());
        dispatch(user.getAgencies());
        return () => {
            document.removeEventListener('click', documentClick);
            document.removeEventListener('click', documentClickAgencies);
        };
    }, []);

    const handleSearchSelect = (item, searchContext) => {
        if (searchContext === FLK_A_KEY) {
            setViewCheckOutHistory(true);
            setCurrentKey(item);
        } else {
            if (item.docType) {
                if (
                    [DOCUMENT_RENT_REDUCTION, DOCUMENT_CREATE_A_FLK, DOCUMENT_CUSTOM].includes(item.docType) &&
                    isDocumentStatusDraft(item.status)
                ) {
                    dispatch(openDocumentForm(item.docType, item, DOCUMENT_EDIT_MODE));
                } else {
                    dispatch(openDocumentForm(item.docType, item, DOCUMENT_READ_ONLY_MODE));
                }
            } else {
                if (isLeaseTypeHolidayLetting(item.leaseType)) {
                    // redirect to the new architecture
                    openSubapp(`${HOLIDAY_LETTING_SUBAPP_PATH}/${toLower(item.location)}/${item.id}`);
                } else {
                    openModalInfoAgreement(item, item.leaseType);
                }
            }
        }
    };

    const triggerAgencySwitch = agencyId => {
        if (agencyId !== userInfo.agency.id) {
            dispatch(
                user.updateUserInfo({
                    agency: agencyId,
                    savedTeamMemberSelection: []
                })
            )
                .then(() => {
                    window.location.reload(false);
                })
                .catch(() => {
                    alert('Could not switch agency, please contact usc');
                });
        }
    };

    const getAssignedColor = (loggedInAgency, agencies) => {
        // this is an index number from 0 to the max amount of agencies they have
        let activeAgencyNr = findIndex(agencies, agency => agency.id === loggedInAgency.id);

        activeAgencyNr = activeAgencyNr % 6;

        const color = ['blue', 'pink', 'green', 'orange', 'grey', 'black'];

        return color[activeAgencyNr];
    };

    const getSearchContext = () => {
        if (userInfo.agency) {
            if (isBusinessUserAccount(userInfo.agency.accountType)) {
                return DOCUMENTS;
            } else if (appHistory.location.pathname && appHistory.location.pathname.includes('flk-a-key')) {
                return FLK_A_KEY;
            }
        }
        return ALL;
    };

    let assignedColor = '';
    if (userInfo.agency && agencies && agencies.length > 1) {
        assignedColor = getAssignedColor(userInfo.agency, agencies);
    }

    const searchContext = getSearchContext();

    function getPlaceHolder() {
        switch (searchContext) {
            case DOCUMENTS: {
                return `Search by document, client, email or phone`;
            }
            case FLK_A_KEY: {
                return `Search by address or key number`;
            }
            case ALL: {
                return `Search by address, document, client, email or phone`;
            }
            default: {
                return undefined;
            }
        }
    }

    function documentClick(event) {
        if (!!popupAccountSettingsRef && !popupAccountSettingsRef.current.contains(event.target)) {
            setIsAccountSettingsPopupOpen(false);
        }
    }

    function documentClickAgencies(event) {
        if (!!popupAgenciesRef && !popupAgenciesRef.current.contains(event.target)) {
            setIsAgenciesPopupOpen(false);
        }
    }

    function toggleMenu() {
        dispatch(dashboard.closeNotificationsPanel());
        dispatch(dashboard.toggleMenu());
    }

    function toggleNotificationsPanel() {
        dispatch(dashboard.closeMenu());
        dispatch(dashboard.toggleNotificationsPanel());
    }

    function togglePopupAgencies() {
        if (agencies.length > 1) {
            setIsAgenciesPopupOpen(oldState => !oldState);
        }
    }

    function logOut() {
        cookie.remove('accountType', { path: '/' });
        cookie.remove('userFromLogin', { path: '/' });
        cookie.remove('rememberMe', { path: '/' });
        dispatch(authorization.unRegister());
        dispatch(login.logOut());
        appHistory.push('/');
    }

    return (
        <div className="header-wrap">
            <header
                className={cx('color-' + assignedColor, 'header', {
                    'notifications-open': isNotificationPanelOpen
                })}
            >
                {agencies?.length > 1 && (
                    <div className={'header-agency-name desktop-only'}>
                        <span className={cx('color-' + assignedColor)}>
                            {userInfo && userInfo.agency && userInfo.agency.details.agencyName}
                        </span>
                    </div>
                )}

                <nav className="nav">
                    <div>
                        <button
                            data-test="nav-button"
                            type="button"
                            onMouseDown={e => {
                                e.stopPropagation();
                                return toggleMenu();
                            }}
                            onTouchStart={e => {
                                e.stopPropagation();
                                return toggleMenu();
                            }}
                            className={cx('desktop-only', 'button', { on: isLeftMenuOpen })}
                        />
                        <Link
                            to={
                                userInfo.agency && isBusinessUserAccount(userInfo.agency.accountType)
                                    ? `/dashboard/documents/all/${LEASE_STATUS_DRAFT}`
                                    : `/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${LEASE_STATUS_DRAFT}`
                            }
                            className="logo desktop-only"
                        >
                            <img src={logoImage} alt="logo" />
                        </Link>
                        <Link
                            to={
                                userInfo.agency &&
                                isBusinessUserAccount(userInfo.agency.accountType) &&
                                !isFlkBMobileResponsivenessEnabled
                                    ? `/dashboard/documents/all/${LEASE_STATUS_DRAFT}`
                                    : `/welcome`
                            }
                            className="logo mobile-only"
                        >
                            <img src={logoImage} alt="logo" />
                        </Link>

                        <div
                            className={cx('desktop-only', 'agency-selector', {
                                active: agencies && agencies.length > 1
                            })}
                        >
                            <ul className="navMenu" ref={popupAgenciesRef}>
                                <li onClick={() => togglePopupAgencies()}>
                                    {userInfo && userInfo.agency && userInfo.agency.details.agencyName}
                                </li>
                                {!requestingAgencies && agencies && agencies.length > 1 && (
                                    <li className="dropDown" onClick={() => togglePopupAgencies()}>
                                        {isAgenciesPopupOpen && (
                                            <div className="popup-agencies">
                                                {agencies.map(childAgency => {
                                                    return (
                                                        <p
                                                            key={childAgency.id}
                                                            onClick={() => triggerAgencySwitch(childAgency.id)}
                                                        >
                                                            {childAgency.logo && (
                                                                <img src={childAgency.logo} alt="logo" />
                                                            )}
                                                            {childAgency.details.agencyName}
                                                            {childAgency.details.companyName && (
                                                                <span className="popup-agency-name">
                                                                    {`(${childAgency.details.companyName})`}
                                                                </span>
                                                            )}
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <SearchBar
                        onSelect={item => handleSearchSelect(item, searchContext)}
                        placeholder={getPlaceHolder()}
                        items={flatten(values(searchContext === FLK_A_KEY ? flkAKeyList : agreementList))}
                        searchContext={searchContext}
                    />
                    <div>
                        <AgentLogo agent={userInfo} />
                        <ul className="navMenu" ref={popupAccountSettingsRef}>
                            <li
                                className="user agent-logo"
                                onClick={() => setIsAccountSettingsPopupOpen(oldState => !oldState)}
                            >
                                <span className="userName desktop-only">Hello, {userInfo.firstName}</span>
                                <span className="userAvatar mobile-only">
                                    {!!userInfo.image && (
                                        <div className="profile-img">
                                            <div
                                                className="profile-img-with-background"
                                                style={{ backgroundImage: 'url(' + userInfo.image + ')' }}
                                            />
                                        </div>
                                    )}
                                    {!userInfo.image && (
                                        <div className="no-photo">
                                            <span className="name-letters">
                                                {userInfo.firstName?.substr(0, 1) || ''}
                                                {userInfo.secondName?.substr(0, 1) || ''}
                                            </span>
                                        </div>
                                    )}
                                </span>
                            </li>
                            <li
                                className="dropDown"
                                onClick={() => setIsAccountSettingsPopupOpen(oldState => !oldState)}
                            >
                                <PopupAccountSettings
                                    unRegister={() => dispatch(authorization.unRegister())}
                                    logOut={() => logOut()}
                                    isAccountSettingsPopupOpen={isAccountSettingsPopupOpen}
                                />
                            </li>
                            <li className="notification desktop-only" onClick={() => toggleNotificationsPanel()}>
                                <PopupNotificationCount notificationsCount={notificationsCount} />
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            {viewCheckOutHistory && (
                <FlkAKeyCheckOutHistoryModal
                    viewCheckOutHistory={viewCheckOutHistory}
                    closeModal={() => {
                        setViewCheckOutHistory(false);
                    }}
                    currentKey={currentKey}
                />
            )}
        </div>
    );
};

export default Header;
