import React from 'react';
import { CLIENT_OTHER, CLIENT_TRADING_NAME } from '../../../../config';
import { getClientTypeFromDoc } from '../../../../utils/documentUtils';
import SummaryItem from './SummaryItem';

type AgencyProps = {
    details: {
        agencyName: string;
        address: string;
        phone: string;
        email: string;
    }
};

type CreateAgentSummaryProps = {
    currentDocument: any;
    type: string;
    agency: AgencyProps;
};

const AgentSummary: React.FC<CreateAgentSummaryProps> = ({ currentDocument, type, agency }) => {
    let clientType = getClientTypeFromDoc(currentDocument, type);
    if ([CLIENT_OTHER, CLIENT_TRADING_NAME].includes(clientType)) {
        clientType = type.toUpperCase();
    }

    const items = [
        {
            value: agency.details.agencyName,
            isBold: true
        },
        {
            value: agency.details.address
        },
        {
            value: agency.details.phone
        },
        {
            value: agency.details.email
        }
    ];
    return (
        <div className="client-summary summary-item">
            <h2>{`${clientType} Agent Details`}</h2>

            <div className="client-item">
                <SummaryItem items={items} />
            </div>
        </div>
    );
};
export default AgentSummary;
