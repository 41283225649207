import React, { memo, useEffect, useState, useRef } from 'react';
import { Form, FormSpy, FormApi } from 'react-final-form';
import { useMutation } from '@tanstack/react-query';
import { confirmAlert } from 'react-confirm-alert';
import { parseISO } from 'date-fns';
import { isEmpty, uniq, remove, cloneDeep } from 'lodash';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { openNoSignatureModal } from '../../../../actions/user';
import EditSenderConfirmation from '../../../../components/modals/document/EditSenderConfirmation';
import { getAgencyTimezoneDate } from '../../../../utils/dateUtils.js';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../../../hooks/useDoubleSendGuard';

import { closeIntentionToSellForm, addOrReplaceDocumentInList } from '../../../../actions/document.js';
import {
    HIDE_MESSAGE_TIME,
    NSW_STATE,
    DELIVERY_TO_AGENT_AND_TENANT,
    DELIVERY_TYPE_EMAIL,
    FORM_SUBMIT_TYPE_SEND,
    CLOSE_MODAL_MESSAGE,
    BREACH_NOTICE
} from '../../../../config';

import DocumentTenantPersonGroup from '../../../../components/document/DocumentTenantPersonGroup.js';
import { FormTextMultiline, FormTextRegular } from '../../../../components/form/FormText.js';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone.js';
import ModalDialog from '../../../../common/components/ModalDialog.js';
import * as validators from '../../../../components/Validate';
import { getAgentSignature } from '../../../../selectors/completion';
import { getUserInfo } from '../../../../selectors/user';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document';
import { isDocumentEditMode, isDocumentReadOnlyMode } from '../../../../utils/generalUtils.js';
import LocationAndAddressSelection from '../../LocationAndAddressSelection.js';
import DocumentHeader from '../../DocumentHeader.js';
import { getIsOpenIntentionToSellDocumentModal } from '../../../../selectors/document/intentionToSellDocument';
import IntentionToSellFormFooter from './IntentionToSellFormFooter';
import DeliveryTypeComponent from '../../shared/DeliveryTypeComponent.js';
import { CloseSharp } from '@material-ui/icons';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup.js';
import { YES_VALUE, NO_VALUE } from '../../../../config';
import * as dashboard from '../../../../actions/dashboard.js';

import '../../../../sass/documentModalDialog.scss';
import { DEFINE_ME } from '../../../../types/utilityTypes';
import { User } from '../../../../types/User';
import FormValues, { IntentionToSellDocument } from './types';
import Banner from '../../../../common/components/Banner.js';

const allowedStates = [NSW_STATE];
const INTENTION_TO_SELL_API = 'api/document/intention-to-sell-notice';
const INSPECTION_COMMENTS_DEFAULT_TEXT =
    'The team will be in contact with you directly to arrange suitable dates and times to access the Property for inspections.';

const IntentionToSellForm = () => {
    const dispatch = useDispatch();

    const formValuesRef = useRef<FormValues | null>(null);
    const isOpen = useSelector(getIsOpenIntentionToSellDocumentModal);
    const loggedInUser: User = useSelector(getUserInfo);
    const agentSignature = useSelector(getAgentSignature);
    const currentDocument: IntentionToSellDocument = useSelector(getCurrentDocument);
    const documentEditMode: boolean = useSelector(getDocumentEditMode);

    const [currentDocumentId, setCurrentDocumentId] = useState(currentDocument ? currentDocument.id : null);
    const [location, setLocation] = useState<string | null>(null);
    const [locationAndAddressSelected, setLocationAndAddressSelected] = useState(false);
    const [addEmailFieldError, setAddEmailFieldError] = useState<string | undefined>('');
    const [formData, setFormData] = useState<FormValues | {}>({});
    const [integrationPropertyId, setIntegrationPropertyId] = useState<string | null>(null);
    const [integration, setIntegration] = useState<DEFINE_ME | null>(null);
    const [addressError, setAddressError] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [showEditSenderConfirmationModel, setShowEditSenderConfirmationModel] = useState(false);
    const [showLoadingIcon, setShowLoadingIcon] = useState(false);
    const doubleSendGuard = useDoubleSendGuard();

    const [agent, setAgent] = useState({
        value: currentDocument && currentDocument.agent ? currentDocument.agent.id : '',
        label: currentDocument && currentDocument.agent ? currentDocument.agent.fullName : ''
    });

    const handleFormDirtyChange = (form: FormApi) => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };

    const formRef = useRef<FormApi<FormValues>>(null);

    const submitConfirmation = () => {
        setShowEditSenderConfirmationModel(false);
        if (formValuesRef.current !== null && formRef.current !== null) {
            formValuesRef.current.isConfirmed = true;
            formRef.current.dispatchEvent(new Event('submit'));
        }
    };

    useEffect(() => {
        if (currentDocument && isOpen) {
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
    }, [isOpen]);

    useEffect(() => {
        if (currentDocument?.details?.integrationPropertyId) {
            setIntegrationPropertyId(currentDocument.details.integrationPropertyId);
            setIntegration({ address: currentDocument.address});
        } else {
            setIntegrationPropertyId(null);
            setIntegration(null);
        }
        if (currentDocument) {
            setLocation(currentDocument.location);
            setLocationAndAddressSelected(true);
            setCurrentDocumentId(currentDocument.id);
        }
        setFormData(
            currentDocument
                ? {
                      address: currentDocument.address,
                      location: currentDocument.location,
                      tenants: currentDocument.tenant.tenants ? currentDocument.tenant.tenants : [],
                      agentName: currentDocument.agent.fullName,
                      agencyName: currentDocument.details.agencyName,
                      agencyAgentName: currentDocument.details.agencyAgentName,
                      addressDetails: currentDocument.addressDetails,
                      deliveryType: currentDocument.details.deliveryType,
                      emailTo:
                          currentDocument.details.deliveryType === DELIVERY_TYPE_EMAIL
                              ? DELIVERY_TO_AGENT_AND_TENANT
                              : null,
                      deliveryTypeDetails: currentDocument.details.deliveryTypeDetails,
                      emailAddresses: currentDocument.details.emailAddresses,
                      ccEmailAddresses: currentDocument.details.ccEmailAddresses,
                      hasASellingAgencyBeenAppointed: currentDocument.details.hasASellingAgencyBeenAppointed,
                      availableForSaleFrom: currentDocument.details.availableForSaleFrom
                          ? parseISO(currentDocument.details.availableForSaleFrom)
                          : null,
                      inspectionComments:
                          currentDocument.details.inspectionComments ?? INSPECTION_COMMENTS_DEFAULT_TEXT,
                      dateOfNotice:
                          currentDocument.details.dateOfNotice &&
                          currentDocument.status !== dashboard.LEASE_STATUS_DRAFT
                              ? parseISO(currentDocument.details.dateOfNotice)
                              : parseISO(new Date().toISOString()),
                      disabled: currentDocument.completion && isDocumentReadOnlyMode(documentEditMode)
                  }
                : getResetFormData()
        );
        if (!currentDocument) {
            setLocation(loggedInUser?.agency?.details?.location ? loggedInUser.agency.details.location : null);
            setLocationAndAddressSelected(false);
        }
    }, [isOpen, currentDocument]);

    const closeModal = () => {
        getResetFormData();
        setIsDirty(false);
        dispatch(closeIntentionToSellForm());
        if (currentDocument) {
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
            );
        }
    };

    const handleCloseModal = () => {
        if (isDirty) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const values: FormValues = formValuesRef.current;
                            saveDraft(values).then(response => {
                                dispatch(addOrReplaceDocumentInList(response.data.intentionToSell));
                            });
                            closeModal();
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            closeModal();
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    const getResetFormData = () => {
        return {
            location: location,
            agentName: loggedInUser.fullName,
            address: '',
            addressDetails: '',
            tenants: [
                {
                    firstName: '',
                    middleName: '',
                    secondName: '',
                    email: ''
                }
            ],
            emailAddresses: [],
            inspectionComments: INSPECTION_COMMENTS_DEFAULT_TEXT
        };
    };

    const prepareValuesForSave = (values: FormValues) => {
        const data = cloneDeep(values);
        if (data.deliveryType !== DELIVERY_TYPE_EMAIL || data.emailTo !== DELIVERY_TO_AGENT_AND_TENANT) {
            data.emailAddresses = [loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        data.integrationPropertyId = integrationPropertyId;
        return data;
    };

    const previewPdf = (values: FormValues) => {
        if (!isEmpty(agentSignature)) {
            setShowLoadingIcon(false);
            const preview = true;
            saveDraft(values, preview)
                .then(response => {
                    setShowLoadingIcon(true);
                    viewPdf(response.data.intentionToSell.id);
                    dispatch(addOrReplaceDocumentInList(response.data.intentionToSell));
                    setCurrentDocumentId(response.data.intentionToSell.id);
                });
        } else {
            closeModal();
            dispatch(openNoSignatureModal());
        }
    };

    const viewPdf = (docId: string) => {
        window.open(`/reader/document/intention-to-sell-notice/${docId}`, '_blank');
    };

    const handleSubmit = (values: FormValues) => doubleSendGuard(() => {
        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            if (!values.isConfirmed && agent.value && loggedInUser.id !== agent.value) {
                setShowEditSenderConfirmationModel(true);
                return;
            }
            return sendDocument(values);
        } else {
            setShowLoadingIcon(false);
            return saveDraft(values)
                .then(response => {
                    setShowLoadingIcon(true);
                    dispatch(addOrReplaceDocumentInList(response.data.intentionToSell));
                    setCurrentDocumentId(response.data.intentionToSell.id);
                    setIsDirty(false);
                }).catch(() => {
                    clearDoubleSendGuard();
                });
        }
    });

    const closeEditSenderConfirmationModel = () => {
        setShowEditSenderConfirmationModel(false);
    };

    const {
        mutateAsync: saveDraftMutateAsync,
        isLoading: isSaveDraftLoading,
        isSuccess: isSaveDraft
    } = useMutation((data: DEFINE_ME) => {
        return axios.post(`${INTENTION_TO_SELL_API}${currentDocumentId ? `/${currentDocumentId}/save` : ''}`, data);
    });

    const saveDraft = (values: FormValues, preview = false) => {
        const data = prepareValuesForSave(values);
        data.documents = [];
        return saveDraftMutateAsync(data);
    };

    const sendIntentionToSellData = (data: DEFINE_ME) => {
        return axios
            .post(`${INTENTION_TO_SELL_API}${currentDocumentId ? `/${currentDocumentId}/send` : '/send'}`, data)
            .then(() => {
                setIsDirty(false);
                setTimeout(() => {
                    closeModal();
                }, HIDE_MESSAGE_TIME);
            });
    };

    const {
        mutateAsync: sendIntentionToSellDataMutateAsync,
        isLoading: isSendingIntentionToSellNotice,
        isError: isSendingIntentionToSellNoticeFail,
        isSuccess: isSendingIntentionToSellNoticeSuccess
    } = useMutation(sendIntentionToSellData, {
        retry: 1
    });

    const sendDocument = (values: FormValues) => {
        const data = prepareValuesForSave(values);
        if (validateEmailList(data)) {
            if (!isEmpty(agentSignature)) {
                data.documents = [];
                return sendIntentionToSellDataMutateAsync(data).catch(error => {
                    let errors = {};
                    if (error.response.data.errors.details) {
                        errors = error.response.data.errors.details;
                    }
                    if (error.response.data.errors.tenant) {
                        errors.tenants = error.response.data.errors.tenant.tenants;
                    }
                    clearDoubleSendGuard();
                    return errors;
                });
            } else {
                closeModal();
                dispatch(openNoSignatureModal());
            }
        }
    };

    // Change tenant details functions
    const addTenantPerson = (values: FormValues) => {
        const data = cloneDeep(values);
        const newTenant = {
            firstName: '',
            middleName: '',
            secondName: '',
            email: ''
        };
        if (data.tenants) {
            data.tenants.push(newTenant);
        } else {
            data.tenants = [newTenant];
        }
        setFormData(data);
    };
    const removeTenantPerson = (index: number, values: FormValues, disabled: boolean) => {
        if (!disabled) {
            const data: FormValues = cloneDeep(values);
            const emailAddresses = remove(data.emailAddresses, function (n) {
                return n !== data.tenants[index].email;
            });
            data.tenants = [...data.tenants.slice(0, index), ...data.tenants.slice(index + 1)];
            data.emailAddresses = emailAddresses;
            setFormData(data);
        }
    };
    const onChangeEmail = (value: string , index: number, values: FormValues, setValue: (fieldName: string, fieldValue?: string | string[]) => void) => {
        // change email list only email to is agentAndTenant
        if (values.emailTo === DELIVERY_TO_AGENT_AND_TENANT) {
            // set changed value to form values
            values.tenants[index].email = value.target.value;
            emailAgentAndTenantHandler(values, setValue);
        }
    };

    // Delivery email change functions
    const emailAgentAndTenantHandler = (values: FormValues, setValue: (fieldName: string, fieldValue?: string | string[]) => void) => {
        values.emailTo = DELIVERY_TO_AGENT_AND_TENANT;
        const emailAddresses: [string] | [] = [];
        values.tenants.forEach(tenant => {
            if (tenant.email && validateEmail(tenant.email)) {
                emailAddresses.push(tenant.email);
            }
        });
        values.emailAddresses = uniq(emailAddresses);
        values.ccEmailAddresses = [loggedInUser.email];
        values.dateOfNotice = parseISO(new Date().toISOString());
        setValue('emailAddresses', values.emailAddresses);
        setValue('ccEmailAddresses', values.ccEmailAddresses);
        setFormData(values);
    };

    const validateEmail = (value: string) => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }
        setAddEmailFieldError(undefined);
        return true;
    };
    const validateEmailList = (values: FormValues) => {
        if (values.deliveryType === DELIVERY_TYPE_EMAIL) {
            if (values.emailAddresses.length === 0) {
                setAddEmailFieldError('Please enter at least one email address');
                return false;
            }
        }
        setAddEmailFieldError('');
        return true;
    };

    const setUpdateAddressDetails = (address: string, details: DEFINE_ME, values: FormValues) => {
        if (!address) {
            setAddressError('Address is required');
        } else {
            setAddressError('');
        }
        const data = cloneDeep(values);
        data.address = address;
        data.location = location;
        data.addressDetails = details;
        setFormData(data);
        setIntegrationPropertyId(null);
        setIntegration(null);
    };
    const confirmDetails = () => {
        const values: FormValues = formValuesRef.current;
        saveDraft(values).then(response => {
            setCurrentDocumentId(response.data.intentionToSell.id);
            setIsDirty(false);
            dispatch(addOrReplaceDocumentInList(response.data.intentionToSell));
            setLocationAndAddressSelected(true);
        });
    };
    const setAddressDetails = (address: string, details: DEFINE_ME, values: FormValues) => {
        const data = cloneDeep(values);
        data.address = address;
        data.location = location;
        data.addressDetails = details;
        data.dateOfNotice = parseISO(new Date().toISOString());
        setIntegrationPropertyId(null);
        setIntegration(null);
        setFormData(data);
    };
    const setIntegrationProperty = (propertyDetails: DEFINE_ME) => {
        setAgent({
            value: loggedInUser.id,
            label: loggedInUser.fullName
        });
        if (propertyDetails) {
            setFormData({
                location: location,
                address: propertyDetails.address,
                agentName: loggedInUser.fullName,
                tenants: propertyDetails.tenants,
                dateOfNotice: parseISO(new Date().toISOString()),
                inspectionComments: INSPECTION_COMMENTS_DEFAULT_TEXT
            });
            setIntegrationPropertyId(propertyDetails.id);
            setIntegration(propertyDetails);
        } else {
            setFormData(getResetFormData());
        }
    };

    return (
        <React.Fragment>
            <ModalDialog
                title={
                    locationAndAddressSelected ? 'Intention to sell notice' : 'Create new Intention to sell notice for'
                }
                isOpen={isOpen}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={`document-centre-modal document-modal-dialog tenant ${
                    locationAndAddressSelected && location ? 'with-header' : 'without-header'
                }`}
                hideCloseButton={isSendingIntentionToSellNotice}
                // Quick fix for search results being cut off by modal
                allowOverflow={!locationAndAddressSelected}
            >
                <Form initialValues={formData} onSubmit={handleSubmit}>
                    {({ handleSubmit, values, form }) => {
                        // set the values to the ref so that the close modal can access these values
                        formValuesRef.current = values;
                        return (
                            <form onSubmit={handleSubmit} noValidate id="create-intention-to-sell-form" ref={formRef}>
                                <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                                {locationAndAddressSelected && location && (
                                    <div className="intention-to-sell-notice">
                                        <DocumentHeader
                                            parentSelector="create-intention-to-sell-form"
                                            confirmDetails={confirmDetails}
                                            address={values.address}
                                            addressError={addressError}
                                            handleAddressChange={(address: string) =>  form.change('address', address)}
                                            setAddressDetails={(address: string, details: DEFINE_ME) =>
                                                setUpdateAddressDetails(address, details, values)
                                            }
                                            disabled={isDocumentReadOnlyMode(documentEditMode) || integrationPropertyId}
                                            agentName={values.agentName}
                                            integration={integration}
                                            viewOnlyIntegration={true}
                                            doc={currentDocument}
                                            hideMoreOptions={currentDocument && !currentDocument.completion}
                                            setAssignedAgent={setAgent}
                                        />
                                        <div>
                                            <div className="tenants">
                                                {formData &&
                                                    formData.tenants &&
                                                    formData.tenants.map((tenant: DEFINE_ME, index: number) => (
                                                        <DocumentTenantPersonGroup
                                                            key={index}
                                                            index={index}
                                                            removeTenant={(index: number) =>
                                                                removeTenantPerson(index, values, values.disabled)
                                                            }
                                                            showHeader={index === 0}
                                                            onChangeEmail={(value: string, index: number) =>
                                                                onChangeEmail(value, index, values, form.change)
                                                            }
                                                            disabled={values.disabled}
                                                        />
                                                    ))}
                                                {(currentDocument?.status === dashboard.LEASE_STATUS_DRAFT ||
                                                    isDocumentEditMode(documentEditMode)) && (
                                                    <div className="button">
                                                        <button
                                                            className="add-tenant"
                                                            type="button"
                                                            onClick={() => addTenantPerson(values)}
                                                        >
                                                            Add tenant
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <h6 className="button-group-header">Sales details</h6>
                                            <div className="salesDetails">
                                                <div className="banner">
                                                    <Banner
                                                        title="Note"
                                                        message="Please note as per the NSW RTA, you are required to give 14 days notice from the Property being offered for sale."
                                                        hideCloseButton={true}
                                                        messageTextClassName="message"
                                                    />
                                                </div>
                                            </div>
                                            <div className="calender">
                                                <DatePickerInAgencyTimeZone
                                                    label="Available for sale from"
                                                    name="availableForSaleFrom"
                                                    selected={values.availableForSaleFrom}
                                                    disabled={values.disabled}
                                                    required
                                                />
                                            </div>
                                            <FormRadioGroup
                                                label="Has a selling agency been appointed?"
                                                name="hasASellingAgencyBeenAppointed"
                                                data={[
                                                    {
                                                        label: 'Yes',
                                                        value: YES_VALUE,
                                                        disabled: values.disabled
                                                    },
                                                    {
                                                        label: 'No',
                                                        value: NO_VALUE,
                                                        disabled: values.disabled
                                                    }
                                                ]}
                                                value={values.hasASellingAgencyBeenAppointed}
                                                required
                                            />
                                            {values.hasASellingAgencyBeenAppointed === YES_VALUE && (
                                                <div>
                                                    <div className="agency-details">
                                                        <div className="details">
                                                            <p className="name">Agency details</p>
                                                            <div className="agency-details-item">
                                                                <FormTextRegular
                                                                    name="agencyName"
                                                                    label="Agency name"
                                                                    required
                                                                    disabled={values.disabled}
                                                                />
                                                                <FormTextRegular
                                                                    name="agencyAgentName"
                                                                    label="Agent name"
                                                                    disabled={values.disabled}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormTextMultiline
                                                        name="inspectionComments"
                                                        label="Inspection comments"
                                                        disabled={values.disabled}
                                                        required
                                                        isChatGpt={true}
                                                        gpt_fieldName="inspectionComments"
                                                        gpt_isDocument={true}
                                                        gpt_documentId={currentDocumentId}
                                                    />
                                                </div>
                                            )}
                                            <DeliveryTypeComponent
                                                emailAgentAndTenantHandler={emailAgentAndTenantHandler}
                                                addEmailFieldError={addEmailFieldError}
                                                values={values}
                                                form={form}
                                            />
                                            <div className="calender">
                                                <DatePickerInAgencyTimeZone
                                                    label="Date of the notice"
                                                    name="dateOfNotice"
                                                    selected={values.dateOfNotice}
                                                    minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                                    disabled={
                                                        values.disabled ||
                                                        values.deliveryType === DELIVERY_TYPE_EMAIL
                                                    }
                                                    required
                                                />
                                            </div>

                                            <br />
                                            <IntentionToSellFormFooter
                                                isSendingIntentionToSellNotice={isSendingIntentionToSellNotice || isSaveDraftLoading}
                                                isSendingIntentionToSellNoticeSuccess={
                                                    isSendingIntentionToSellNoticeSuccess || isSaveDraft
                                                }
                                                isSendingIntentionToSellNoticeFail={isSendingIntentionToSellNoticeFail}
                                                currentDocument={currentDocument}
                                                documentEditMode={documentEditMode}
                                                previewPdf={() => previewPdf(values)}
                                                viewPdf={viewPdf}
                                                form={form}
                                                isSaveDraft={isSaveDraft}
                                                showLoadingIcon={showLoadingIcon}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!locationAndAddressSelected && (
                                    <LocationAndAddressSelection
                                        location={location}
                                        allowedStates={allowedStates}
                                        setLocation={setLocation}
                                        closeModal={closeModal}
                                        confirmDetails={confirmDetails}
                                        address={values.address}
                                        handleAddressChange={(address: string) =>  form.change('address', address)}
                                        setAddressDetails={(address: string, details: DEFINE_ME) =>
                                            setAddressDetails(address, details, values)
                                        }
                                        setIntegrationProperty={(property: DEFINE_ME) => setIntegrationProperty(property)}
                                        isCreating={isSendingIntentionToSellNotice}
                                        defaultIsIntegrationSearch={false}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
                <EditSenderConfirmation
                    isOpen={showEditSenderConfirmationModel}
                    close={closeEditSenderConfirmationModel}
                    primaryHandler={submitConfirmation}
                    secondaryHandler={closeEditSenderConfirmationModel}
                    currentAgentName={agent.label}
                    loggedUserInfo={{
                        firstName: loggedInUser?.firstName,
                        fullName: loggedInUser?.fullName
                    }}
                    isLoading={false}
                    docType={BREACH_NOTICE}
                />
            </ModalDialog>
        </React.Fragment>
    );
};
export default memo(IntentionToSellForm);
