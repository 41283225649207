import * as React from 'react';
import { Form } from 'react-final-form';
import { has, isEmpty, remove, isString, cloneDeep } from 'lodash';
import { updateTenant, clearValidationErrors, getTenantAndProgressSteps } from '../../actions/lease';
import EditTenantPersonGroup from '../lease/mainScreen/common/EditTenantPersonGroup';
import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import * as validators from '../Validate';

import 'sass/tenant.scss';

const EditTenant = () => {
    const dispatch = useDispatch();

    const address = useSelector(state => state.lease.common.address, shallowEqual);
    const tenant = useSelector(state => state.lease.tenant, shallowEqual);
    const hasCompleted = useSelector(state => state.lease.common.leaseAllInfo.tenant, shallowEqual);
    const validationErrors = useSelector(state => state.lease.common.validationErrors, shallowEqual);
    const status = useSelector(state => state.dashboard.agreementInfo.status, shallowEqual);
    const agreementInfo = useSelector(state => state.dashboard.agreementInfo, shallowEqual);
    const isSubmittingLease = useSelector(state => state.lease.common.isSubmittingLease, shallowEqual);

    const isTenantConfirmedError = () => {
        if (
            validationErrors &&
            validationErrors.tenant &&
            validationErrors.tenant.tenants &&
            validationErrors.tenant.tenants[0] &&
            validationErrors.tenant.tenants[0].isEditable
        ) {
            return true;
        }
        return false;
    };
    const isEditable = () => {
        let editable = false;
        tenant.tenants.forEach(user => {
            if (
                isEmpty(user.steps) ||
                !user.steps.steps ||
                !user.steps.steps[2] ||
                user.steps.steps[2].status !== 'confirmed'
            ) {
                editable = true;
            }
        });
        return editable;
    };

    useEffect(() => {
        dispatch(getTenantAndProgressSteps(agreementInfo.id));
    }, []);

    useEffect(() => {
        if (isTenantConfirmedError()) {
            dispatch(getTenantAndProgressSteps(agreementInfo.id));
        }
    }, [validationErrors]);

    const validate = value => {
        if (isEmpty(value)) {
            return false;
        }

        return validators.isCorrectEmail(value);
    };

    const submitValues = values => {
        const data = cloneDeep(values);
        data.tenants.forEach(tenant => {
            if (typeof tenant.email === 'undefined') {
                tenant.email = '';
            }
        });
        if (data.isServicesOfNoticesEmail) {
            tenant.tenants.forEach((tenant, index) => {
                if (validate(data.tenants[index].email) && tenant.email !== data.tenants[index].email) {
                    remove(data.servicesOfNoticesEmails, email => {
                        return email === tenant.email;
                    });
                    data.servicesOfNoticesEmails.push(data.tenants[index].email);
                }
            });
        }
        dispatch(updateTenant(agreementInfo.id, data, agreementInfo.deadline));
    };
    return (
        <div className="tenant">
            <Form
                onSubmit={submitValues}
                initialValues={tenant}
                validate={() => {
                    return validationErrors && validationErrors.tenant ? validationErrors.tenant : {};
                }}
            >
                {({ handleSubmit, errors, values }) => {
                    return (
                        <form id="update-tenant-detail-form" onSubmit={handleSubmit} noValidate>
                            <div className="tenants">
                                {validationErrors &&
                                    validationErrors.tenant &&
                                    validationErrors.tenant.tenants &&
                                    validationErrors.tenant.tenants[0] &&
                                    validationErrors.tenant.tenants[0].isEditable && (
                                        <p className="FormInput">
                                            <span className="FormError">
                                                {validationErrors.tenant.tenants[0].isEditable}
                                            </span>
                                        </p>
                                    )}
                                {tenant.tenants.map((tenant, index) => (
                                    <EditTenantPersonGroup key={index} index={index} tenant={tenant} status={status} />
                                ))}
                            </div>
                            {has(errors, 'tenants') && isString(errors.tenants) && (
                                <div className="has-error custom-error-message">{errors.tenants}</div>
                            )}
                            <div className="btn-edit-signatory">
                                <button
                                    type="button"
                                    disabled={isSubmittingLease || !isEditable()}
                                    onClick={() => submitValues(values)}
                                >
                                    {isSubmittingLease && <span className="savestatus-spinner-white" />}
                                    Save changes
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default EditTenant;
