import * as React from 'react';
import DatePicker from 'react-datepicker';
import { Field } from 'react-final-form';
import { STANDARD_DATE_FORMAT, STANDARD_DATE_TIME_FORMAT_DATE } from '../../config';

import '../../sass/react-datapicker.scss';
import { getError } from '../../utils/formUtils';
import useComponentId from '../../hooks/useComponentId';
import cx from 'classnames';
import Suggestion from '../suggestions/Suggestion';

import { isDate, parseISO, isValid, setHours, format } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { isEmpty } from 'lodash';

/**
 * Set the date to midday
 * @param date
 * @returns {*}
 */
const setToMidday = date => {
    let newDate;
    if (!isDate(date)) {
        date = parseISO(date);
    }

    // always set the time to midday, 12AM.
    newDate = setHours(date, 12);
    return newDate;
};

const DatePickerInput = ({
    input,
    precision,
    name,
    value,
    disabled,
    selected,
    minDate,
    maxDate,
    onChange,
    meta,
    validateOnTouch,
    label,
    subLabel,
    required,
    disabledLabel,
    popperPlacement,
    showPopperArrow,
    showTimeSelect,
    timeZone,
    className,
    filterDate,
    isClearable,
    newVersion,
    toolTipIcon,
    staticText,
    suggestionsList,
    removeSuggestion,
    suggestionIcon,
    ...rest
}) => {
    const id = useComponentId();
    let newInput = { ...input };
    if (selected && !isDate(selected)) {
        if (showTimeSelect) {
            selected = parseISO(selected);
        } else {
            selected = parseISO(setToMidday(selected));
        }
    }

    if (!isValid(selected)) {
        selected = null;
    }

    const handleSuggestionClick = (suggestion, index) => {
        if (suggestion && timeZone) {
            let utcDate = showTimeSelect
                ? zonedTimeToUtc(suggestion, timeZone)
                : zonedTimeToUtc(setToMidday(suggestion), timeZone);

            newInput.onChange(utcDate);
            if (onChange) {
                onChange(utcDate);
            }
        } else if (suggestion) {
            newInput.onChange(suggestion);
            if (onChange) {
                onChange(suggestion);
            }
        }

        // Remove the suggestion from the list
        removeSuggestion(index);
    };

    const handleEndIconClick = (e, index) => {
        e.stopPropagation();
        removeSuggestion(index);
    };

    return (
        <div
            className={cx(
                className,
                'form-date-picker',
                'FormInput',
                { 'FormInput--error': getError(meta, validateOnTouch) },
                { 'new-version': newVersion }
            )}
        >
            <div className={input.value ? 'form-group regular on' : 'form-group regular'}>
                <label
                    className={
                        getError(meta, validateOnTouch)
                            ? `date-picker-label FormError ${disabledLabel}`
                            : `date-picker-label ${disabledLabel}`
                    }
                    htmlFor={id}
                >
                    {label}
                    {required ? ' *' : null}
                </label>
                {toolTipIcon && <span>{toolTipIcon}</span>}
                {staticText && <span className="static-text">{staticText}</span>}
                {!isEmpty(suggestionsList) && (
                    <div className="with-suggestions">
                        {suggestionsList.map((suggestion, index) => (
                            <Suggestion
                                className={'form-suggestions'}
                                key={index}
                                text={format(new Date(suggestion), STANDARD_DATE_FORMAT)}
                                icon={suggestionIcon}
                                onClick={() => handleSuggestionClick(suggestion, index)}
                                onClickEndIcon={e => handleEndIconClick(e, index)}
                            />
                        ))}
                    </div>
                )}
                <DatePicker
                    id={id}
                    className="datepicker-input"
                    name={name}
                    {...newInput}
                    onChangeRaw={() => {
                        // this is used to select min date or max date if typed date is blocked by min/max date
                        // otherwise user can't continue typing
                        let date = null;
                        if (minDate) {
                            date = minDate;
                        } else if (maxDate) {
                            date = maxDate;
                        }
                        if (date && timeZone) {
                            // get utc date for agent timezone
                            let utcDate;
                            if (showTimeSelect) {
                                utcDate = zonedTimeToUtc(date, timeZone);
                            } else {
                                utcDate = zonedTimeToUtc(setToMidday(date), timeZone);
                            }
                            newInput.onChange(utcDate);
                            if (onChange) {
                                onChange(utcDate);
                            }
                        } else if (date) {
                            newInput.onChange(date);
                            if (onChange) {
                                onChange(date);
                            }
                        }
                    }}
                    onChange={item => {
                        // always set the time to midday, 12AM.
                        if (item && isDate(item)) {
                            if (!showTimeSelect) {
                                item = setToMidday(item);
                            }
                            if (timeZone) {
                                // get utc date for agent timezone
                                let utcDate = zonedTimeToUtc(item, timeZone);
                                newInput.onChange(utcDate);
                                if (onChange) {
                                    onChange(utcDate);
                                }
                            } else {
                                newInput.onChange(item);
                                if (onChange) {
                                    onChange(item);
                                }
                            }
                        } else {
                            newInput.onChange(null);
                            if (onChange) {
                                onChange(null);
                            }
                        }
                    }}
                    //show agent timezone date
                    selected={selected ? (timeZone ? utcToZonedTime(selected, timeZone) : selected) : null}
                    dateFormat={showTimeSelect ? STANDARD_DATE_TIME_FORMAT_DATE : STANDARD_DATE_FORMAT}
                    disabled={disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    autoComplete="off"
                    showPopperArrow={showPopperArrow}
                    popperPlacement={popperPlacement ? popperPlacement : 'bottom-start'}
                    // force popper to stay in mentioned placement
                    popperModifiers={{
                        flip: {
                            enabled: false
                        },
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false
                        }
                    }}
                    showTimeSelect={showTimeSelect}
                    openToDate={!selected && timeZone ? utcToZonedTime(new Date(), timeZone) : undefined}
                    filterDate={filterDate}
                    isClearable={isClearable}
                />
                {subLabel && <p className="sub-label">{subLabel}</p>}
                {getError(meta, validateOnTouch) && <span className={'FormError'}>{getError(meta)}</span>}
            </div>
        </div>
    );
};

class FormDatePicker extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            name,
            disabled,
            minValue,
            maxValue,
            onChange,
            selected,
            validateOnTouch,
            label,
            subLabel,
            required,
            popperPlacement,
            showPopperArrow,
            showTimeSelect,
            timeZone,
            className,
            filterDate,
            isClearable,
            newVersion,
            toolTipIcon,
            staticText,
            suggestionsList,
            removeSuggestion,
            suggestionIcon
        } = this.props;
        let disabledLabel = '';
        if (true === disabled) {
            disabledLabel = 'disabled';
        }
        let minDate = null;
        let maxDate = null;

        if (maxValue) {
            maxDate = new Date(maxValue);
        }

        if (minValue) {
            minDate = new Date(minValue);
        }

        return (
            <Field
                name={name}
                onChange={onChange}
                selected={selected ? selected : null}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                render={DatePickerInput}
                disabledLabel={disabledLabel}
                validateOnTouch={validateOnTouch}
                label={label}
                subLabel={subLabel}
                required={required}
                popperPlacement={popperPlacement}
                showPopperArrow={typeof showPopperArrow === 'undefined' ? true : showPopperArrow}
                showTimeSelect={showTimeSelect}
                timeZone={timeZone}
                className={className}
                filterDate={filterDate}
                isClearable={isClearable}
                newVersion={newVersion}
                toolTipIcon={toolTipIcon}
                staticText={staticText}
                suggestionsList={suggestionsList}
                removeSuggestion={removeSuggestion}
                suggestionIcon={suggestionIcon}
            />
        );
    }
}

export default FormDatePicker;
