import { combineReducers } from 'redux';
import { OPEN_TERMINATE_LEASE_MODAL, CLOSE_TERMINATE_LEASE_MODAL } from '../actions/types';
import produce from 'immer';
import { cloneDeep, isArray, merge, get, set } from 'lodash';
import { statefulActions } from './utils';

import landlordReducer from './lease/landlord';
import tenantReducer from './lease/tenant';
import tenantAgentReducer from './lease/tenantAgent';
import rentReducer from './lease/rent';
import occupantsReducer from './lease/occupants';
import contactReducer, * as Contacts from './lease/contact';
import additionalReducer from './lease/additional';
import documentsReducer from './lease/documents';
import connectionsReducer from './lease/connections';
import agentAuthorityReducer from './lease/agentAuthority';
import feesReducer from './lease/fees';
import materialFactsReducer from './lease/materialFacts';
import expensesAndChargesReducer from './lease/expensesAndCharges';
import otherServicesReducer from './lease/otherServices';
import advertisingReducer from './lease/advertising';
import leaseRentAndTermReducer from './lease/leaseRentAndTerm';
import outgoingsReducer from './lease/outgoings';
import insuranceReducer from './lease/insurance';
import * as Dashboard from './dashboard';
import * as LeaseWithRequest from '../reducers/leaseWithRequest';
import {
    booleanToString,
    getLeaseTypeForFrontEnd,
    getTotalStepsCount,
    isTemplate,
    mappingsToFrontEnd
} from '../utils/agreementUtils';
import inclusionsReducer from './lease/inclusions'; // needs new one
import termReducer from './lease/term'; // create new
import exclusionsReducer from './lease/exclusions';
import petsReducer from './lease/pets';
import repairsAndMaintenanceReducer from './lease/repairsAndMaintenance';
import nominatedRepairersReducer from './lease/nominatedRepairers';
import disclosureReducer from './lease/disclosure';
import instructionsReducer from './lease/instructions';
import executedAsAgreementReducer from './lease/executedAsAgreement';

import { isLeaseTypePm, isLeaseTypeSales, isLeaseTypeCommercialLease } from '../utils/agreementUtils';

import * as Renewal from './../reducers/renewal';

/** Sales Related Reducers **/
import signatoryReducer from './sales/signatory';
import salesLandlordReducer from './sales/landlord';
import landlordsSolicitorReducer from './sales/landlordsSolicitor';
import salesInclusionsReducer from './sales/inclusions';
import priceAndFeeReducer from './sales/priceAndFee';
import salesTermReducer from './sales/term';
import salesExpensesAndChargesReducer from './sales/salesExpensesAndCharges';
import additionalSalesTermsReducer from './sales/additionalSalesTerms';
import managingAgentReducer from './sales/managingAgent';
import propertyDetailsReducer from './sales/propertyDetails';
import propertyDescriptionReducer from './sales/propertyDescription';
import agreementTermReducer from './sales/agreementTerm';

import leaseDataReducer from './lease/leaseData';
// import helpers
import {
    CLOSE_AGREEMENT_INFO_MODAL,
    FINISH_COMPLETION_PROCESS_SUCCESS,
    SOCKET_UPDATE_AGREEMENT,
    UPDATE_ADDRESS_REQUEST_SUCCESS
} from './dashboard';
import {
    DEFAULT_SUB_LEASE_TYPE,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_SALES,
    NSW_STATE,
    QLD_STATE,
    SA_STATE,
    VIC_STATE
} from '../config';
import { LANDLORD, PM_LANDLORD, SALES_SIGNATORY, steps } from '../components/lease/steps';

export const OPEN_LEASE_AGREEMENT_FORM = 'OPEN_LEASE_AGREEMENT_FORM';
export const CLOSE_LEASE_AGREEMENT_FORM = 'CLOSE_LEASE_AGREEMENT_FORM';

export const OPEN_AGREEMENT_TEMPLATE_FORM = 'OPEN_AGREEMENT_TEMPLATE_FORM';
export const CLOSE_AGREEMENT_TEMPLATE_FORM = 'CLOSE_AGREEMENT_TEMPLATE_FORM';

export const OPEN_CLONE_RES_TEN_FORM = 'OPEN_CLONE_RES_TEN_FORM';
export const CLOSE_CLONE_RES_TEN_FORM = 'CLOSE_CLONE_RES_TEN_FORM';

export const CLONE_AGREEMENT_EXTRA_REQUEST = 'CLONE_AGREEMENT_EXTRA_REQUEST';
export const CLONE_AGREEMENT_EXTRA_SUCCESS = 'CLONE_AGREEMENT_EXTRA_SUCCESS';
export const CLONE_AGREEMENT_EXTRA_FAILURE = 'CLONE_AGREEMENT_EXTRA_FAILURE';

export const SET_LEASE_AGREEMENT_TYPE = 'SET_LEASE_AGREEMENT_TYPE';
export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';

export const EDIT_LEASE_AGREEMENT_FORM = 'EDIT_LEASE_AGREEMENT_FORM';

export const INITIALIZE_NSW_LEASE_AGREEMENT_FORM = 'INITIALIZE_NSW_LEASE_AGREEMENT_FORM';
export const INITIALIZE_QLD_LEASE_AGREEMENT_FORM = 'INITIALIZE_QLD_LEASE_AGREEMENT_FORM';
export const INITIALIZE_VIC_LEASE_AGREEMENT_FORM = 'INITIALIZE_VIC_LEASE_AGREEMENT_FORM';
export const INITIALIZE_ACT_LEASE_AGREEMENT_FORM = 'INITIALIZE_ACT_LEASE_AGREEMENT_FORM';
export const INITIALIZE_SA_LEASE_AGREEMENT_FORM = 'INITIALIZE_SA_LEASE_AGREEMENT_FORM';

export const FINISH_EDITING_LEASE_AGREEMENT_REQUEST = 'FINISH_EDITING_LEASE_AGREEMENT_REQUEST';
export const FINISH_EDITING_LEASE_AGREEMENT_SUCCESS = 'FINISH_EDITING_SUCCESS';
export const FINISH_EDITING_MODE = 'FINISH_EDITING_MODE';
export const SET_SIDE_MENU_STEP = 'SET_SIDE_MENU_STEP';
export const MOVE_TO_NEXT_STEP_EDITING_MODE = 'MOVE_TO_NEXT_STEP_EDITING_MODE';

export const FINISH_EDITING_TEMPLATE_AGREEMENT_REQUEST = 'FINISH_EDITING_TEMPLATE_AGREEMENT_REQUEST';
export const FINISH_EDITING_TEMPLATE_AGREEMENT_SUCCESS = 'FINISH_EDITING_TEMPLATE_AGREEMENT_SUCCESS';

export const EDIT_LEASE_AGREEMENT_ADDRESS = 'EDIT_LEASE_AGREEMENT_ADDRESS';
export const EDIT_LEASE_AGREEMENT_AGENT = 'EDIT_LEASE_AGREEMENT_AGENT';
export const UPDATE_LEASE_AGREEMENT_ADDRESS = 'UPDATE_LEASE_AGREEMENT_ADDRESS';

export const SEND_REMOVE_OCCUPANT_REQUEST = 'SEND_REMOVE_OCCUPANT_REQUEST';
export const SEND_REMOVE_OCCUPANT_SUCCESS = 'SEND_REMOVE_OCCUPANT_SUCCESS';
export const SEND_REMOVE_OCCUPANT_FAIL = 'SEND_REMOVE_OCCUPANT_FAIL';

export const SET_ACTIVE_REQUEST = 'SET_ACTIVE_REQUEST';

export const RESOLVE_REQUEST_TO_CHANGE_REQUEST = 'RESOLVE_REQUEST_TO_CHANGE_REQUEST';

export const RESOLVE_REQUEST_TO_CHANGE_FAIL = 'RESOLVE_REQUEST_TO_CHANGE_FAIL';
export const UNRESOLVE_REQUEST_TO_CHANGE_REQUEST = 'UNRESOLVE_REQUEST_TO_CHANGE_REQUEST';

export const UNRESOLVE_REQUEST_TO_CHANGE_FAIL = 'UNRESOLVE_REQUEST_TO_CHANGE_FAIL';
export const CHANGE_REQUEST_TO_CHANGE_SUCCESS = 'CHANGE_REQUEST_TO_CHANGE_SUCCESS';

export const GET_ADDRESSES_REQUEST = 'dashboard.lease.get.addresses.request';
export const GET_ADDRESSES_SUCCESS = 'dashboard.lease.get.addresses.succes';
export const GET_ADDRESSES_FAIL = 'dashboard.lease.get.addresses.fail';

export const CREATE_LEASE_REQUEST = 'dashboard.lease.create.request';
export const CREATE_LEASE_SUCCESS = 'dashboard.lease.create.success';
export const CREATE_LEASE_FAILURE = 'dashboard.lease.create.failure';

export const EDIT_TEMPLATE_REQUEST = 'EDIT_TEMPLATE_REQUEST';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_FAILURE = 'EDIT_TEMPLATE_FAILURE';

export const CREATE_SALES_LEASE_REQUEST = 'dashboard.sales.create.request';
export const CREATE_SALES_LEASE_SUCCESS = 'dashboard.sales.create.success';
export const CREATE_SALES_LEASE_FAILURE = 'dashboard.sales.create.failure';

export const CREATE_PM_LEASE_REQUEST = 'dashboard.pm.create.request';
export const CREATE_PM_LEASE_SUCCESS = 'dashboard.pm.create.success';
export const CREATE_PM_LEASE_FAILURE = 'dashboard.pm.create.failure';

export const CREATE_COMMERCIAL_LEASE_REQUEST = 'dashboard.commercial.lease.create.request';
export const CREATE_COMMERCIAL_LEASE_SUCCESS = 'dashboard.commercial.lease.create.success';
export const CREATE_COMMERCIAL_LEASE_FAILURE = 'dashboard.commercial.lease.create.failure';

export const CREATE_HOLIDAY_LETTING_REQUEST = 'dashboard.holiday.letting.create.request';
export const CREATE_HOLIDAY_LETTING_SUCCESS = 'dashboard.holiday.letting.create.success';
export const CREATE_HOLIDAY_LETTING_FAILURE = 'dashboard.holiday.letting.create.failure';

export const CLOSE_LEASE_MODAL = 'dashboard.lease.close.modal';

export const LEASE_LANDLORD_REQUEST = 'dashboard.lease.set.landlord.request';
export const LEASE_LANDLORD_SUCCESS = 'LEASE_LANDLORD_SUCCESS';
export const LEASE_TENANT_SUCCESS = 'LEASE_TENANT_SUCCESS';
export const SET_DIRTY_STEP = 'SET_DIRTY_STEP';
export const RESET_DIRTY_STEP = 'RESET_DIRTY_STEP';
export const SET_NAVIGATION_STATE = 'SET_NAVIGATION_STATE';

export const LEASE_SIGNATORY_REQUEST = 'dashboard.lease.set.signatory.request';
export const LEASE_SIGNATORY_SUCCESS = 'dashboard.lease.set.signatory.success';

export const LEASE_SALES_LANDLORD_REQUEST = 'dashboard.sales.set.landlord.request';
export const LEASE_SALES_LANDLORD_SUCCESS = 'dashboard.sales.set.landlord.success';

export const LEASE_SALES_TERM_REQUEST = 'dashboard.lease.set.salesTerm.request';
export const LEASE_SALES_TERM_SUCCESS = 'dashboard.sales.set.salesTerm.success';

export const LEASE_TENANT_REQUEST = 'dashboard.lease.set.tenant.request';

export const UPDATE_TENANT_REQUEST = 'edit.lease.tenant.request';
export const UPDATE_TENANT_SUCCESS = 'edit.lease.tenant.success';

export const UPDATE_LANDLORD_REQUEST = 'edit.lease.landlord.request';
export const UPDATE_LANDLORD_SUCCESS = 'edit.lease.landlord.success';

export const UPDATE_VENDOR_REQUEST = 'edit.lease.vendor.request';
export const UPDATE_VENDOR_SUCCESS = 'edit.lease.vendor.success';

export const LEASE_TENANT_DETAILS_REQUEST = 'dashboard.lease.set.tenant.details.request';
export const LEASE_TENANT_DETAILS_SUCCESS = 'dashboard.lease.set.tenant.details.success';

export const LEASE_LANDLORD_DETAILS_REQUEST = 'dashboard.lease.set.landlord.details.request';
export const LEASE_LANDLORD_DETAILS_SUCCESS = 'dashboard.lease.set.landlord.details.success';

export const LEASE_VENDOR_DETAILS_REQUEST = 'dashboard.lease.set.vendor.details.request';
export const LEASE_VENDOR_DETAILS_SUCCESS = 'dashboard.lease.set.vendor.details.success';

export const LEASE_TENANTS_AGENT_REQUEST = 'dashboard.lease.set.tenants.agent.request';
export const LEASE_TENANTS_AGENT_SUCCESS = 'dashboard.lease.set.tenants.agent.success';

export const LEASE_TERM_REQUEST = 'dashboard.lease.set.term.request';
export const LEASE_TERM_SUCCESS = 'dashboard.lease.set.term.success';

export const LEASE_RENT_REQUEST = 'dashboard.lease.set.rent.request';
export const LEASE_RENT_SUCCESS = 'dashboard.lease.set.rent.success';
export const LEASE_PAYMENT_REQUEST = 'dashboard.lease.set.payment.request';
export const LEASE_PAYMENT_SUCCESS = 'dashboard.lease.set.payment.success';
export const PM_LEASE_PAYMENT_SUCCESS = 'dashboard.pm.lease.set.payment.success';

export const LEASE_INCLUSIONS_REQUEST = 'dashboard.lease.set.inclusions.request';
export const LEASE_INCLUSIONS_SUCCESS = 'dashboard.lease.set.inclusions.success';

export const LEASE_EXCLUSIONS_REQUEST = 'dashboard.lease.set.exclusions.request';
export const LEASE_EXCLUSIONS_SUCCESS = 'dashboard.lease.set.exclusions.success';

export const LEASE_OCCUPANTS_REQUEST = 'dashboard.lease.set.occupants.request';
export const LEASE_OCCUPANTS_SUCCESS = 'dashboard.lease.set.occupants.success';

export const LEASE_CONTACTS_REQUEST = 'dashboard.lease.set.contacts.request';
export const LEASE_CONTACTS_SUCCESS = 'dashboard.lease.set.contacts.success';

export const LEASE_ADDITIONAL_REQUEST = 'dashboard.lease.set.additional.request';
export const LEASE_ADDITIONAL_SUCCESS = 'dashboard.lease.set.additional.success';

export const LEASE_CONDITIONS_REQUEST = 'dashboard.lease.set.condition.request';
export const LEASE_CONDITIONS_SUCCESS = 'dashboard.lease.set.condition.success';

export const LEASE_SALES_EXPENSES_CHARGES_REQUEST = 'LEASE_SALES_EXPENSES_CHARGES_REQUEST';
export const LEASE_SALES_EXPENSES_CHARGES_SUCCESS = 'LEASE_SALES_EXPENSES_CHARGES_SUCCESS';

export const LEASE_DOCUMENTS_REQUEST = 'DASHBOARD_LEASE_SET_DOCUMENTS_REQUEST';
export const LEASE_DOCUMENTS_SUCCESS = 'DASHBOARD_LEASE_SET_DOCUMENTS_SUCCESS';
export const UPDATE_DOCUMENT_UPLOAD_PROGRESS = 'UPDATE_DOCUMENT_UPLOAD_PROGRESS';

export const UPDATE_TENANT_LINKS_REQUEST = 'UPDATE_TENANT_LINKS_REQUEST';
export const UPDATE_TENANT_LINKS_SUCCESS = 'UPDATE_TENANT_LINKS_SUCCESS';
export const UPDATE_TENANT_LINKS_FAIL = 'UPDATE_TENANT_LINKS_FAIL';

export const LEASE_CONNECTIONS_REQUEST = 'dashboard.lease.set.connections.request';
export const LEASE_CONNECTIONS_SUCCESS = 'dashboard.lease.set.connections.success';

export const LEASE_SALES_INCLUSIONS_REQUEST = 'dashboard.lease.set.salesInclusions.request';
export const LEASE_SALES_INCLUSIONS_SUCCESS = 'dashboard.lease.set.salesInclusions.success';

export const LEASE_LANDLORD_SOLICITOR_REQUEST = 'dashboard.lease.set.landlordsSolicitor.request';
export const LEASE_LANDLORD_SOLICITOR_SUCCESS = 'dashboard.lease.set.landlordsSolicitor.success';

export const LEASE_LICENSEE_REQUEST = 'dashboard.lease.set.licensee.request';
export const LEASE_LICENSEE_SUCCESS = 'dashboard.lease.set.licensee.success';

export const LEASE_MANAGING_AGENT_SUCCESS = 'dashboard.lease.set.managing.agent.success';

export const LEASE_PROPERTY_DETAILS_SUCCESS = 'dashboard.lease.set.property.details.success';

export const LEASE_PROPERTY_DESCRIPTION_SUCCESS = 'dashboard.lease.set.property.description.success';

export const LEASE_PRICE_AND_FEE_REQUEST = 'dashboard.lease.set.priceAndFee.request';
export const LEASE_PRICE_AND_FEE_SUCCESS = 'dashboard.lease.set.priceAndFee.success';

export const LEASE_MARKETING_AND_ADVERTISING_REQUEST = 'dashboard.lease.set.marketingAndAdvertising.request';
export const LEASE_MARKETING_AND_ADVERTISING_SUCCESS = 'dashboard.lease.set.marketingAndAdvertising.success';

export const LEASE_INSPECTION_DAYS_REQUEST = 'dashboard.lease.set.inspectionDays.request';
export const LEASE_INSPECTION_DAYS_SUCCESS = 'dashboard.lease.set.inspectionDays.success';

export const LEASE_AGREEMENT_TERM_REQUEST = 'dashboard.lease.set.agreementTerm.request';
export const LEASE_AGREEMENT_TERM_SUCCESS = 'dashboard.lease.set.agreementTerm.success';

export const LEASE_AGENT_AUTHORITY_REQUEST = 'dashboard.lease.set.agentAuthority.request';
export const LEASE_AGENT_AUTHORITY_SUCCESS = 'dashboard.lease.set.agentAuthority.success';

export const LEASE_REPAIRS_REQUEST = 'dashboard.lease.set.repairs.request';
export const LEASE_REPAIRS_SUCCESS = 'dashboard.lease.set.repairs.success';

export const LEASE_EXPENSES_AND_CHARGES_REQUEST = 'dashboard.lease.set.expensesAndCharges.request';
export const LEASE_EXPENSES_AND_CHARGES_SUCCESS = 'dashboard.lease.set.expensesAndCharges.success';

export const LEASE_PM_EXPENSES_AND_CHARGES_REQUEST = 'dashboard.lease.set.pm.expensesAndCharges.request';
export const LEASE_PM_EXPENSES_AND_CHARGES_SUCCESS = 'dashboard.lease.set.pm.expensesAndCharges.success';

export const LEASE_OTHER_SERVICES_REQUEST = 'dashboard.lease.set.otherServices.request';
export const LEASE_OTHER_SERVICES_SUCCESS = 'dashboard.lease.set.otherServices.success';

export const LEASE_PM_OTHER_SERVICES_REQUEST = 'dashboard.lease.set.pm.otherServices.request';
export const LEASE_PM_OTHER_SERVICES_SUCCESS = 'dashboard.lease.set.pm.otherServices.success';

export const LEASE_PM_DETAILS_REPAIRS_REQUEST = 'dashboard.lease.set.pm.detailsRepairs.request';
export const LEASE_PM_DETAILS_REPAIRS_SUCCESS = 'dashboard.lease.set.pm.detailsRepairs.success';

export const LEASE_PM_RENT_TERM_REQUEST = 'dashboard.lease.set.pm.rentTerm.request';
export const LEASE_PM_RENT_TERM_SUCCESS = 'dashboard.lease.set.pm.rentTerm.success';

export const LEASE_PM_RENT_PAYMENT_REQUEST = 'dashboard.lease.set.pm.rentpayment.request';
export const LEASE_PM_RENT_PAYMENT_SUCCESS = 'dashboard.lease.set.pm.rentpayment.success';

export const LEASE_ADVERTISING_REQUEST = 'dashboard.lease.set.advertising.request';
export const LEASE_ADVERTISING_SUCCESS = 'dashboard.lease.set.advertising.success';

export const LEASE_FEES_REQUEST = 'dashboard.lease.set.fees.request';
export const LEASE_FEES_SUCCESS = 'dashboard.lease.set.fees.success';

export const LEASE_MATERIAL_FACTS_REQUEST = 'dashboard.lease.set.material.facts.request';
export const LEASE_MATERIAL_FACTS_SUCCESS = 'dashboard.lease.set.material.facts.success';

export const LEASE_PM_FEES_REQUEST = 'dashboard.lease.set.pm.fees.request';
export const LEASE_PM_FEES_SUCCESS = 'dashboard.lease.set.pm.fees.success';

export const LEASE_PM_AUTHORITY_PERIOD_REQUEST = 'dashboard.lease.set.pm.authorityPeriod.request';
export const LEASE_PM_AUTHORITY_PERIOD_SUCCESS = 'dashboard.lease.set.pm.authorityPeriod.success';

export const LEASE_PM_MARKETING_COSTS_REQUEST = 'dashboard.lease.set.pm.marketingCosts.request';
export const LEASE_PM_MARKETING_COSTS_SUCCESS = 'dashboard.lease.set.pm.marketingCosts.success';

export const LEASE_PM_ADDITIONAL_COSTS_REQUEST = 'dashboard.lease.set.pm.additionalCosts.request';
export const LEASE_PM_ADDITIONAL_COSTS_SUCCESS = 'dashboard.lease.set.pm.additionalCosts.success';

export const LEASE_PM_SERVICES_SCHEDULE_REQUEST = 'dashboard.lease.set.pm.servicesSchedule.request';
export const LEASE_PM_SERVICES_SCHEDULE_SUCCESS = 'dashboard.lease.set.pm.servicesSchedule.success';

export const LEASE_PM_LANDLORD_REQUEST = 'dashboard.lease.set.pm.landlord.request';
export const LEASE_PM_LANDLORD_SUCCESS = 'dashboard.lease.set.pm.landlord.success';

export const LEASE_RENT_AND_TERM_REQUEST = 'dashboard.lease.set.leaseRentAndTerm.request';
export const LEASE_RENT_AND_TERM_SUCCESS = 'dashboard.lease.set.leaseRentAndTerm.success';

export const LEASE_OUTGOINGS_REQUEST = 'dashboard.lease.set.outgoings.request';
export const LEASE_OUTGOINGS_SUCCESS = 'dashboard.lease.set.outgoings.success';

export const LEASE_INSURANCE_REQUEST = 'dashboard.lease.set.insurance.request';
export const LEASE_INSURANCE_SUCCESS = 'dashboard.lease.set.insurance.success';

export const LEASE_DISCLOSURE_REQUEST = 'dashboard.lease.set.disclosure.request';
export const LEASE_DISCLOSURE_SUCCESS = 'dashboard.lease.set.disclosure.success';

export const LEASE_INSTRUCTIONS_REQUEST = 'dashboard.lease.set.instructions.request';
export const LEASE_INSTRUCTIONS_SUCCESS = 'dashboard.lease.set.instructions.success';

export const LEASE_EXECUTED_AS_AGREEMENT_REQUEST = 'dashboard.lease.set.executedAsAgreement.request';
export const LEASE_EXECUTED_AS_AGREEMENT_SUCCESS = 'dashboard.lease.set.executedAsAgreement.success';

export const LEASE_REPAIRS_AND_MAINTENANCE_REQUEST = 'dashboard.lease.set.repairsAndMaintenance.request';
export const LEASE_REPAIRS_AND_MAINTENANCE_SUCCESS = 'dashboard.lease.set.repairsAndMaintenance.success';

export const LEASE_NOMINATED_REPAIRERS_REQUEST = 'dashboard.lease.set.nominatedRepairers.request';
export const LEASE_NOMINATED_REPAIRERS_SUCCESS = 'dashboard.lease.set.nominatedRepairers.success';

export const LEASE_PETS_REQUEST = 'dashboard.lease.set.pets.request';
export const LEASE_PETS_SUCCESS = 'dashboard.lease.set.pets.success';

export const LEASE_PM_OUTGOINGS_REQUEST = 'dashboard.lease.set.pm.outgoings.request';
export const LEASE_PM_OUTGOINGS_SUCCESS = 'dashboard.lease.set.pm.outgoings.success';

export const LEASE_WARRANTY_AND_MAINTENANCE_REQUEST = 'dashboard.lease.set.warranty.maintenance.request';
export const LEASE_WARRANTY_AND_MAINTENANCE_SUCCESS = 'dashboard.lease.set.warranty.maintenance.success';

export const LEASE_TENANT_PAYMENTS_REQUEST = 'dashboard.lease.set.pm.tenant.payments.request';
export const LEASE_TENANT_PAYMENTS_SUCCESS = 'dashboard.lease.set.pm.tenant.payments.success';

export const LEASE_PM_DUTIES_REQUEST = 'dashboard.lease.set.pm.duties.request';
export const LEASE_PM_DUTIES_SUCCESS = 'dashboard.lease.set.pm.duties.success';

export const LEASE_POOL_SAFETY_AND_SMOKE_ALARMS_REQUEST = 'dashboard.lease.set.pool.safety.smoke.alarms.request';
export const LEASE_POOL_SAFETY_AND_SMOKE_ALARMS_SUCCESS = 'dashboard.lease.set.pool.safety.smoke.alarms.success';

export const GET_INFO_LEASE_REQUEST = 'dashboard.lease.set.lease.request';
export const GET_INFO_LEASE_SUCCESS = 'dashboard.lease.set.lease.success';
export const GET_INFO_LEASE_FAIL = 'dashboard.lease.set.lease.fail';

export const POST_SIGNING_REQUEST = 'dashboard.lease.set.signing.request';
export const POST_SIGNING_SUCCESS = 'dashboard.lease.set.signing.success';
export const POST_SIGNING_FAIL = 'dashboard.lease.set.signing.fail';

export const RESEND_POST_SIGNING_REQUEST = 'RESEND_POST_SIGNING_REQUEST';
export const RESEND_POST_SIGNING_SUCCESS = 'RESEND_POST_SIGNING_SUCCESS';
export const RESEND_POST_SIGNING_FAIL = 'RESEND_POST_SIGNING_FAIL';

export const SEND_TERMINATE_LEASE_REQUEST = 'SEND_TERMINATE_LEASE_REQUEST';
export const SEND_TERMINATE_LEASE_SUCCESS = 'SEND_TERMINATE_LEASE_SUCCESS';
export const SEND_TERMINATE_LEASE_FAIL = 'SEND_TERMINATE_LEASE_FAIL';

export const CREATE_RENT_INCREASE_PDF_SUCCESS = 'CREATE_RENT_INCREASE_PDF_SUCCESS';
export const CREATE_ESP_LETTER_PDF_SUCCESS = 'CREATE_ESP_LETTER_PDF_SUCCESS';

export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';

export const UPDATE_TERMINATE_NOTICE_SENDER_EMAIL_ADDRESSES = 'UPDATE_TERMINATE_NOTICE_SENDER_EMAIL_ADDRESSES';

export const SET_EMAIL_LEASE_TERMINATION_PDF_SUCCESS = 'SET_EMAIL_LEASE_TERMINATION_PDF_SUCCESS';

export const LEASE_FORM_FAIL = 'DASHBOARD_LEASE_FORM_FAIL';
export const SUBMIT_FORM_FAIL = 'SUBMIT_FORM_FAIL';
export const CHANGE_STEP_SIGNAL = 'dashboard.lease.change.step';
export const GO_BACK_SIGNAL = 'dashboard.lease.change.step.go.back';

export const LEASE_FOOTER_DISABLE_SUBMIT = 'dashboard.lease.form.footer.disable.submit';
export const LEASE_FOOTER_ENABLE_SUBMIT = 'dashboard.lease.form.footer.enable.submit';

export const LEASE_CLEAR_VALIDATION_ERRORS = 'lease.form.clease.validation.errors';

export const REQUEST_RESOLVE_REQUEST = 'lease.form.with.request.resolve.send';
export const REQUEST_RESOLVE_SUCCESS = 'lease.form.with.request.resolve.success';
export const REQUEST_RESOLVE_FAIL = 'lease.form.with.request.resolve.fail';

export const REQUEST_UNRESOLVE_REQUEST = 'lease.form.with.request.unresolve.send';
export const REQUEST_UNRESOLVE_SUCCESS = 'lease.form.with.request.unresolve.success';
export const REQUEST_UNRESOLVE_FAIL = 'lease.form.with.request.unresolve.fail';

export const EMAIL_LEASE_PDF_REQUEST = 'dashboard.lease.pdf.send';
export const EMAIL_LEASE_PDF_SUCCESS = 'dashboard.lease.pdf.success';
export const RESET_EMAIL_LEASE_PDF = 'RESET_EMAIL_LEASE_PDF';
export const EMAIL_LEASE_PDF_FAIL = 'dashboard.lease.pdf.send.fail';

export const EMAIL_RENT_INCREASE_PDF_REQUEST = 'dashboard.rentIncrease.pdf.send';
export const RESET_EMAIL_RENT_INCREASE_PDF = 'RESET_EMAIL_RENT_INCREASE_PDF';
export const EMAIL_RENT_INCREASE_PDF_SUCCESS = 'dashboard.rentIncrease.pdf.success';
export const EMAIL_RENT_INCREASE_PDF_FAIL = 'dashboard.rentIncrease.pdf.send.fail';

export const EMAIL_LEASE_MANUAL_SIGNING_REQUEST = 'dashboard.lease.manual.signing.send';
export const EMAIL_LEASE_MANUAL_SIGNING_SUCCESS = 'dashboard.lease.manual.signing.success';
export const RESET_LEASE_MANUAL_SIGNING = 'RESET_LEASE_MANUAL_SIGNING';
export const EMAIL_LEASE_MANUAL_SIGNING_FAIL = 'dashboard.lease.manual.signing.fail';

export const MOVE_TO_ARCHIVE_REQUEST = 'dashboard.lease.move.to.archive.send';
export const MOVE_TO_ARCHIVE_SUCCESS = 'dashboard.lease.move.to.archive.success';
export const MOVE_TO_ARCHIVE_FAIL = 'dashboard.lease.move.to.archive.fail';

export const MOVE_TO_AWAITING_RENEWAL_REQUEST = 'dashboard.lease.move.to.awaiting.renewal.send';
export const MOVE_TO_AWAITING_RENEWAL_SUCCESS = 'dashboard.lease.move.to.awaiting.renewal.success';
export const MOVE_TO_AWAITING_RENEWAL_FAIL = 'dashboard.lease.move.to.awaiting.renewal.fail';

export const ASK_FOR_RENEWAL_INTENTION_REQUEST = 'dashboard.lease.ask.for.renewal.intention.send';
export const ASK_FOR_RENEWAL_INTENTION_SUCCESS = 'dashboard.lease.ask.for.renewal.intention.success';
export const ASK_FOR_RENEWAL_INTENTION_FAIL = 'dashboard.lease.ask.for.renewal.intention.fail';

export const EMAIL_OWNER_COPY_AGENT_REQUEST = 'EMAIL_OWNER_COPY_AGENT_REQUEST';
export const EMAIL_OWNER_COPY_AGENT_SUCCESS = 'EMAIL_OWNER_COPY_AGENT_SUCCESS';
export const RESET_EMAIL_OWNER_COPY_AGENT = 'RESET_EMAIL_OWNER_COPY_AGENT';
export const EMAIL_OWNER_COPY_AGENT_FAIL = 'EMAIL_OWNER_COPY_AGENT_FAIL';

export const EMAIL_COMPLETED_OWNER_COPY_AGENT_REQUEST = 'EMAIL_COMPLETED_OWNER_COPY_AGENT_REQUEST';
export const EMAIL_COMPLETED_OWNER_COPY_AGENT_SUCCESS = 'EMAIL_COMPLETED_OWNER_COPY_AGENT_SUCCESS';
export const RESET_EMAIL_COMPLETED_OWNER_COPY_AGENT = 'RESET_EMAIL_COMPLETED_OWNER_COPY_AGENT';
export const EMAIL_COMPLETED_OWNER_COPY_AGENT_FAIL = 'EMAIL_COMPLETED_OWNER_COPY_AGENT_FAIL';

export const LEASE_DISCLOSURE_REBATE_REQUEST = 'dashboard.lease.disclosure.rebate.send';
export const LEASE_DISCLOSURE_REBATE_SUCCESS = 'dashboard.lease.disclosure.rebate.success';
export const LEASE_DISCLOSURE_REBATE_FAIL = 'dashboard.lease.disclosure.rebate.fail';

export const ADDITIONAL_SALES_TERMS_REQUEST = 'ADDITIONAL_SALES_REQUEST';
export const ADDITIONAL_SALES_TERMS_SUCCESS = 'ADDITIONAL_SALES_SUCCESS';

export const LEASE_PM_AGREEMENT_TERM_REQUEST = 'dashboard.lease.set.pm.agreementTerm.request';
export const LEASE_PM_AGREEMENT_TERM_SUCCESS = 'dashboard.lease.set.pm.agreementTerm.success';

export const UPDATE_INTEGRATION_SUCCESS = 'dashboard.lease.update.integration.success';

export const FINISH_LEASE_EDITING = 'FINISH_LEASE_EDITING';

export const UPDATE_LEASE_INFO = 'UPDATE_LEASE_INFO';

export const LEASE_SECTION_UPDATE_SUCCESS = 'LEASE_SECTION_UPDATE_SUCCESS';

export const LEASE_PULL_INTEGRATION_DATA = statefulActions('dashboard.lease.integration.pullLeaseData');

export const REMOVE_LEASE_CONDITION_REPORT = statefulActions('dashboard.lease.conditionReport.remove');

export const PULL_LEASE_CONDITION_REPORT = statefulActions('dashboard.lease.conditionReport.pull');

export const ENABLE_LEASE_DATA_SYNC = 'ENABLE_LEASE_DATA_SYNC';
export const UPDATE_LEASE_DATA_SYNC = 'UPDATE_LEASE_DATA_SYNC';
export const CLEAN_LEASE_DATA_SYNC = 'CLEAN_LEASE_DATA_SYNC';

//Modes
const FINISH_EDIT_COMPLETE = 'FINISH_EDIT_COMPLETE';
const MOVE_TO_NEXT_STEP = 'MOVE_TO_NEXT_STEP';

export const REMOVE_SUGGESTION = 'REMOVE_SUGGESTION';
export const UPDATE_SUGGESTION = 'UPDATE_SUGGESTION';

export default combineReducers({
    common: leaseReducer,
    landlord: landlordReducer,
    tenant: tenantReducer,
    tenantsAgent: tenantAgentReducer,
    term: termReducer,
    rent: rentReducer,
    inclusions: inclusionsReducer,
    exclusions: exclusionsReducer,
    maxOccupants: occupantsReducer,
    emergencyContacts: contactReducer,
    additionalTerms: additionalReducer,
    documents: documentsReducer,
    connections: connectionsReducer,
    agentAuthority: agentAuthorityReducer,
    fees: feesReducer,
    materialFacts: materialFactsReducer,
    expensesAndCharges: expensesAndChargesReducer,
    otherServices: otherServicesReducer,
    advertising: advertisingReducer,
    leaseRentAndTerm: leaseRentAndTermReducer,
    outgoings: outgoingsReducer,
    insurance: insuranceReducer,
    pets: petsReducer,
    repairsAndMaintenance: repairsAndMaintenanceReducer,
    nominatedRepairers: nominatedRepairersReducer,
    disclosure: disclosureReducer,
    instructions: instructionsReducer,
    executedAsAgreement: executedAsAgreementReducer,

    /** Sales **/
    signatory: signatoryReducer,
    landlordsSolicitor: landlordsSolicitorReducer,
    salesLandlord: salesLandlordReducer,
    salesInclusions: salesInclusionsReducer,
    priceAndFee: priceAndFeeReducer,
    salesTerm: salesTermReducer,
    additionalSalesTerms: additionalSalesTermsReducer,
    salesExpensesAndCharges: salesExpensesAndChargesReducer,
    managingAgent: managingAgentReducer,
    propertyDetails: propertyDetailsReducer,
    propertyDescription: propertyDescriptionReducer,
    agreementTerm: agreementTermReducer,

    // common lease data dor draft leases
    leaseData: leaseDataReducer
});

const initState = {
    editMode: null,
    agency: {},
    signatory: null,
    agent: {},
    leaseType: null,
    subLeaseType: null,
    documentType: null,
    id: '',
    address: '** ADDRESS ERROR **', // This has shown for some lease agreements, changing it to be clear so it gets picked up
    location: 'NSW',
    addresses: [],
    step: 1,
    dirtyStep: null,
    validationErrors: null,
    leaseAllInfo: {},
    isLeaseLoading: false,
    listLink: [],
    isOpenLeaseAgreementForm: false,
    isOpenAgreementTemplateForm: false,
    isEditAgreementTemplateForm: false,
    isOpenCloneResTen: false,
    isEditLeaseAgreementForm: false,
    isOpenRequestsToChangeForm: false,
    isSubmitButtonDisabled: false,
    requests: [],
    activeRequest: {
        leaseId: '',
        step: 2
    },
    isEditingTemplateFinish: false,
    isLeaseEditingFinished: false,
    isSubmittingLease: false,
    isSendingLeasePdfEmail: false,
    leasePdfEmailSentSuccess: false,
    leasePdfEmailSentFail: false,
    isSendingLeaseManualSigningEmail: false,
    isSendingTerminateLease: false,
    isSendingTerminateLeaseSuccess: false,
    isSendingTerminateLeaseFail: false,
    isSendingLeaseTerminationPdfEmail: false,
    isSendingLeaseTerminationPdfEmailSuccess: false,
    isSendingLeaseTerminationPdfEmailFail: false,
    leaseManualSigningEmailSentSuccess: false,
    leaseManualSigningEmailSentFail: false,
    isSendingOwnerCopyEmail: false,
    sendingOwnerCopyEmailSentSuccess: false,
    sendingOwnerCopyEmailSentFail: false,
    sendingCompletedOwnerCopyEmailSentSuccess: false,
    sendingCompletedOwnerCopyEmailSentFail: false,
    isSendingRentIncreasePdfEmail: false,
    rentIncreasePdfEmailSentSuccess: false,
    rentIncreasePdfEmailSentFail: false,
    isRemovingOccupant: false,
    isRemovingOccupantSuccess: false,
    isRemovingOccupantFail: false,
    movedToArchive: false,
    movedToArchiveSuccess: false,
    movedToArchiveFail: false,
    movedToAwaitingRenewal: false,
    movedToAwaitingRenewalSuccess: false,
    movedToAwaitingRenewalFail: false,
    isPostSigningFail: false,
    postSigningFailMessage: null,
    savingStep: false,
    documentUploadProgress: 0,
    cloneTemplate: null,
    integrationDataSync: {
        enabled: false,
        fields: []
    }
};

/**
 * All errors are formatted on the server
 *
 * @param {object} serverErrors - errors from server
 * @return {object}
 */
export const processServerErrors = function (serverErrors) {
    return serverErrors;
};

function leaseReducer(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case GET_ADDRESSES_REQUEST: {
            newState.getAddressesRequest = true;
            newState.getAddressesSuccess = false;
            break;
        }
        case GET_ADDRESSES_SUCCESS: {
            newState.addresses = action.result.data.addressList;
            newState.getAddressesRequest = false;
            newState.getAddressesSuccess = true;
            break;
        }
        case GET_ADDRESSES_FAIL: {
            break;
        }
        case SET_LEASE_AGREEMENT_TYPE: {
            newState.leaseType = action.payload.leaseType;
            break;
        }

        case SET_DOCUMENT_TYPE: {
            newState.documentType = action.payload.docType;
            break;
        }

        case SEND_REMOVE_OCCUPANT_REQUEST: {
            newState.isRemovingOccupant = true;
            newState.isRemovingOccupantSuccess = false;
            newState.isRemovingOccupantFail = false;
            break;
        }
        case SEND_REMOVE_OCCUPANT_SUCCESS: {
            newState.isRemovingOccupant = false;
            newState.isRemovingOccupantSuccess = true;
            newState.isRemovingOccupantFail = false;
            break;
        }
        case SEND_REMOVE_OCCUPANT_FAIL: {
            newState.isRemovingOccupant = false;
            newState.isRemovingOccupantSuccess = false;
            newState.isRemovingOccupantFail = true;
            break;
        }
        case OPEN_LEASE_AGREEMENT_FORM: {
            newState.isOpenLeaseAgreementForm = true;
            newState.isEditLeaseAgreementForm = false;
            newState.step = 1;
            newState.id = '';
            newState.validationErrors = null;
            newState.leaseType = action.leaseType;
            newState.createNewModalOpenedFromWelcome = action.openedFromWelcome;
            break;
        }
        case CLOSE_LEASE_AGREEMENT_FORM: {
            newState = {
                ...initState,
                requests: state.requests,
                leaseType: state.leaseType
            };
            break;
        }
        case OPEN_CLONE_RES_TEN_FORM: {
            newState = {
                ...initState,
                isOpenCloneResTen: true,
                isOpenLeaseAgreementForm: false,
                leaseType: action.leaseType,
                cloneLeaseId: action.leaseId
            };
            break;
        }
        case CLOSE_CLONE_RES_TEN_FORM: {
            newState = {
                ...initState,
                isOpenCloneResTen: false
            };
            break;
        }

        case CLONE_AGREEMENT_EXTRA_REQUEST: {
            newState.isOpenCloneResTen = true;
            break;
        }
        case CLONE_AGREEMENT_EXTRA_SUCCESS: {
            newState.isOpenCloneResTen = false;
            break;
        }
        case CLONE_AGREEMENT_EXTRA_FAILURE: {
            break;
        }
        case EDIT_LEASE_AGREEMENT_FORM: {
            newState.isOpenAgreementTemplateForm = isTemplate(action.payload.agreementInfo.leaseType);
            newState.location = action.payload.agreementInfo.location;
            newState.leaseAllInfo = action.payload.agreementInfo;
            newState.leaseType = action.payload.agreementInfo.leaseType;
            newState.subLeaseType = action.payload.agreementInfo.subLeaseType;
            newState.isOpenLeaseAgreementForm = !isTemplate(action.payload.agreementInfo.leaseType);
            newState.isEditLeaseAgreementForm =
                action.payload.agreementInfo.progress ===
                    getTotalStepsCount(
                        action.payload.agreementInfo.leaseType,
                        action.payload.agreementInfo.subLeaseType,
                        action.payload.agreementInfo.location
                    ) && !isTemplate(action.payload.agreementInfo.leaseType);
            newState.address = action.payload.agreementInfo.address;
            newState.id = action.payload.agreementInfo.id;
            newState.step = action.payload.step;
            newState.suggestions = action.payload.agreementInfo.suggestions;
            newState.validationErrors = null;
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            newState.address = action.lease.address;
            newState.id = action.lease.id;
            newState.validationErrors = null;
            break;
        }
        case EDIT_LEASE_AGREEMENT_ADDRESS: {
            newState.address = action.address;
            break;
        }
        case EDIT_LEASE_AGREEMENT_AGENT: {
            newState.agent = action.agent;
            break;
        }

        case UPDATE_LEASE_AGREEMENT_ADDRESS: {
            newState.address = action.data;
            break;
        }
        case CREATE_LEASE_REQUEST: {
            newState.suggestions = {};
            break;
        }
        case CREATE_LEASE_SUCCESS: {
            newState.leaseAllInfo = action.result.data.lease;
            newState.suggestions = action.result.data.suggestions || {};
            newState.leaseType = action.result.data.lease.leaseType;
            newState.subLeaseType = action.result.data.lease.subLeaseType;
            newState.address = action.result.data.lease.address;
            newState.location = action.result.data.lease.location;
            newState.id = action.result.data.lease.id;
            newState.agency = action.result.data.lease.agency;
            newState.agent = action.result.data.lease.agent;
            newState.step += 1;
            break;
        }
        case CREATE_LEASE_FAILURE: {
            newState.createLeaseError = action.error;
            break;
        }
        case EDIT_TEMPLATE_SUCCESS: {
            newState.isOpenAgreementTemplateForm = true;
            newState.isEditAgreementTemplateForm = false;
            newState.leaseAllInfo = action.result.data.lease;
            newState.leaseType = action.result.data.lease.leaseType;
            newState.address = action.result.data.lease.address;
            newState.location = action.result.data.lease.location;
            newState.id = action.result.data.lease.id;
            newState.agency = action.result.data.lease.agency;
            newState.agent = action.result.data.lease.agent;
            newState.step += 1;
            break;
        }
        case OPEN_AGREEMENT_TEMPLATE_FORM: {
            newState.isOpenAgreementTemplateForm = true;
            newState.isEditAgreementTemplateForm = false;
            newState.step = 1;
            newState.id = '';
            newState.validationErrors = null;
            newState.leaseType = action.leaseType;
            newState.cloneTemplate = action.cloneTemplate;
            break;
        }
        case CLOSE_AGREEMENT_TEMPLATE_FORM: {
            newState = {
                ...initState,
                requests: state.requests,
                leaseType: state.leaseType
            };
            break;
        }
        case CREATE_SALES_LEASE_SUCCESS:
        case CREATE_COMMERCIAL_LEASE_SUCCESS:
        case CREATE_HOLIDAY_LETTING_SUCCESS:
        case CREATE_PM_LEASE_SUCCESS: {
            newState.leaseAllInfo = action.result.data.lease;
            newState.address = action.result.data.lease.address;
            newState.location = action.result.data.lease.location;
            newState.id = action.result.data.lease.id;
            newState.agency = action.result.data.lease.agency;
            newState.agent = action.result.data.lease.agent;
            newState.step += 1;
            break;
        }

        case CREATE_COMMERCIAL_LEASE_FAILURE:
        case CREATE_HOLIDAY_LETTING_FAILURE:
        case CREATE_PM_LEASE_FAILURE: {
            newState.createLeaseError = action.error;
            break;
        }
        case CLOSE_LEASE_MODAL: {
            newState = initState;
            break;
        }
        case Dashboard.CLOSE_SIGNING_SUCCESS: {
            newState.listLink = [];
            break;
        }
        case FINISH_EDITING_LEASE_AGREEMENT_REQUEST: {
            newState.isSubmittingLease = true;
            newState.isLeaseEditingFinished = false;
            newState.isLeaseEditingError = false;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                documents: null
            });
            break;
        }
        case FINISH_EDITING_LEASE_AGREEMENT_SUCCESS: {
            newState.leaseAllInfo = action.result.data.lease;
            newState.isLeaseEditingFinished = true;
            newState.isSubmittingLease = false;
            newState.isLeaseEditingError = false;
            break;
        }
        case FINISH_EDITING_TEMPLATE_AGREEMENT_REQUEST: {
            newState.isSubmittingLease = true;
            newState.isLeaseEditingError = false;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                documents: null
            });
            break;
        }
        case FINISH_EDITING_TEMPLATE_AGREEMENT_SUCCESS: {
            newState.isEditingTemplateFinish = true;
            newState.leaseAllInfo = action.result.data.lease;
            newState.isLeaseEditingFinished = false;
            newState.isSubmittingLease = false;
            newState.isLeaseEditingError = false;
            break;
        }
        case SET_ACTIVE_REQUEST: {
            newState.activeRequest = action.payload;
            break;
        }
        case CHANGE_REQUEST_TO_CHANGE_SUCCESS: {
            const requests = [...state.requests];

            requests.map((request, index) => {
                if (request.id === action.result.data.requestToChange.id) {
                    requests[index] = { ...action.result.data.requestToChange };
                }
            });

            newState.requests = requests;

            break;
        }
        case LEASE_SIGNATORY_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
                draftState.validationErrors = Object.assign({}, state.validationErrors, {
                    signatory: null
                });
            });
        }
        case LEASE_SECTION_UPDATE_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.leaseAllInfo = action.result.data.lease;
                draftState.isLeaseEditingFinished = action.result.isLeaseEditingFinished;
            });
        }
        case LEASE_LANDLORD_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_TENANT_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
                draftState.validationErrors = Object.assign({}, state.validationErrors, {
                    tenant: null
                });
            });
        }
        case FINISH_EDITING_MODE: {
            return produce(state, draftState => {
                draftState.editMode = FINISH_EDIT_COMPLETE;
            });
        }

        case MOVE_TO_NEXT_STEP_EDITING_MODE: {
            return produce(state, draftState => {
                draftState.editMode = MOVE_TO_NEXT_STEP;
            });
        }

        case SET_SIDE_MENU_STEP: {
            return produce(state, draftState => {
                draftState.goToStep = action.goToStep;
                draftState.savingStep = true;
            });
        }

        case SET_DIRTY_STEP: {
            return produce(state, draftState => {
                draftState.dirtyStep = action.payload.step;
            });
        }
        case RESET_DIRTY_STEP: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
            });
        }
        case SET_NAVIGATION_STATE: {
            return produce(state, draftState => {
                if (state.editMode && state.editMode === FINISH_EDIT_COMPLETE) {
                    draftState.isLeaseEditingFinished = true;
                    draftState.isSubmittingLease = false;
                    draftState.isLeaseEditingError = false;
                    draftState.editMode = null;
                }
                if (!state.isLeaseEditingFinished && state.editMode && state.editMode === MOVE_TO_NEXT_STEP) {
                    draftState.step += 1;
                    draftState.editMode = null;
                }
                if (state.goToStep) {
                    draftState.step = state.goToStep;
                    draftState.goToStep = null;
                }
                draftState.savingStep = false;
            });
        }
        case UPDATE_LANDLORD_REQUEST:
        case UPDATE_TENANT_REQUEST:
        case LEASE_TENANT_REQUEST: {
            newState.isSubmittingLease = true;
            newState.listLink = [];
            break;
        }
        case UPDATE_VENDOR_SUCCESS:
        case UPDATE_LANDLORD_SUCCESS:
        case UPDATE_TENANT_SUCCESS: {
            newState.isSubmittingLease = false;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                tenant: null,
                pmLandlord: null,
                signatory: null
            });
            break;
        }
        case LEASE_TENANTS_AGENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_TERM_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_RENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
                draftState.isLeaseEditingFinished = action.result.isLeaseEditingFinished;
            });
        }
        case LEASE_PAYMENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case PM_LEASE_PAYMENT_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.isSubmittingLease = false;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                paymentMethod: null
            });
            break;
        }
        case LEASE_INCLUSIONS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_EXCLUSIONS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_OCCUPANTS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_CONTACTS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
                if (isLeaseTypePm(action.result.data.lease.leaseType)) {
                    draftState.validationErrors = Object.assign({}, state.validationErrors, {
                        pmEmergencyContacts: null
                    });
                }
            });
        }
        case LEASE_ADDITIONAL_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_CONDITIONS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_SALES_EXPENSES_CHARGES_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_DOCUMENTS_REQUEST: {
            newState.isSubmittingLease = true;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                documents: null
            });
            break;
        }
        case LEASE_DOCUMENTS_SUCCESS: {
            return produce(state, draftState => {
                if (
                    (isLeaseTypeCommercialLease(action.result.data.lease.leaseType) ||
                        (isLeaseTypePm(action.result.data.lease.leaseType) &&
                            [QLD_STATE, VIC_STATE, SA_STATE].includes(action.result.data.lease.location)) ||
                        (isLeaseTypeSales(action.result.data.lease.leaseType) &&
                            action.result.data.lease.location === QLD_STATE)) &&
                    !action.editMode
                ) {
                    draftState.isLeaseEditingFinished = !action.bypassFormValidation;
                }
                if (!action.editMode) {
                    if (draftState.goToStep) {
                        draftState.step = state.goToStep;
                        draftState.goToStep = null;
                        draftState.savingStep = false;
                    } else if (!draftState.isLeaseEditingFinished) {
                        draftState.step += 1;
                    }
                }
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
                draftState.documentUploadProgress = 0;
                draftState.validationErrors = Object.assign({}, state.validationErrors, {
                    documents: null
                });
            });
        }
        case UPDATE_DOCUMENT_UPLOAD_PROGRESS: {
            return produce(state, draftState => {
                draftState.documentUploadProgress = action.progress;
            });
        }
        case UPDATE_TENANT_LINKS_SUCCESS: {
            return produce(state, draftState => {
                draftState.leaseAllInfo.tenantLinks = action.payload.links;
            });
        }
        case LEASE_CONNECTIONS_REQUEST: {
            newState.isSubmittingLease = true;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                connections: null
            });
            break;
        }
        case LEASE_CONNECTIONS_SUCCESS: {
            newState.isLeaseEditingFinished = action.result.isLeaseEditingFinished;
            newState.isSubmittingLease = false;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                connections: null
            });
            break;
        }
        case LEASE_SALES_LANDLORD_REQUEST: {
            newState.isSubmittingLease = true;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                salesLandlord: null
            });
            break;
        }
        case LEASE_SALES_LANDLORD_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                salesLandlord: null
            });
            break;
        }
        case LEASE_LANDLORD_SOLICITOR_SUCCESS:
        case LEASE_SALES_INCLUSIONS_SUCCESS:
        case LEASE_MANAGING_AGENT_SUCCESS:
        case LEASE_PROPERTY_DETAILS_SUCCESS:
        case LEASE_PROPERTY_DESCRIPTION_SUCCESS:
        case LEASE_LICENSEE_SUCCESS:
        case LEASE_AGREEMENT_TERM_SUCCESS:
        case LEASE_PRICE_AND_FEE_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_EXPENSES_AND_CHARGES_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                expensesAndCharges: null
            });
            break;
        }
        case LEASE_PM_EXPENSES_AND_CHARGES_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_SALES_TERM_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_MARKETING_AND_ADVERTISING_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_INSPECTION_DAYS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_AGENT_AUTHORITY_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                agentAuthority: null
            });
            break;
        }
        case ADDITIONAL_SALES_TERMS_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                additionalSalesTerms: null
            });
            break;
        }
        case LEASE_OTHER_SERVICES_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                otherServices: null
            });
            break;
        }
        case LEASE_PM_OTHER_SERVICES_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_PM_DETAILS_REPAIRS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_ADVERTISING_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                advertising: null
            });
            break;
        }
        case LEASE_FEES_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                fees: null
            });
            break;
        }
        case LEASE_MATERIAL_FACTS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_PM_FEES_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                pmFees: null
            });
            break;
        }
        case LEASE_PM_LANDLORD_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_PM_RENT_TERM_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_PM_RENT_PAYMENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_RENT_AND_TERM_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                leaseRentAndTerm: null
            });
            break;
        }
        case LEASE_REPAIRS_SUCCESS:
        case LEASE_PM_OUTGOINGS_SUCCESS:
        case LEASE_OUTGOINGS_SUCCESS:
        case LEASE_PETS_SUCCESS:
        case LEASE_REPAIRS_AND_MAINTENANCE_SUCCESS:
        case LEASE_DISCLOSURE_SUCCESS:
        case LEASE_INSTRUCTIONS_SUCCESS:
        case LEASE_INSURANCE_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_EXECUTED_AS_AGREEMENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.isLeaseEditingFinished = action.result.isLeaseEditingFinished;
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_WARRANTY_AND_MAINTENANCE_SUCCESS: {
            newState.step += 1;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                warrantyAndMaintenance: null
            });
            break;
        }
        case LEASE_TENANT_PAYMENTS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_PM_DUTIES_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_PM_SERVICES_SCHEDULE_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_POOL_SAFETY_AND_SMOKE_ALARMS_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case LEASE_DISCLOSURE_REBATE_SUCCESS: {
            newState.isLeaseEditingFinished = action.result.isLeaseEditingFinished;
            newState.isSubmittingLease = false;
            newState.leaseAllInfo = action.result.data.lease;
            newState.validationErrors = Object.assign({}, state.validationErrors, {
                connections: null
            });
            break;
        }
        case LEASE_PM_AGREEMENT_TERM_SUCCESS: {
            return produce(state, draftState => {
                draftState.dirtyStep = null;
                draftState.isSubmittingLease = false;
                draftState.leaseAllInfo = action.result.data.lease;
            });
        }
        case Contacts.CONTACT_REMOVE_PERSON: {
            newState.validationErrors = Object.assign([], state.validationErrors, { emergencyContacts: null });
            break;
        }
        case SUBMIT_FORM_FAIL: {
            const { leaseType, errors, location } = action.payload;
            newState.isSubmittingLease = false;
            newState.isLeaseEditingFinished = false;
            newState.isLeaseEditingError = true;
            newState.validationErrors = errors;
            const frontEndLeaseType = getLeaseTypeForFrontEnd(leaseType);
            try {
                if (leaseType) {
                    let step = steps[frontEndLeaseType][DEFAULT_SUB_LEASE_TYPE][location].findIndex(
                        step => Object.keys(errors)[0] === step.part
                    );
                    if (step > -1) {
                        newState.step = step + 2;
                    }
                }
            } catch (e) {
                // probably means there is a document error
                if (e.message.includes('Unexpected token')) {
                    let step = steps[frontEndLeaseType][DEFAULT_SUB_LEASE_TYPE][location].findIndex(
                        step => Object.keys(errors)[0] === step.part
                    );
                    if (step > -1) {
                        newState.step = step + 2;
                    }
                } else {
                    throw e;
                }
            }
            break;
        }
        case LEASE_FORM_FAIL: {
            if (action.error && action.error.response) {
                const { status, data } = action.error.response;
                if (status === 400) {
                    newState.validationErrors = processServerErrors(data.errors);
                }
            }

            newState.isSubmittingLease = false;
            newState.isLeaseEditingFinished = false;
            newState.isLeaseEditingError = true;

            // If there is data in the request that was sent, then we can use that to figure out what step has an error
            if (action && action.error && action.error.config && action.error.config.data) {
                let leaseType = newState.leaseType ? mappingsToFrontEnd[newState.leaseType] : null;
                let location = newState.location;
                try {
                    if (!leaseType) {
                        const dataSentInRequest = JSON.parse(action.error.config.data);
                        // We used this to identify the type (use unique field to identify each type)
                        if (Object.prototype.hasOwnProperty.call(dataSentInRequest, PM_LANDLORD)) {
                            leaseType = LEASE_TYPE_PROPERTY_MANAGEMENT;
                        } else if (Object.prototype.hasOwnProperty.call(dataSentInRequest, SALES_SIGNATORY)) {
                            leaseType = LEASE_TYPE_SALES;
                        } else if (Object.prototype.hasOwnProperty.call(dataSentInRequest, LANDLORD)) {
                            leaseType = LEASE_TYPE_RESIDENTIAL;
                            if (Object.prototype.hasOwnProperty.call(dataSentInRequest, 'materialFacts')) {
                                location = NSW_STATE;
                            }
                        }
                    }
                    // If there is a lease type, then find the step and jump to that step
                    if (leaseType) {
                        let errorPart = Object.keys(newState.validationErrors)[0];
                        if (leaseType === LEASE_TYPE_SALES) {
                            switch (errorPart) {
                                case 'inclusions':
                                    errorPart = 'salesInclusions';
                                    break;
                                case 'term':
                                    errorPart = 'salesTerm';
                                    break;
                                case 'expensesAndCharges ':
                                    errorPart = 'salesExpensesAndCharges';
                                    break;
                            }
                        } else if (leaseType === LEASE_TYPE_PROPERTY_MANAGEMENT && errorPart === 'paymentMethod') {
                            errorPart = 'pmPaymentMethod';
                        }
                        let step = steps[leaseType][DEFAULT_SUB_LEASE_TYPE][location].findIndex(
                            step => errorPart === step.part
                        );
                        if (step > -1) {
                            newState.step = step + 2;
                        }
                    }
                } catch (e) {
                    // probably means there is a document error
                    if (action.data) {
                        const dataSentInRequest = action.data;
                        if (Object.prototype.hasOwnProperty.call(dataSentInRequest, PM_LANDLORD)) {
                            leaseType = LEASE_TYPE_PROPERTY_MANAGEMENT;
                        } else if (Object.prototype.hasOwnProperty.call(dataSentInRequest, SALES_SIGNATORY)) {
                            leaseType = LEASE_TYPE_SALES;
                        } else if (Object.prototype.hasOwnProperty.call(dataSentInRequest, LANDLORD)) {
                            leaseType = LEASE_TYPE_RESIDENTIAL;
                            if (Object.prototype.hasOwnProperty.call(dataSentInRequest, 'materialFacts')) {
                                location = NSW_STATE;
                            }
                        }
                    } else if (action.additionalData) {
                        leaseType = action.additionalData.leaseType;
                        location = action.additionalData.location;
                    }
                    if (e.message.includes('Unexpected token')) {
                        let step = steps[getLeaseTypeForFrontEnd(leaseType)][DEFAULT_SUB_LEASE_TYPE][
                            location
                        ].findIndex(step => Object.keys(newState.validationErrors)[0] === step.part);
                        if (step > -1) {
                            newState.step = step + 2;
                        }
                    } else {
                        throw e;
                    }
                }
            }
            break;
        }
        case GET_INFO_LEASE_REQUEST: {
            return {
                ...state,
                leaseAllInfo: {},
                isLeaseLoading: true
            };
        }
        /*
         * Boolean values that are displayed in radio buttons with values of yes/no
         * need to be converted from true/false to yes/no
         * */
        case GET_INFO_LEASE_SUCCESS: {
            const leaseAllInfoRaw = action.result.data.lease;
            if (leaseAllInfoRaw.pmDetailsRepairs) {
                leaseAllInfoRaw.pmDetailsRepairs.smokeAlarms = booleanToString(
                    leaseAllInfoRaw.pmDetailsRepairs.smokeAlarms
                );
            }
            return {
                ...state,
                leaseAllInfo: leaseAllInfoRaw,
                location: action.result.data.lease.location,
                agency: action.result.data.lease.agency,
                agent: action.result.data.lease.agent,
                leaseType: action.result.data.lease.leaseType,
                subLeaseType: action.result.data.lease.subLeaseType,
                isLeaseLoading: false,
                suggestions: action.result.data.lease.suggestions
            };
        }
        case GET_INFO_LEASE_FAIL: {
            return {
                ...state,
                leaseAllInfo: {},
                isLeaseLoading: false
            };
        }
        case CHANGE_STEP_SIGNAL: {
            return {
                ...state,
                step: action.data,
                isSubmitButtonDisabled: false
            };
        }
        case GO_BACK_SIGNAL: {
            if (newState.step > 2) {
                newState.step = newState.step - 1;
            }
            break;
        }
        case Dashboard.OPEN_AGREEMENT_INFO_MODAL: {
            newState.listLink = [];
            newState.isOpenLeaseAgreementForm = false;
            newState.isEditLeaseAgreementForm = false;
            break;
        }
        case CLOSE_AGREEMENT_INFO_MODAL: {
            newState.postSigningFailMessage = null;
            newState.isPostSigningFail = false;
            newState.leaseAllInfo = {};
            newState.suggestions = {};
            newState.integrationDataSync = {
                enabled: false,
                fields: []
            };
            break;
        }
        case POST_SIGNING_REQUEST: {
            newState.listLink = [];
            newState.postSigningFailMessage = null;
            newState.isPostSigningFail = false;
            break;
        }
        case POST_SIGNING_SUCCESS: {
            return produce(state, draftState => {
                draftState.listLink = action.result.data.links;
                draftState.postSigningFailMessage = null;
                draftState.isPostSigningFail = false;
            });
        }
        case RESEND_POST_SIGNING_REQUEST: {
            newState.listLink = [];
            break;
        }
        case RESEND_POST_SIGNING_SUCCESS: {
            newState.listLink = action.result.data.links;
            break;
        }
        case RESEND_POST_SIGNING_FAIL: {
            newState.listLink = [];
            break;
        }
        case POST_SIGNING_FAIL: {
            newState.listLink = [];
            newState.postSigningFailMessage = action?.error?.message?.agentMessage?.message;
            newState.isPostSigningFail = true;
            break;
        }
        case LEASE_FOOTER_DISABLE_SUBMIT: {
            return {
                ...state,
                isSubmitButtonDisabled: true
            };
        }
        case LEASE_FOOTER_ENABLE_SUBMIT: {
            return {
                ...state,
                isSubmitButtonDisabled: false
            };
        }
        case LEASE_CLEAR_VALIDATION_ERRORS: {
            if (state.validationErrors) {
                return produce(state, draftState => {
                    draftState.validationErrors = null;
                    draftState.formPart = null;
                });
            }

            return newState;
        }
        case REQUEST_RESOLVE_SUCCESS:
        case REQUEST_UNRESOLVE_SUCCESS: {
            return {
                ...state,
                leaseAllInfo: action.result.data.lease
            };
        }
        case EMAIL_LEASE_PDF_REQUEST: {
            return {
                ...state,
                isSendingLeasePdfEmail: true,
                leasePdfEmailSentFail: false
            };
        }
        case EMAIL_LEASE_PDF_SUCCESS: {
            return {
                ...state,
                isSendingLeasePdfEmail: false,
                leasePdfEmailSentSuccess: true,
                leasePdfEmailSentFail: false
            };
        }
        case RESET_EMAIL_LEASE_PDF: {
            return {
                ...state,
                isSendingLeasePdfEmail: false,
                leasePdfEmailSentSuccess: false,
                leasePdfEmailSentFail: false
            };
        }
        case EMAIL_LEASE_PDF_FAIL: {
            return {
                ...state,
                isSendingLeasePdfEmail: false,
                leasePdfEmailSentSuccess: false,
                leasePdfEmailSentFail: true
            };
        }

        case EMAIL_LEASE_MANUAL_SIGNING_REQUEST: {
            return {
                ...state,
                isSendingLeaseManualSigningEmail: true,
                leaseManualSigningEmailSentSuccess: false,
                leaseManualSigningEmailSentFail: false
            };
        }
        case EMAIL_LEASE_MANUAL_SIGNING_SUCCESS: {
            return {
                ...state,
                isSendingLeaseManualSigningEmail: false,
                leaseManualSigningEmailSentSuccess: true,
                leaseManualSigningEmailSentFail: false
            };
        }
        case EMAIL_LEASE_MANUAL_SIGNING_FAIL: {
            return {
                ...state,
                isSendingLeaseManualSigningEmail: false,
                leaseManualSigningEmailSentSuccess: false,
                leaseManualSigningEmailSentFail: true
            };
        }

        case RESET_LEASE_MANUAL_SIGNING: {
            return {
                ...state,
                isSendingLeaseManualSigningEmail: false,
                leaseManualSigningEmailSentSuccess: false,
                leaseManualSigningEmailSentFail: false
            };
        }

        case SEND_TERMINATE_LEASE_REQUEST: {
            return {
                ...state,
                isSendingTerminateLease: true,
                isSendingTerminateLeaseSuccess: false,
                isSendingTerminateLeaseFail: false
            };
        }
        case SEND_TERMINATE_LEASE_SUCCESS: {
            return {
                ...state,
                isSendingTerminateLease: false,
                isSendingTerminateLeaseSuccess: true,
                isSendingTerminateLeaseFail: false
            };
        }
        case SEND_TERMINATE_LEASE_FAIL: {
            return {
                ...state,
                isSendingTerminateLease: false,
                isSendingTerminateLeaseSuccess: false,
                isSendingTerminateLeaseFail: true
            };
        }
        case OPEN_TERMINATE_LEASE_MODAL: {
            return {
                ...state,
                isSendingTerminateLeaseSuccess: false
            };
        }
        case CLOSE_TERMINATE_LEASE_MODAL: {
            return {
                ...state,
                isSendingTerminateLeaseSuccess: false,
                isSendingTerminateLeaseFail: false
            };
        }
        case UPDATE_TERMINATE_NOTICE_SENDER_EMAIL_ADDRESSES: {
            return {
                ...state,
                emailAddress: action.payload.emails
            };
        }
        case SET_EMAIL_LEASE_TERMINATION_PDF_SUCCESS: {
            return {
                ...state,
                isSendingLeaseTerminationPdfEmailSuccess: action.payload.value
            };
        }
        case EMAIL_OWNER_COPY_AGENT_REQUEST: {
            return {
                ...state,
                sendingOwnerCopyEmailSentSuccess: false,
                sendingOwnerCopyEmailSentFail: false,
                isSendingOwnerCopyEmail: true
            };
        }
        case EMAIL_OWNER_COPY_AGENT_SUCCESS: {
            return {
                ...state,
                isSendingOwnerCopyEmail: false,
                sendingOwnerCopyEmailSentSuccess: true,
                sendingOwnerCopyEmailSentFail: false
            };
        }
        case EMAIL_OWNER_COPY_AGENT_FAIL: {
            return {
                ...state,
                isSendingOwnerCopyEmail: false,
                sendingOwnerCopyEmailSentSuccess: false,
                sendingOwnerCopyEmailSentFail: true
            };
        }
        case RESET_EMAIL_OWNER_COPY_AGENT: {
            return {
                ...state,
                isSendingOwnerCopyEmail: false,
                sendingOwnerCopyEmailSentSuccess: false,
                sendingOwnerCopyEmailSentFail: false
            };
        }
        case EMAIL_COMPLETED_OWNER_COPY_AGENT_REQUEST: {
            return {
                ...state,
                sendingCompletedOwnerCopyEmailSentSuccess: false,
                sendingCompletedOwnerCopyEmailSentFail: false,
                isSendingCompletedOwnerCopyEmail: true
            };
        }
        case EMAIL_COMPLETED_OWNER_COPY_AGENT_SUCCESS: {
            return {
                ...state,
                isSendingCompletedOwnerCopyEmail: false,
                sendingCompletedOwnerCopyEmailSentSuccess: true,
                sendingCompletedOwnerCopyEmailSentFail: false
            };
        }
        case EMAIL_COMPLETED_OWNER_COPY_AGENT_FAIL: {
            return {
                ...state,
                isSendingCompletedOwnerCopyEmail: false,
                sendingCompletedOwnerCopyEmailSentSuccess: false,
                sendingCompletedOwnerCopyEmailSentFail: true
            };
        }
        case RESET_EMAIL_COMPLETED_OWNER_COPY_AGENT: {
            return {
                ...state,
                isSendingCompletedOwnerCopyEmail: false,
                sendingCompletedOwnerCopyEmailSentSuccess: false,
                sendingCompletedOwnerCopyEmailSentFail: false
            };
        }
        case EMAIL_RENT_INCREASE_PDF_REQUEST: {
            return {
                ...state,
                isSendingRentIncreasePdfEmail: true,
                rentIncreasePdfEmailSentSuccess: false,
                rentIncreasePdfEmailSentFail: false
            };
        }
        case EMAIL_RENT_INCREASE_PDF_SUCCESS: {
            return {
                ...state,
                isSendingRentIncreasePdfEmail: false,
                rentIncreasePdfEmailSentSuccess: true,
                rentIncreasePdfEmailSentFail: false
            };
        }
        case EMAIL_RENT_INCREASE_PDF_FAIL: {
            return {
                ...state,
                isSendingRentIncreasePdfEmail: false,
                rentIncreasePdfEmailSentSuccess: false,
                rentIncreasePdfEmailSentFail: true
            };
        }
        case RESET_EMAIL_RENT_INCREASE_PDF: {
            return {
                ...state,
                isSendingRentIncreasePdfEmail: false,
                rentIncreasePdfEmailSentSuccess: false,
                rentIncreasePdfEmailSentFail: false
            };
        }

        case UPDATE_ADDRESS_REQUEST_SUCCESS: {
            return {
                ...state,
                leaseAllInfo: action.result.data.lease,
                address: action.result.data.lease.address
            };
        }
        case UPDATE_INTEGRATION_SUCCESS: {
            const leaseAllInfo = newState.leaseAllInfo;

            return {
                ...state,
                leaseAllInfo: {
                    ...leaseAllInfo,
                    integration: action.data
                },
                suggestions: null,
            };
        }
        case MOVE_TO_ARCHIVE_REQUEST: {
            return {
                ...state,
                movedToArchive: true,
                movedToArchiveSuccess: false,
                movedToArchiveFail: false
            };
        }
        case MOVE_TO_ARCHIVE_SUCCESS: {
            return {
                ...state,
                leaseAllInfo: action.result.data.lease,
                movedToArchive: false,
                movedToArchiveSuccess: true,
                movedToArchiveFail: false
            };
        }
        case MOVE_TO_ARCHIVE_FAIL: {
            return {
                ...state,
                movedToArchive: false,
                movedToArchiveSuccess: false,
                movedToArchiveFail: true
            };
        }
        case MOVE_TO_AWAITING_RENEWAL_REQUEST: {
            return {
                ...state,
                movedToAwaitingRenewal: true,
                movedToAwaitingRenewalSuccess: false,
                movedToAwaitingRenewalFail: false
            };
        }
        case MOVE_TO_AWAITING_RENEWAL_SUCCESS: {
            return {
                ...state,
                leaseAllInfo: action.result.data.lease,
                movedToAwaitingRenewal: false,
                movedToAwaitingRenewalSuccess: true,
                movedToAwaitingRenewalFail: false
            };
        }
        case MOVE_TO_AWAITING_RENEWAL_FAIL: {
            return {
                ...state,
                movedToAwaitingRenewal: false,
                movedToAwaitingRenewalSuccess: false,
                movedToAwaitingRenewalFail: true
            };
        }

        case SOCKET_UPDATE_AGREEMENT: {
            const newData = action.data;

            return produce(state, draftState => {
                const lease = cloneDeep(state.leaseAllInfo);
                if (lease && lease.id === newData.id) {
                    draftState.leaseAllInfo = merge(lease, newData);
                }
            });
        }

        case FINISH_COMPLETION_PROCESS_SUCCESS: {
            return produce(state, draftState => {
                if (!action?.result?.data?.lease || action?.result?.data.lease?.id !== state?.leaseAllInfo?.id) {
                    return;
                }
                const lease = cloneDeep(state.leaseAllInfo);
                if (lease && lease.id === action.result.data.lease.id) {
                    draftState.leaseAllInfo = merge(lease, action.result.data.lease);
                }
            });
        }
        case FINISH_LEASE_EDITING: {
            return produce(state, draftState => {
                draftState.isLeaseEditingFinished = false;
            });
        }
        case Renewal.START_RENEWAL_SUCCESS: {
            const response = action.result.data;
            return produce(state, draftState => {
                // For sub lease type renewals we open agreement info model.
                // That should be in edit view
                if (response.lease && response.lease.subLeaseType !== DEFAULT_SUB_LEASE_TYPE) {
                    draftState.isOpenLeaseAgreementForm = true;
                    draftState.isLeaseEditingFinished = false;
                    draftState.leaseAllInfo = response.lease;
                    draftState.leaseType = response.lease.leaseType;
                    draftState.id = response.lease.id;
                    draftState.subLeaseType = response.lease.subLeaseType;
                    draftState.step = 2;
                }
            });
        }
        case UPDATE_LEASE_INFO: {
            return produce(state, draftState => {
                draftState.leaseAllInfo = action.payload.lease;
            });
        }
        case REMOVE_SUGGESTION: {
            const { suggestionKey, index } = action;

            return produce(state, draftState => {
                // Use lodash.get to safely retrieve the suggestion based on the key
                const suggestions = get(draftState.suggestions, suggestionKey);

                // Check if the retrieved suggestions is an array
                if (isArray(suggestions)) {
                    // If there is only one suggestion in the array, remove the suggestionKey
                    if (suggestions.length === 1) {
                        set(draftState.suggestions, suggestionKey, undefined);
                    } else {
                        // If there are multiple suggestions in the array
                        // Create an updated array without the suggestion at the specified index
                        const updatedArray = suggestions.filter((_, i) => i !== index);

                        set(draftState.suggestions, suggestionKey, updatedArray);
                    }
                } else {
                    // If the retrieved suggestions is not an array, set it to undefined
                    set(draftState.suggestions, suggestionKey, undefined);
                }
            });
        }
        case UPDATE_SUGGESTION: {
            return produce(state, draftState => {
                draftState.suggestions = action.payload.suggestions;
            });
        }

        case REMOVE_LEASE_CONDITION_REPORT.REQUEST: {
            return produce(state, draftState => {});
        }

        case REMOVE_LEASE_CONDITION_REPORT.SUCCESS: {
            return produce(state, draftState => {
                draftState.leaseAllInfo.conditionReport = null;
            });
        }
        case REMOVE_LEASE_CONDITION_REPORT.FAIL: {
            return produce(state, draftState => {});
        }

        case PULL_LEASE_CONDITION_REPORT.REQUEST: {
            return produce(state, draftState => {
                draftState.leaseAllInfo.errorPullingConditionReport = false;
                draftState.leaseAllInfo.isPullingConditionReport = true;
            });
        }
        case PULL_LEASE_CONDITION_REPORT.SUCCESS: {
            return produce(state, draftState => {
                draftState.leaseAllInfo.isPullingConditionReport = false;
                draftState.leaseAllInfo.conditionReport = action?.result?.data?.conditionReport;
            });
        }
        case PULL_LEASE_CONDITION_REPORT.FAIL: {
            return produce(state, draftState => {
                draftState.leaseAllInfo.errorPullingConditionReport = true;
                draftState.leaseAllInfo.isPullingConditionReport = false;
            });
        }

        case UPDATE_LEASE_DATA_SYNC: {
            return produce(state, draftState => {
                const { field, value } = action.payload;

                const fields = draftState.integrationDataSync.fields;

                // Find the index of the item with the same field
                const index = fields.findIndex(item => item.field === field);

                if (index !== -1) {
                    // If the field already exists, update its value
                    fields[index].selectedValue = value;
                } else {
                    // If the field does not exist, add a new item
                    fields.push({ field, value });
                }
            });
        }

        case ENABLE_LEASE_DATA_SYNC: {
            return produce(state, draftState => {
                draftState.integrationDataSync = {
                    enabled: true,
                    fields: action.payload
                };
            });
        }

        case CLEAN_LEASE_DATA_SYNC: {
            return produce(state, draftState => {
                draftState.integrationDataSync = {
                    enabled: false,
                    fields: []
                };
            });
        }
    }

    return newState;
}
