import React, { createContext, useContext, useEffect, useState } from 'react';
import cookie from 'react-cookie';
import { useSelector } from 'react-redux';
import { getIsUserLoggedIn } from '../../selectors/login';

const AccountContext = createContext(null);

export function AccountProvider({ children }) {
    const isUserLoggedin = useSelector(getIsUserLoggedIn);
    const [accountType, setAccountType] = useState(cookie.load('accountType'));

    useEffect(() => {
        if (accountType && isUserLoggedin) {
            setAccountType(cookie.load('accountType'));
        }
    }, [accountType, isUserLoggedin]);

    return <AccountContext.Provider value={{ accountType, setAccountType }}>{children}</AccountContext.Provider>;
}

export function useAccountProvider() {
    const { accountType, setAccountType } = useContext(AccountContext);

    useEffect(() => {
        const accountTypeCookie = cookie.load('accountType');
        if (accountTypeCookie) {
            setAccountType(accountTypeCookie);
        }
    }, [accountType]);

    return accountType;
}
