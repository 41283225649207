import React from 'react';
import ReactTooltip from 'react-tooltip';
import '../../sass/form-switch.scss';

function Switch({ onClick, value, toolTip, disabled, isError, dataTest }) {
    return (
        <React.Fragment>
            {toolTip && (
                <ReactTooltip id="switch" class="tooltip" place="top" effect="solid" globalEventOff="wheel">
                    <p>{toolTip}</p>
                </ReactTooltip>
            )}
            <div
                className={`switch ${value ? 'active' : ''} ${disabled ? 'disabled' : ''} ${isError ? 'error' : ''}`}
                onClick={onClick}
                data-tip={true}
                data-for="switch"
                data-test={dataTest}
            >
                <div className="slider" />
            </div>
        </React.Fragment>
    );
}
export default Switch;
