import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Form } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import Button from '../../common/components/Button';
import { FormTextRegular } from '../form/FormText';
import { FormTextMultiline } from '../form/FormText';
import ModalDialog from '../../common/components/ModalDialog';
import { addSpecialCondition, updateSpecialCondition } from '../../actions/specialConditions';
import {
    DOCUMENT_RENT_REDUCTION,
    LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
    LEASE_TYPE_RESIDENTIAL_DISPLAY,
    LEASE_TYPE_SALES_DISPLAY,
    LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY,
    LEASE_TYPE_HOLIDAY_LETTING_DISPLAY,
    OPTION_TYPE_AGREE_ONLY,
    OPTION_TYPE_YES_OR_NO
} from '../../config';
import { FormRadioGroupButton } from '../form/FormRadioGroupButton';
import { getOptionDefaultType } from '../../utils/agreementUtils';

import HouseIcon from '@material-ui/icons/HouseSharp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnSharp';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterSharp';
import TrendingDownIcon from '@material-ui/icons/TrendingDownSharp';
import CommercialLeaseIcon from '@material-ui/icons/BusinessSharp';
import yesNoIcon from '@material-ui/icons/ThumbsUpDownSharp';
import DoneIcon from '@material-ui/icons/DoneSharp';
import { Icons, getIcon } from '../../common/components/Icon';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { NSW_HOLIDAY_LETTING } from '../../constants/featureFlags';

import '../../sass/conditions.scss';
import styles from './ModalSpecialCondition.module.scss';

function ModalDialogLeftButton({ specialCondition, type, deleteTemplate }) {
    function deleteSpecialConditionsTemplate() {
        confirmAlert({
            title: '',
            message: `Are you sure want to delete ${specialCondition.name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteTemplate(specialCondition);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    if (type === 'Edit') {
        return (
            <Button error onClick={deleteSpecialConditionsTemplate}>
                <i className="icon-remove-signature" />
                Remove
            </Button>
        );
    }
    return <></>;
}

function ModalDialogRightButton({ type, formRef }) {
    function submitHandler() {
        formRef.current.dispatchEvent(new Event('submit'));
    }
    return (
        <Button className="btn-save-signature" height={40} primary onClick={submitHandler}>
            {type === 'Create' && 'Create template'}
            {type === 'Edit' && 'Save changes'}
            {type === 'Edit' && <i className="icon-save-signature" />}
        </Button>
    );
}

export default function ModalSpecialCondition({
    isOpen,
    closeModal,
    type,
    deleteTemplate,
    specialCondition: propsSpecialCondition
}) {
    const dispatch = useDispatch();
    const formRef = useRef(null);

    const isNswHolidayLettingActive = useFeatureFlag(NSW_HOLIDAY_LETTING);

    let [specialCondition, setSpecialCondition] = useState(propsSpecialCondition);
    let [errors, setErrors] = useState({});

    const shouldDisplayOption = useCallback(agreementType => {
        return ![DOCUMENT_RENT_REDUCTION, LEASE_TYPE_RESIDENTIAL_DISPLAY, LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY].includes(
            agreementType
        );
    }, []);

    useEffect(() => {
        if (specialCondition && propsSpecialCondition !== specialCondition) {
            setSpecialCondition(cloneDeep(propsSpecialCondition));
        } else if (!specialCondition && propsSpecialCondition !== specialCondition) {
            // When the modal is re-opened, but there are no special conditions set (add template)
            setSpecialCondition({
                name: '',
                agreementType: null,
                onePageVersion: false,
                clauseList: [],
                text: ''
            });
        }
    }, [propsSpecialCondition]);

    function saveSpecialConditionRequest(template) {
        if (template.id) {
            return axios.put(`/api/agency/account/special-conditions/${template.id}`, template);
        } else {
            return axios.post('/api/agency/account/special-conditions', template);
        }
    }

    function addTemplate(values) {
        return saveSpecialConditionRequest(values)
            .then(response => {
                if (values.id) {
                    dispatch(updateSpecialCondition(response.data.specialCondition, response.data.templatesToUpdate));
                } else {
                    dispatch(addSpecialCondition(response.data.specialCondition));
                }
                return Promise.resolve();
            })
            .catch(error => {
                const response = error.response;
                setErrors(response.data.errors);
                return response.data.errors;
            });
    }

    function addClause(values, push) {
        if (values.agreementType) {
            push('clauseList', {
                clause: '',
                comment: '',
                optionType: getOptionDefaultType(values.agreementType)
            });
        } else {
            confirmAlert({
                title: '',
                message: 'Please select which agreement type this template is for before adding any clauses.',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            });
        }
    }

    function changeDefaultValues(values, agreementType) {
        const optionType = getOptionDefaultType(agreementType);
        const newValues = cloneDeep(values);
        newValues.agreementType = agreementType;
        if (newValues.clauseList) {
            newValues.clauseList.forEach(clause => {
                clause.optionType = optionType;
            });
        }
        setSpecialCondition(newValues);
    }

    return (
        <ModalDialog
            title={`${type} template`}
            isOpen={isOpen}
            closeModal={closeModal}
            leftButton={
                <ModalDialogLeftButton
                    specialCondition={specialCondition}
                    type={type}
                    deleteTemplate={deleteTemplate}
                />
            }
            rightButton={<ModalDialogRightButton type={type} formRef={formRef} />}
            shouldCloseOnOverlayClick={false}
        >
            <div className="special-conditions-modal">
                <Form
                    onSubmit={addTemplate}
                    initialValues={specialCondition}
                    validate={values => {
                        if (!values) {
                            return;
                        }
                        return !values.agreementType ? { agreementType: 'Agreement type is required' } : {};
                    }}
                    mutators={{
                        ...arrayMutators
                    }}
                >
                    {({
                        handleSubmit,
                        form: {
                            mutators: { push }
                        },
                        values
                    }) => {
                        const radioOptions = [
                            {
                                label: 'Residential Tenancy',
                                value: LEASE_TYPE_RESIDENTIAL_DISPLAY,
                                dataTest: LEASE_TYPE_RESIDENTIAL_DISPLAY,
                                icon: HouseIcon
                            },
                            {
                                label: 'Property Management',
                                value: LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
                                dataTest: LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
                                icon: BusinessCenterIcon,
                                onClick: () => changeDefaultValues(values, LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY)
                            },
                            {
                                label: 'Sales',
                                value: LEASE_TYPE_SALES_DISPLAY,
                                dataTest: LEASE_TYPE_SALES_DISPLAY,
                                icon: MonetizationOnIcon,
                                onClick: () => changeDefaultValues(values, LEASE_TYPE_SALES_DISPLAY)
                            },
                            {
                                label: 'Rent Relief',
                                value: DOCUMENT_RENT_REDUCTION,
                                dataTest: DOCUMENT_RENT_REDUCTION,
                                icon: TrendingDownIcon,
                                onClick: () => changeDefaultValues(values, DOCUMENT_RENT_REDUCTION)
                            },
                            {
                                label: 'Commercial Lease',
                                value: LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY,
                                icon: CommercialLeaseIcon,
                                onClick: () => changeDefaultValues(values, LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY)
                            }
                        ];
                        if (isNswHolidayLettingActive) {
                            radioOptions.push({
                                label: 'Holiday letting',
                                value: LEASE_TYPE_HOLIDAY_LETTING_DISPLAY,
                                icon: getIcon(Icons.HOLIDAY_HOUSE),
                                onClick: () => changeDefaultValues(values, LEASE_TYPE_HOLIDAY_LETTING_DISPLAY)
                            });
                        }

                        return (
                            <form id="special-conditions-form" onSubmit={handleSubmit} noValidate ref={formRef}>
                                <FormRadioGroupButton
                                    label="Please choose what agreement type this special condition template will be used for"
                                    name="agreementType"
                                    className={cx({ 'color-primary': !values.agreementType })}
                                    radioGroupClass={styles.agreementTypeRadioGroup}
                                    radioItemClassName={styles.agreementTypeRadioItem}
                                    data={radioOptions}
                                    required={true}
                                    validateOnTouch={false}
                                    value={values.agreementType}
                                />
                                <FormTextRegular name="name" label="Template name" disabled={!values.agreementType} />
                                {errors && errors.clauseList && typeof errors.clauseList === 'string' && (
                                    <p className="FormInput">
                                        <span className="FormError"> {errors.clauseList}</span>
                                    </p>
                                )}
                                {!specialCondition.onePageVersion && (
                                    <FieldArray name="clauseList">
                                        {({ fields }) =>
                                            fields.map((name, index) => (
                                                <div key={name} className="item-condition">
                                                    <div className="clauses">
                                                        <div className="clauses-header">
                                                            <h3>Clause {index + 1}</h3>
                                                            <div
                                                                className="toggle"
                                                                onClick={() => fields.remove(index)}
                                                            >
                                                                <span className="span-remove">Remove</span>
                                                            </div>
                                                        </div>
                                                        <div className="formBox-column">
                                                            {values.agreementType &&
                                                                shouldDisplayOption(values.agreementType) && (
                                                                    <FormRadioGroupButton
                                                                        className={'option-group'}
                                                                        label="Client reply option"
                                                                        name={`${name}.optionType`}
                                                                        data={[
                                                                            {
                                                                                label: 'Yes/No',
                                                                                value: OPTION_TYPE_YES_OR_NO,
                                                                                icon: yesNoIcon
                                                                            },
                                                                            {
                                                                                label: 'Agree',
                                                                                value: OPTION_TYPE_AGREE_ONLY,
                                                                                icon: DoneIcon
                                                                            }
                                                                        ]}
                                                                        required={true}
                                                                        validateOnTouch={false}
                                                                        value={values.clauseList[index].optionType}
                                                                    />
                                                                )}
                                                            <FormTextRegular
                                                                name={`${name}.clause`}
                                                                label="Title"
                                                                disabled={!values.agreementType}
                                                            />
                                                            <FormTextMultiline
                                                                disabled={!values.agreementType}
                                                                name={`${name}.comment`}
                                                                label="Description"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </FieldArray>
                                )}

                                {!specialCondition.onePageVersion && (
                                    <div>
                                        <a className="btn-add" onClick={() => addClause(values, push)}>
                                            Add clause
                                        </a>
                                    </div>
                                )}
                            </form>
                        );
                    }}
                </Form>
            </div>
        </ModalDialog>
    );
}
