import React from 'react';

import styles from './Maintenance.module.scss';
// @ts-ignore
import maintenanceImage from '../../../assets/images/scheduled-maintenance.svg';
// @ts-ignore
import appHistory from '../../AppHistory';

type SystemMaintenanceMessage = {
  message: string
  subMessage: string
};

type SystemMaintenance = {
  agentMessage: SystemMaintenanceMessage
};

const Maintenance = () => {
    let state: SystemMaintenance = appHistory.location.state;
    return (
        <div className={styles.maintenance}>
            <div>
                <img className={styles.maintenanceImage} src={maintenanceImage} alt="scheduled maintenance image" />
            </div>
            <div className={styles.maintenanceMessage}>
                <div>{state?.agentMessage?.message}</div>
                <div>{state?.agentMessage?.subMessage}</div>
            </div>
        </div>
    );
};

export default Maintenance;
