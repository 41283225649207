import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { IntercomProps } from 'react-use-intercom';

import { createAccountSuccess, setPending } from '@app/actions/authorization.js';
import { Subscription } from '@app/common/components/cards/SubscriptionCard';
import { CreateAccountForm, CreateAccountFormValues, SignUpFormContainer } from '@app/components/signup';
import { CreateAccountFormProps } from '@app/components/signup/SignUpForms';
import { SignUpStep } from '@app/config';
import { AccountType, AvailableCountries } from '@app/constants/constants';
import { EmailValidation } from '@app/types/User';

import styles from './AccountStep.module.scss';

type AccountStepProps = {
    isPending: boolean;
    accountType: AccountType;
    country: AvailableCountries;
    selectedSubscription: Subscription;
    updateIntercom: (props?: Partial<IntercomProps>) => void;
} & Pick<CreateAccountFormProps, 'initialValues'>;

const AccountStep: React.FC<AccountStepProps> = ({
    isPending,
    initialValues,
    accountType,
    country,
    selectedSubscription,
    updateIntercom
}) => {
    const dispatch = useDispatch();
    const [emailValidationResults, setEmailValidationResults] = React.useState<EmailValidation>();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const pushUserEmailVerificationTokenToIntercom = (token: string) => {
        updateIntercom({
            customAttributes: {
                signupToken: token
            }
        });
    };

    const createAccountMutation = useMutation({
        mutationFn: async (values: CreateAccountFormValues) => {
            if (!accountType || !country || !selectedSubscription) {
                throw new Error('Missing required data');
            }

            const additionalDataForGeneralAccount = {
                timezone: timezone
            };

            const valuesWithAdditionalData = {
                ...values,
                accountType: accountType,
                selectedPlan: selectedSubscription.size,
                ...(accountType === AccountType.GENERAL_ACCOUNT_TYPE ? additionalDataForGeneralAccount : {}),
                emailValidation: emailValidationResults
            };

            const response = await axios.post('/auth/sign-up', valuesWithAdditionalData);
            return response.data;
        },
        onMutate: () => {
            dispatch(setPending(true));
        },
        onSuccess: data => {
            dispatch(createAccountSuccess(data));
            pushUserEmailVerificationTokenToIntercom(data.token);
        },
        onSettled: () => {
            dispatch(setPending(false));
        }
    });

    return (
        <SignUpFormContainer
            hideBackButton
            currentStep={SignUpStep.Account - 1} //Offset because we index from 1
            className={styles.form}
            contentClassName={styles.content}
        >
            <CreateAccountForm
                onSubmit={async values => {
                    try {
                        await createAccountMutation.mutateAsync(values);
                    } catch (error) {
                        if (error.response) {
                            return error.response.data?.errors;
                        }
                    }
                }}
                isPending={isPending}
                initialValues={initialValues}
                accountType={accountType}
                setEmailValidationResults={setEmailValidationResults}
            />
        </SignUpFormContainer>
    );
};

export default AccountStep;
