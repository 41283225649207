import * as React from 'react';
import { cloneDeep, concat, isEmpty, indexOf, has, isInteger, includes, get, find } from 'lodash';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import {
    LEASE_STATUS_ARCHIVED,
    LEASE_STATUS_COMPLETE,
    LEASE_STATUS_CANCELLED,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_AWAITING_COMPLETION
} from '../actions/dashboard';
import {
    LEASE_TYPE_RESIDENTIAL,
    DOCUMENT_RENT_REDUCTION,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY,
    LEASE_TYPE_SALES,
    NSW_STATE,
    QLD_STATE,
    VIC_STATE,
    ACT_STATE,
    SA_STATE,
    TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_HOLIDAY_LETTING,
    TEMPLATE_TYPE_SALES,
    CREATE_A_FLK_TEMPLATE,
    FLK_A_PDF_TEMPLATE,
    FLK_A_PDF_DISPLAY,
    CREATE_A_FLK_DISPLAY,
    CREATE_A_FLK_GLOBAL_TEMPLATE,
    CREATE_A_FLK_GLOBAL_DISPLAY,
    TEMPLATE,
    LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
    LEASE_TYPE_SALES_DISPLAY,
    LEASE_TYPE_RESIDENTIAL_DISPLAY,
    SUB_LEASE_TYPE_SA_RENEWALS,
    validDocumentTypes,
    DOCUMENT_RENT_INCREASE,
    OPTION_TYPE_YES_OR_NO,
    OPTION_TYPE_AGREE_ONLY,
    REQUEST_TO_CHANGE_STATUS_RESOLVED,
    DOCUMENT_CREATE_A_FLK,
    DOCUMENT_CUSTOM,
    DOCUMENT_TERMINATION_NOTICE,
    BREACH_NOTICE,
    PERIOD_FORTNIGHTLY,
    PERIOD_MONTHLY,
    DEFAULT_SUB_LEASE_TYPE,
    SUB_LEASE_TYPE_LONG_TERM_RENEWAL,
    PERIOD_YEARLY,
    SCHEDULE_TYPE_LATER,
    YES_LABEL,
    ALL,
    LEASE_TYPE_HOLIDAY_LETTING,
    LEASE_TYPE_HOLIDAY_LETTING_DISPLAY,
    defaultSalesAttachedDocuments,
    defaultTenancyAttachedDocuments,
    defaultCommercialLeaseAttachedDocuments,
    defaultPmAttachedDocuments
} from '../config';
import {
    LANDLORD_INFORMATION_STATEMENT_STATUS_NOT_SENT,
    LANDLORD_INFORMATION_STATEMENT_READY_TO_BE_SENT,
    LANDLORD_INFORMATION_STATEMENT_STATUS_SENT,
    LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED,
    LANDLORD_INFORMATION_STATEMENT_STATUS_SEND_FAIL
} from '../config';
import ReactTooltip from 'react-tooltip';
import { steps } from '../components/lease/steps';

/**
 * List of all required steps.
 *
 * @type {Array<string>}
 * @private
 */
const requiredQLDTenancyStepList = [
    'address',
    'landlord',
    'tenant',
    'term',
    'rent',
    'paymentMethod',
    'inclusions',
    'maxOccupants',
    'nominatedRepairers',
    'additionalTerms',
    'specialConditions',
    'documents',
    'connections'
];
const requiredVICTenancyStepList = [
    'address',
    'landlord',
    'tenant',
    'term',
    'rent',
    'paymentMethod',
    'inclusions',
    'maxOccupants',
    'emergencyContacts',
    'additionalTerms',
    'specialConditions',
    'documents',
    'connections'
];
const requiredACTTenancyStepList = [
    'address',
    'landlord',
    'tenant',
    'term',
    'rent',
    'paymentMethod',
    'inclusions',
    'maxOccupants',
    'emergencyContacts',
    'additionalTerms',
    'specialConditions',
    'documents',
    'connections'
];
const requiredSATenancyStepList = [
    'address',
    'landlord',
    'tenant',
    'exclusions',
    'term',
    'rent',
    'paymentMethod',
    'outgoings',
    'insurance',
    'pets',
    'repairsAndMaintenance',
    'disclosure',
    'instructions',
    'documents',
    'specialConditions',
    'executedAsAgreement',
    'connections'
];
const saResTenLongTermRenewalSteps = ['address', 'tenant', 'term', 'rent'];

const requiredNSWTenancyStepList = [
    'address',
    'landlord',
    'tenant',
    'tenantsAgent',
    'term',
    'rent',
    'paymentMethod',
    'inclusions',
    'maxOccupants',
    'emergencyContacts',
    'additionalTerms',
    'materialFacts',
    'specialConditions',
    'documents',
    'connections'
];

const requiredNSWCommercialLeaseStepList = [
    'address',
    'lessor',
    'licensee',
    'lessee',
    'guarantor',
    'premises',
    'permittedUse',
    'term',
    'rent',
    'securityDeposit',
    'information',
    'specialConditions',
    'documents'
];
const requiredSalesStepList = [
    'address',
    'signatory',
    'landlordsSolicitor',
    'licensee',
    'inclusions',
    'priceAndFee',
    'expensesAndCharges',
    'term',
    'marketingAndAdvertising',
    'inspectionDays',
    'specialConditions',
    'documents',
    'disclosureOfRebates'
];

const requiredSalesQldStepList = [
    'address',
    'signatory',
    'landlordsSolicitor',
    'licensee',
    'managingAgent',
    'propertyDetails',
    'propertyDescription',
    'term',
    'priceAndFee',
    'marketingAndAdvertising',
    'expensesAndCharges',
    'disclosureOfRebates',
    'materialFacts',
    'specialConditions',
    'documents'
];

const requiredPmStepList = [
    'address',
    'pmLandlord',
    'licensee',
    'pmAgreementTerm',
    'pmDetailsRepairs',
    'pmRentAndTerm',
    'pmFees',
    'pmExpensesAndCharges',
    'pmOtherServices',
    'marketingAndAdvertising',
    'inspectionDays',
    'specialConditions',
    'pmDuties',
    // 'agentAuthority',
    'pmOutgoings',
    'paymentMethod',
    'documents',
    'disclosureOfRebates'
];
const requiredQLDPmStepList = [
    'address',
    'pmLandlord',
    'licensee',
    'pmDetailsRepairs',
    'pmAgreementTerm',
    'pmRentAndPayment',
    'pmFees',
    'pmOtherServices',
    'marketingAndAdvertising',
    'disclosureOfRebates',
    'pmOutgoings',
    'tenantPayments',
    'pmEmergencyContacts',
    // will add Warranty & Maintenance Contracts in future
    // 'warrantyAndMaintenance',
    'poolSafetyAndSmokeAlarms',
    'specialConditions',
    'documents'
];
const requiredVicPmStepList = [
    'address',
    'licensee',
    'pmLandlord',
    'pmDetailsRepairs',
    'pmAuthorityPeriodDetails',
    'pmRentAndTerm',
    'pmFees',
    'pmMarketingCosts',
    'pmAdditionalCosts',
    'pmOutgoings',
    'pmRentAndPayment',
    'disclosureOfRebates',
    'pmRepairs',
    'pmServicesSchedule',
    'specialConditions',
    'documents'
];

const requiredSaPmStepList = [
    'address',
    'pmLandlord',
    'licensee',
    'pmDetailsRepairs',
    'pmAgreementTerm',
    'pmRentAndTerm',
    'pmFees',
    'pmMarketingCosts',
    'thirdPartyPayment',
    'pmDuties',
    'insurance',
    'paymentMethod',
    'pmEmergencyContacts',
    'specialConditions',
    'documents'
];

const requiredStepList = {
    residential_tenancy: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: requiredNSWTenancyStepList,
            [QLD_STATE]: requiredQLDTenancyStepList,
            [VIC_STATE]: requiredVICTenancyStepList,
            [ACT_STATE]: requiredACTTenancyStepList,
            [SA_STATE]: requiredSATenancyStepList
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: saResTenLongTermRenewalSteps
        }
    },
    sales: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: requiredSalesStepList,
            [QLD_STATE]: requiredSalesQldStepList,
            [VIC_STATE]: requiredSalesStepList,
            [ACT_STATE]: requiredSalesStepList,
            [SA_STATE]: requiredSalesStepList
        }
    },
    sales_template: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: requiredSalesStepList,
            [QLD_STATE]: requiredSalesStepList,
            [VIC_STATE]: requiredSalesStepList,
            [ACT_STATE]: requiredSalesStepList,
            [SA_STATE]: requiredSalesStepList
        }
    },
    property_management: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: requiredPmStepList,
            [QLD_STATE]: requiredQLDPmStepList,
            [VIC_STATE]: requiredVicPmStepList,
            [ACT_STATE]: requiredPmStepList,
            [SA_STATE]: requiredSaPmStepList
        }
    },
    property_management_template: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: requiredPmStepList,
            [QLD_STATE]: requiredQLDPmStepList,
            [VIC_STATE]: requiredVicPmStepList,
            [ACT_STATE]: requiredPmStepList,
            [SA_STATE]: requiredSaPmStepList
        }
    },
    commercial_lease: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: requiredNSWCommercialLeaseStepList,
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: []
        }
    }
};

const notRequiredStepList = {
    residential_tenancy: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: []
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: []
        }
    },
    sales: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: []
        }
    },
    sales_template: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: []
        }
    },
    property_management: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: []
        }
    },
    property_management_template: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: []
        }
    },
    commercial_lease: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: []
        }
    }
};

export const mappingsToBackend = {
    [LEASE_TYPE_SALES]: LEASE_TYPE_SALES_DISPLAY,
    [LEASE_TYPE_SALES_DISPLAY]: LEASE_TYPE_SALES_DISPLAY,
    [LEASE_TYPE_RESIDENTIAL]: LEASE_TYPE_RESIDENTIAL_DISPLAY,
    [LEASE_TYPE_COMMERCIAL_LEASE]: LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY,
    [LEASE_TYPE_HOLIDAY_LETTING]: LEASE_TYPE_HOLIDAY_LETTING_DISPLAY,
    [LEASE_TYPE_RESIDENTIAL_DISPLAY]: LEASE_TYPE_RESIDENTIAL_DISPLAY,
    [LEASE_TYPE_PROPERTY_MANAGEMENT]: LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
    [LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY]: LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
    [TEMPLATE_TYPE_SALES]: LEASE_TYPE_SALES_DISPLAY,
    [TEMPLATE_TYPE_PROPERTY_MANAGEMENT]: LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
    [SUB_LEASE_TYPE_SA_RENEWALS]: LEASE_TYPE_RESIDENTIAL_DISPLAY
};

export const mappingsToFrontEnd = {
    [LEASE_TYPE_SALES_DISPLAY]: LEASE_TYPE_SALES,
    [LEASE_TYPE_RESIDENTIAL_DISPLAY]: LEASE_TYPE_RESIDENTIAL,
    [LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY]: LEASE_TYPE_PROPERTY_MANAGEMENT,
    [LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY]: LEASE_TYPE_COMMERCIAL_LEASE,
    [LEASE_TYPE_HOLIDAY_LETTING_DISPLAY]: LEASE_TYPE_HOLIDAY_LETTING
};
export function getResTenStepNo(location, part, subLeaseType = DEFAULT_SUB_LEASE_TYPE) {
    const resTenSteps = steps[LEASE_TYPE_RESIDENTIAL][subLeaseType][location];
    return resTenSteps.find(element => element.part === part).step;
}
export function getSalesStepNo(location, part, subLeaseType = DEFAULT_SUB_LEASE_TYPE) {
    const salesSteps = steps[LEASE_TYPE_SALES][subLeaseType][location];
    return salesSteps.find(element => element.part === part).step;
}
export function getPmStepNo(location, part, subLeaseType = DEFAULT_SUB_LEASE_TYPE) {
    const pmSteps = steps[LEASE_TYPE_PROPERTY_MANAGEMENT][subLeaseType][location];
    return pmSteps.find(element => element.part === part).step;
}
export function getClStepNo(location, part, subLeaseType = DEFAULT_SUB_LEASE_TYPE) {
    const clSteps = steps[LEASE_TYPE_COMMERCIAL_LEASE][subLeaseType][location];
    return clSteps.find(element => element.part === part).step;
}

export function getPmStepTitle(location, part, subLeaseType = DEFAULT_SUB_LEASE_TYPE) {
    const pmSteps = steps[LEASE_TYPE_PROPERTY_MANAGEMENT][subLeaseType][location];
    return pmSteps.find(element => element.part === part).title;
}
export function getClStepTitle(location, part, subLeaseType = DEFAULT_SUB_LEASE_TYPE) {
    const clSteps = steps[LEASE_TYPE_COMMERCIAL_LEASE][subLeaseType][location];
    return clSteps.find(element => element.part === part).title;
}
export function getSalesStepTitle(location, part, subLeaseType = DEFAULT_SUB_LEASE_TYPE) {
    const salesSteps = steps[LEASE_TYPE_SALES][subLeaseType][location];
    return salesSteps?.find(element => element.part === part)?.title;
}

const LANDLORD_ACKNOWLEDGE_TEXT = ' and acknowledged outside of FLK it over';

export function getTotalStepsCount(leaseType, subLeaseType = DEFAULT_SUB_LEASE_TYPE, location = NSW_STATE) {
    const key = getLeaseTypeForFrontEnd(leaseType);
    let totalStepCount = 0;
    if (requiredStepList[key] && requiredStepList[key][subLeaseType]) {
        totalStepCount =
            requiredStepList[key][subLeaseType][location].length +
            notRequiredStepList[key][subLeaseType][location].length;
    }
    return totalStepCount;
}

export function getRequiredStepsCount(leaseType, subLeaseType = DEFAULT_SUB_LEASE_TYPE, location = NSW_STATE) {
    const key = getLeaseTypeForFrontEnd(leaseType);
    return requiredStepList[key] ? requiredStepList[key][subLeaseType][location].length : 0;
}

export function getAllSteps(leaseType, subLeaseType = DEFAULT_SUB_LEASE_TYPE, location = NSW_STATE) {
    const key = getLeaseTypeForFrontEnd(leaseType);
    if (requiredStepList[key] && notRequiredStepList[key]) {
        return concat(requiredStepList[key][subLeaseType][location], notRequiredStepList[key][subLeaseType][location]);
    }
    return 0;
}

export function booleanToString(object, except = []) {
    let newObject = cloneDeep(object);
    for (const key in newObject) {
        if (!includes(except, key)) {
            if (typeof newObject[key] === 'boolean') {
                if (newObject[key]) {
                    newObject[key] = 'yes';
                } else {
                    newObject[key] = 'no';
                }
            } else if (typeof newObject[key] === 'object') {
                newObject[key] = booleanToString(newObject[key], except);
            }
        }
    }
    return newObject;
}

export function calcBond(amount, isLeaseLocationSA = false) {
    if (!amount) {
        return 0;
    }
    if (isLeaseLocationSA && amount >= 800) {
        return amount * 6;
    }
    return amount * 4;
}

/**
 * Calculate lease agreement progress.
 *
 * @param lease
 * @param leaseType
 * @param location
 * @return {number}
 */
export function calculateProgress(lease, leaseType, location = NSW_STATE) {
    // Count the steps that are not required as done
    const leaseTypeVal = leaseType ? leaseType : lease.leaseType;
    const key = getLeaseTypeForFrontEnd(leaseTypeVal);
    let progress = 0;
    progress = notRequiredStepList[key]
        ? notRequiredStepList[key][lease.subLeaseType || DEFAULT_SUB_LEASE_TYPE][location].length
        : 0;

    requiredStepList[key] &&
        requiredStepList[key][lease.subLeaseType || DEFAULT_SUB_LEASE_TYPE][location].forEach(item => {
            if (!(lease[item] === undefined || lease[item] === null)) {
                progress++;
            }
        });
    return progress;
}

/**
 * Return first missed step, or step that is invalid for lease that has been created via a template.
 *
 * @param lease
 * @param leaseType
 * @param location
 * @return {number}
 */
export function getMissedStep(lease, leaseType, location = NSW_STATE) {
    const key = getLeaseTypeForFrontEnd(leaseType);
    if (requiredStepList[key]) {
        for (let i = 0; i < requiredStepList[key][lease.subLeaseType || DEFAULT_SUB_LEASE_TYPE][location].length; i++) {
            let field = requiredStepList[key][lease.subLeaseType || DEFAULT_SUB_LEASE_TYPE][location][i];
            if (lease[field] === undefined || lease[field] === null) {
                return ++i;
            }
        }

        let invalidParts = leaseCustomValidation(lease);
        if (!isEmpty(invalidParts)) {
            let index = indexOf(
                requiredStepList[key][lease.subLeaseType || DEFAULT_SUB_LEASE_TYPE][lease.location],
                invalidParts[0]
            );
            // in order to replicate the functionality in the for loop above
            // and get the correct index of the missed step we need to increase by 1
            return index + 1;
        }

        /**
         * Backend returns invalid steps, if they exist we will return the first one for now
         * In the future we could show the errors on the frontend straight away
         */
        if (lease.invalidSteps.length > 0) {
            return lease.invalidSteps[0].index;
        }
    }

    return 2;
}

/**
 * Custom frontend validation for a lease
 * Returns a array of lease parts that are not valid.
 * This was built to be used with the "missing step" functionality
 * as a more detailed validation.
 *
 * @param lease
 * @returns {Array}
 */
export function leaseCustomValidation(lease) {
    let invalidParts = [];
    if (
        has(lease, 'additionalTerms.swimmingPool') &&
        lease.additionalTerms.swimmingPool &&
        (!lease.documents || isEmpty(lease.documents) || !documentExists(lease.documents, 'swimming_pool_compliance'))
    ) {
        invalidParts.push('documents');
    } else if (
        has(lease, 'premises.anyAttachedInventory') &&
        lease.premises.anyAttachedInventory === YES_LABEL &&
        (!lease.documents || isEmpty(lease.documents) || !documentExists(lease.documents, 'inventory'))
    ) {
        invalidParts.push('documents');
    }

    return invalidParts;
}

/**
 * Checks if a document of a specific category exists in an array of documents
 * @param documents
 * @param category
 * @returns {boolean}
 */
export function documentExists(documents, category) {
    if (documents && documents.length > 0) {
        return !!documents.find(element => element.category === category);
    }
    return false;
}

/**
 * Calculate monthly rent.
 * @param {number} weeklyRent rent per week value.
 * @return {number}
 */
export function calculateMonthlyRent(weeklyRent) {
    return (((weeklyRent / 7) * 365) / 12).toFixed(2);
}

/**
 * Calculate weekly rent from monthly rent.
 * @param {number} monthlyRent rent per month value.
 * @return {number}
 */
export function calculateFromMonthlyRent(monthlyRent) {
    const weeklyRent = ((monthlyRent * 7) / 365) * 12;
    return weeklyRent > 0 ? weeklyRent : 0;
}

/**
 * Calculate the end date given the period, start date and the length of the period
 *
 * @param period - string week|month|year
 * @param startDate
 * @param qty
 * @returns {Duration|*}
 */
export function calculateFinishDate(period, startDate, qty) {
    // We need to subtract a day to adjust the end date of the lease
    // If the lease starts on the 25th October 2017 and the lease is a year long,
    // it should end on the 24th October 2018

    // Validate the qty, for period and start date if the values are invalid,
    // the moment date will be invalid so we don't validate them explicitly
    if (!isInteger(qty) && isEmpty(qty)) {
        return null;
    }
    let date;
    if (period === 'week') {
        date = add(startDate, { days: qty * 7 });
    } else {
        date = add(startDate, { [period + 's']: qty });
    }
    date = sub(date, { days: 1 });

    if (date) {
        return date;
    }

    return null;
}

/**
 * Returns whether the termination notice functionality is enabled for a lease.
 * @param lease
 * @returns {boolean}
 */
export function isTerminationNoticeEnabled(lease) {
    return (
        [LEASE_STATUS_ARCHIVED, LEASE_STATUS_COMPLETE, LEASE_STATUS_CANCELLED].includes(lease.status) &&
        lease.leaseType.toLowerCase().replace(' ', '_') === LEASE_TYPE_RESIDENTIAL &&
        (lease.location === NSW_STATE || lease.location === QLD_STATE)
    );
}

/**
 * Returns whether the rent increase functionality is enabled for a lease.
 * @param lease
 * @returns {boolean}
 */
export function isRentIncreaseEnabled(lease) {
    return (
        (lease.status === LEASE_STATUS_ARCHIVED || lease.status === LEASE_STATUS_COMPLETE) &&
        isLeaseTypeResidentialTenancy(lease.leaseType) &&
        isLeaseLocationNSW(lease.location)
    );
}

export const mapLeaseStatusToCategory = status => {
    if (status === 'completed' || status === 'complete') {
        return 'archived';
    }
    return status;
};

export function isLeaseTypeSales(leaseType) {
    if (
        (leaseType && leaseType.toLowerCase() === LEASE_TYPE_SALES) ||
        (leaseType && leaseType.toLowerCase() === TEMPLATE_TYPE_SALES)
    ) {
        return true;
    }
    return false;
}

export function isTemplate(leaseType) {
    if (leaseType && leaseType.includes(TEMPLATE)) {
        return true;
    }
    return false;
}

export function isDocumentType(leaseType) {
    if (leaseType && validDocumentTypes.includes(leaseType)) {
        return true;
    }
    return false;
}

export function getTemplateTypeForLeaseType(leaseType) {
    if (leaseType === LEASE_TYPE_PROPERTY_MANAGEMENT) {
        return TEMPLATE_TYPE_PROPERTY_MANAGEMENT;
    } else if (leaseType === LEASE_TYPE_SALES) {
        return TEMPLATE_TYPE_SALES;
    }
}

export function getLeaseTypeForTemplateType(leaseType) {
    if (leaseType === TEMPLATE_TYPE_PROPERTY_MANAGEMENT) {
        return LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY;
    } else if (leaseType === TEMPLATE_TYPE_SALES) {
        return LEASE_TYPE_SALES_DISPLAY;
    } else if (leaseType === TEMPLATE_TYPE_HOLIDAY_LETTING) {
        return LEASE_TYPE_HOLIDAY_LETTING_DISPLAY;
    } else if (leaseType === CREATE_A_FLK_TEMPLATE) {
        return CREATE_A_FLK_DISPLAY;
    } else if (leaseType === CREATE_A_FLK_GLOBAL_TEMPLATE) {
        return CREATE_A_FLK_GLOBAL_DISPLAY;
    } else if (leaseType === FLK_A_PDF_TEMPLATE) {
        return FLK_A_PDF_DISPLAY;
    }
}

export function isLeaseTypePm(leaseType) {
    if (
        (leaseType && leaseType.toLowerCase() === 'property management') ||
        (leaseType && leaseType.toLowerCase() === LEASE_TYPE_PROPERTY_MANAGEMENT) ||
        (leaseType && leaseType.toLowerCase() === TEMPLATE_TYPE_PROPERTY_MANAGEMENT)
    ) {
        return true;
    }
    return false;
}

export function isLeaseTypeResidentialTenancy(leaseType) {
    if (
        (leaseType && leaseType.toLowerCase() === 'residential tenancy') ||
        (leaseType && leaseType.toLowerCase() === LEASE_TYPE_RESIDENTIAL)
    ) {
        return true;
    }
    return false;
}

export function isLeaseTypeCommercialLease(leaseType) {
    if (
        (leaseType && leaseType.toLowerCase() === 'commercial lease') ||
        (leaseType && leaseType.toLowerCase() === LEASE_TYPE_COMMERCIAL_LEASE)
    ) {
        return true;
    }
    return false;
}

export function isLeaseTypeHolidayLetting(leaseType) {
    if (
        (leaseType && leaseType.toLowerCase() === 'holiday letting') ||
        (leaseType && leaseType.toLowerCase() === LEASE_TYPE_HOLIDAY_LETTING)
    ) {
        return true;
    }
    return false;
}

export function isDefaultSubLease(subLeaseType) {
    if (subLeaseType && subLeaseType === DEFAULT_SUB_LEASE_TYPE) {
        return true;
    }
    return false;
}

export function isLongTermRenewalSubLease(subLeaseType) {
    if (subLeaseType === SUB_LEASE_TYPE_LONG_TERM_RENEWAL) {
        return true;
    }
    return false;
}

export function isPropertyManagementTemplate(type) {
    if (type && type === TEMPLATE_TYPE_PROPERTY_MANAGEMENT) {
        return true;
    }
    return false;
}

export function isDocTypeRentIncrease(docType) {
    if (docType && docType.toLowerCase() === DOCUMENT_RENT_INCREASE) {
        return true;
    }
    return false;
}

export function isDocTypeRentRelief(docType) {
    if (docType && docType.toLowerCase() === DOCUMENT_RENT_REDUCTION) {
        return true;
    }
    return false;
}
export function isDocTypeTerminationNotice(docType) {
    if (docType && docType.toLowerCase() === DOCUMENT_TERMINATION_NOTICE) {
        return true;
    }
    return false;
}
export function isDocTypeBreachNotice(docType) {
    if (docType && docType.toLowerCase() === BREACH_NOTICE) {
        return true;
    }
    return false;
}

export function isDocTypeCreateAFlk(docType) {
    if (docType && docType.toLowerCase() === DOCUMENT_CREATE_A_FLK) {
        return true;
    }
    return false;
}
export function isDocTypeFlkAPdf(docType) {
    if (docType && docType.toLowerCase() === DOCUMENT_CUSTOM) {
        return true;
    }
    return false;
}
export function isDocTypeALL(docType) {
    if (docType && docType.toLowerCase() === ALL) {
        return true;
    }
    return false;
}

export function isSalesTemplate(type) {
    if (type && type === TEMPLATE_TYPE_SALES) {
        return true;
    }
    return false;
}

export function isLeaseLocationNSW(location) {
    return location === NSW_STATE;
}

export function isLeaseLocationQLD(location) {
    return location === QLD_STATE;
}

export function isLeaseLocationVIC(location) {
    return location === VIC_STATE;
}

export function isLeaseLocationSA(location) {
    return location === SA_STATE;
}

export function isLeaseLocationACT(location) {
    return location === ACT_STATE;
}

export function getOptionDefaultType(agreementType) {
    return agreementType === LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY ? OPTION_TYPE_YES_OR_NO : OPTION_TYPE_AGREE_ONLY;
}

/**
 * Function used in a few places to check if all change requests are resolved
 * @param changeRequestList
 * @returns {boolean}
 */
export function allRequestsResolved(changeRequestList) {
    let allResolved = true;

    for (let key in changeRequestList) {
        // eslint-disable-next-line no-prototype-builtins
        if (!changeRequestList.hasOwnProperty(key)) {
            continue;
        }
        let request = changeRequestList[key];

        if (typeof request.status === 'undefined' || request.status !== REQUEST_TO_CHANGE_STATUS_RESOLVED) {
            allResolved = false;
            break;
        }
    }

    return allResolved;
}

export function isCompleted(lease, leaseType, location) {
    if (
        lease &&
        !isEmpty(lease) &&
        leaseType &&
        location &&
        !isTemplate(leaseType) &&
        !isDocumentType(leaseType) &&
        isEmpty(lease.invalidSteps)
    ) {
        return (
            calculateProgress(lease, leaseType, location) ===
            getTotalStepsCount(leaseType, lease.subLeaseType, location)
        );
    }
    return false;
}

export function isCompletedAndValidated(lease, leaseType, location) {
    if (leaseType && !isTemplate(leaseType) && !isDocumentType(leaseType)) {
        if (lease && isCompleted(lease, leaseType, location)) {
            return lease.invalidSteps.length === 0 && leaseCustomValidation(lease).length === 0;
        }
    } else {
        return true;
    }
    return false;
}

export function renderLandlordInformationStatementStatus(status, ManuallyAcknowledged, sendMethod, index) {
    switch (status) {
        case LANDLORD_INFORMATION_STATEMENT_STATUS_NOT_SENT:
            return (
                <React.Fragment>
                    <ReactTooltip
                        id={`landlordStatusNotSent-${index}`}
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>
                            Landlord acknowledgement status: Not sent
                            {ManuallyAcknowledged ? LANDLORD_ACKNOWLEDGE_TEXT : ''}
                        </p>
                    </ReactTooltip>
                    <span
                        className={`landlord-status landlord-status-not-sent${sendMethod === 'sms' ? '-message' : ''}`}
                        data-tip
                        data-for={`landlordStatusNotSent-${index}`}
                    />
                </React.Fragment>
            );
        case LANDLORD_INFORMATION_STATEMENT_READY_TO_BE_SENT:
            return (
                <React.Fragment>
                    <ReactTooltip
                        id={`landlordStatusReadyToBeSent-${index}`}
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>
                            Landlord acknowledgement status: Ready to be sent
                            {ManuallyAcknowledged ? LANDLORD_ACKNOWLEDGE_TEXT : ''}
                        </p>
                    </ReactTooltip>
                    <span
                        className={`landlord-status landlord-status-ready-to-be-sent${
                            sendMethod === 'sms' ? '-message' : ''
                        }`}
                        data-tip
                        data-for={`landlordStatusReadyToBeSent-${index}`}
                    />
                </React.Fragment>
            );
        case LANDLORD_INFORMATION_STATEMENT_STATUS_SEND_FAIL:
            return (
                <React.Fragment>
                    <ReactTooltip
                        id={`landlordStatusFailed-${index}`}
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>
                            Landlord acknowledgement status: Sending Failed
                            {ManuallyAcknowledged ? LANDLORD_ACKNOWLEDGE_TEXT : ''}
                        </p>
                    </ReactTooltip>
                    <span
                        className={`landlord-status landlord-status-failed${sendMethod === 'sms' ? '-message' : ''}`}
                        data-tip
                        data-for={`landlordStatusFailed-${index}`}
                    />
                </React.Fragment>
            );
        case LANDLORD_INFORMATION_STATEMENT_STATUS_SENT:
            return (
                <React.Fragment>
                    <ReactTooltip
                        id={`landlordStatusPending-${index}`}
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>
                            Landlord acknowledgement status: Pending
                            {ManuallyAcknowledged ? LANDLORD_ACKNOWLEDGE_TEXT : ''}
                        </p>
                    </ReactTooltip>
                    <span
                        className={`landlord-status landlord-status-pending${sendMethod === 'sms' ? '-message' : ''}`}
                        data-tip
                        data-for={`landlordStatusPending-${index}`}
                    />
                </React.Fragment>
            );
        case LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED:
            return (
                <React.Fragment>
                    <ReactTooltip
                        id={`landlordStatusAcknowledged-${index}`}
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>Landlord acknowledgement status: Acknowledged</p>
                    </ReactTooltip>

                    <span
                        className={`landlord-status landlord-status-confirmed${sendMethod === 'sms' ? '-message' : ''}`}
                        data-tip
                        data-for={`landlordStatusAcknowledged-${index}`}
                    />
                </React.Fragment>
            );
        default:
            return null;
    }
}

export const getClientFromLeaseType = (leaseType, location) => {
    if (isLeaseTypeResidentialTenancy(leaseType)) {
        if (location === VIC_STATE) {
            return 'renter';
        }
        return 'tenant';
    } else if (isLeaseTypeSales(leaseType)) {
        return 'vendor';
    } else {
        return 'landlord';
    }
};

export const getUpdateObject = (lease, isChangeRequest = false) => {
    const stepObject = {};
    stepObject.documents = lease.documents.documents;
    stepObject.address = isChangeRequest ? lease.common.address : lease.address;
    return stepObject;
};

export function isCompletedAndValidatedAllSteps(lease) {
    let isCompleted = true;

    const frontendCalculatedProgress = calculateProgress(lease, lease.leaseType, lease.location);
    const totalStepCount = getTotalStepsCount(lease.leaseType, lease.subLeaseType, lease.location);

    // Check that all of the required steps exist and that for a
    // new lease that may have been created via a template, we have no invalid steps
    if (frontendCalculatedProgress !== totalStepCount || !isEmpty(lease.invalidSteps)) {
        isCompleted = false;
    }

    // If the first validation passes then check custom lease validation
    if (isCompleted === true) {
        let invalidParts = leaseCustomValidation(lease);
        if (!isEmpty(invalidParts)) {
            isCompleted = false;
        }
        // if frontend progress is not equal to the backend progress, it is not completed
        if (frontendCalculatedProgress !== lease.progress) {
            isCompleted = false;
        }
    }
    return isCompleted;
}

export function canSendRentIncreaseLetter(leaseAllInfo) {
    let canSendEmail = get(leaseAllInfo, 'tenant.isServicesOfNoticesEmail', false);
    let willRentIncrease = get(leaseAllInfo, 'rent.isRentIncrease', false);
    return leaseAllInfo.location === NSW_STATE ? canSendEmail && willRentIncrease : false;
}

export const serverToDocType = {
    rent_increase_agreement: 'Rent increase',
    rent_reduction_agreement: 'Rent relief',
    termination_notice: 'Termination notice',
    document_custom: FLK_A_PDF_DISPLAY,
    create_a_flk: CREATE_A_FLK_DISPLAY,
    breach_notice: 'Breach notice',
    estimated_selling_price_notice: 'Esp notice'
};

export const serverToStatus = {
    awaiting_completion: 'Awaiting completion',
    sent_for_signing: 'Sent for signing',
    draft: 'Draft',
    awaiting_renewal: 'Awaiting renewal',
    archived: 'Complete',
    complete: 'Complete',
    error: 'Error',
    active: 'Active',
    cancelled: 'Cancelled'
};

/**
 * Calculate the rent for a period
 * @param amount (the weekly rent cost)
 * @param period
 * @returns {string}
 */
export const getRentForPeriod = (amount, period) => {
    switch (period) {
        case PERIOD_YEARLY: {
            return ((amount / 7) * 365).toFixed(2);
        }
        case PERIOD_MONTHLY: {
            return (((amount / 7) * 365) / 12).toFixed(2);
        }
        case PERIOD_FORTNIGHTLY: {
            return (amount * 2).toFixed(2);
        }
        default: {
            return amount ? amount.toFixed(2) : amount;
        }
    }
};
export function hasSaExtensions(agreementList) {
    const saExtensionList = find(agreementList, { subLeaseType: SUB_LEASE_TYPE_LONG_TERM_RENEWAL });
    return !isEmpty(saExtensionList);
}

export function hasNoticeToLeaveLeases(agreementList) {
    const noticeToLeaveList = find(
        agreementList,
        lease =>
            lease?.renewalNoticeToLeaveDetails?.isRenewalToLeaveFlow &&
            lease.renewalNoticeToLeaveDetails.scheduleType === SCHEDULE_TYPE_LATER
    );
    return !isEmpty(noticeToLeaveList);
}

export function getLeaseTypeForFrontEnd(leaseType) {
    return leaseType.replace(/ /g, '_').toLowerCase();
}

export function getStep(steps, leaseType, location, step, subLeaseType = DEFAULT_SUB_LEASE_TYPE) {
    // In DB we have lease type as normal string (Residential Tenancy) but in front end we use that as snake case string (residential_tenancy)
    const frontEndLeaseType = getLeaseTypeForFrontEnd(leaseType);
    return steps[frontEndLeaseType][subLeaseType][location][step];
}

export function getLeaseTypeDisplayName(leaseType) {
    return `${leaseType.replace('_', ' ')} agreements`;
}

export function isValidRenewalFlowDetails(lease, agency) {
    if (
        isLeaseTypeResidentialTenancy(lease.leaseType) &&
        isLeaseLocationQLD(lease.location) &&
        lease.status === LEASE_STATUS_DRAFT &&
        lease.term &&
        !lease.term.periodic
    ) {
        // If agency has default value for isRenewalToLeaveFlow is false then this is valid
        // If default value is true and lease has vacantPossessionDate that means this is valid
        // And lease isRenewalToLeaveFlow is false then it is valid
        return (
            !agency?.renewalNoticeToLeaveDetails?.isRenewalToLeaveFlow ||
            lease?.renewalNoticeToLeaveDetails?.vacantPossessionDate ||
            (lease.renewalNoticeToLeaveDetails && !lease.renewalNoticeToLeaveDetails.isRenewalToLeaveFlow)
        );
    } else {
        return true;
    }
}

export function needWitnessSignature(lease) {
    const { leaseType } = lease;
    if (isLeaseTypeCommercialLease(leaseType)) {
        return !lease?.lessor?.isCorporation;
    } else if (isLeaseTypeResidentialTenancy(leaseType)) {
        return isLeaseLocationACT(lease.location);
    }
    return false;
}

export function getTenantNames(lease) {
    let names = '-';
    if (isLeaseTypeSales(lease.leaseType) && lease.signatory) {
        names = lease.signatory.signatoryListText;
    } else if (isLeaseTypeResidentialTenancy(lease.leaseType) && lease.tenant) {
        names = lease.tenant.tenantsListText;
    } else if (isLeaseTypeCommercialLease(lease.leaseType) && lease.lessee) {
        names = lease.lessee.lesseesListText;
    } else if (isLeaseTypePm(lease.leaseType) && lease.pmLandlord) {
        names = lease.pmLandlord.pmLandlordListText;
    }
    return names;
}

export function disableLeaseDeletion(isUserAdmin, status) {
    return !isUserAdmin && (status === LEASE_STATUS_AWAITING_COMPLETION || status === LEASE_STATUS_COMPLETE);
}

/**
 * Return a list of Objects, containing containing document name and category
 * based on the location and leaseType.
 *
 * leaseType param is passed in two formats one for the creation and the other is the modal display.
 *
 * @param {String} leaseType
 * @param {String} location
 *
 * @return {Object} Array of Objects containing document name and category.
 */
export function getDefaultAttachedDocuments(leaseType, location) {
    if (isLeaseTypeSales(leaseType)) {
        return defaultSalesAttachedDocuments[location];
    } else if (isLeaseTypeResidentialTenancy(leaseType)) {
        return defaultTenancyAttachedDocuments[location];
    } else if (isLeaseTypeCommercialLease(leaseType)) {
        return defaultCommercialLeaseAttachedDocuments[location];
    } else if (isLeaseTypePm(leaseType)) {
        return defaultPmAttachedDocuments[location];
    }
}
