import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import SyncClient from 'twilio-sync';
import cookie from 'react-cookie';
import { useSelector } from 'react-redux';
import { getIsUserLoggedIn } from '../../selectors/login';

/**
 * @returns {Promise}
 */
const getTwilioAccessToken = () => {
    return axios.get(`/api/user/twilio-access-token`).then(response => {
        if (response) {
            return Promise.resolve(response.data.token);
        } else {
            Promise.reject('Something went wrong getting twillio token');
        }
    });
};

const initializeSync = () => {
    return getTwilioAccessToken().then(token => {
        if (!token) {
            return false;
        }

        let syncClient;
        syncClient = new SyncClient(token, { logLevel: 'info' });

        syncClient.on('connectionStateChanged', function(state) {
            if (state !== 'connected') {
                // eslint-disable-next-line no-console
                console.log('Sync is not live (websocket connection ' + state);
            } else {
                // eslint-disable-next-line no-console
                console.log('Sync is live!');
            }
        });

        syncClient.on('tokenAboutToExpire', function() {
            // Obtain a JWT access token: https://www.twilio.com/docs/sync/identity-and-access-tokens
            getTwilioAccessToken().then(token => {
                syncClient.updateToken(token).catch(err => {
                    // eslint-disable-next-line no-console
                    console.log('There was an error updating token');
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
            });
        });

        return syncClient;
    });
};

const TwilioSyncContext = createContext(null);

export function TwilioSyncProvider({ children }) {
    const isUserLoggedin = useSelector(getIsUserLoggedIn);
    const [client, setClient] = useState();
    const [userFromLogin, setUserFromLogin] = useState(cookie.load('userFromLogin'));

    useEffect(() => {
        if (userFromLogin && isUserLoggedin) {
            initializeSync().then(client => {
                setClient(client);
            });
        }
    }, [userFromLogin, isUserLoggedin]);

    return <TwilioSyncContext.Provider value={{ client, setUserFromLogin }}>{children}</TwilioSyncContext.Provider>;
}

export function useTwilioSyncProvider() {
    const { client, setUserFromLogin } = useContext(TwilioSyncContext);

    useEffect(() => {
        const userFromLogin = cookie.load('userFromLogin');
        if (userFromLogin) {
            setUserFromLogin(userFromLogin);
        }
    }, [setUserFromLogin]);

    return client;
}
