import React, { memo, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { isEmpty, uniq, remove, cloneDeep, pickBy, identity } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { openNoSignatureModal } from '../../../../actions/user';

import { closeDocumentForm, addOrReplaceDocumentInList } from '../../../../actions/document';
import {
    leaseTerminationReasonOptions,
    NSW_STATE,
    QLD_STATE,
    DELIVERY_TO_AGENT_AND_TENANT,
    DELIVERY_TYPE_EMAIL,
    FORM_SUBMIT_TYPE_SEND,
    CLOSE_MODAL_MESSAGE
} from '../../../../config';

import DocumentTenantPersonGroup from '../../../../components/document/DocumentTenantPersonGroup';
import { FormTextMultiline } from '../../../../components/form/FormText';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import { FormTextRegular } from '../../../../components/form/FormText';
import { SelectField } from '../../../../components/form/FormSelect';
import ModalDialog from '../../../../common/components/ModalDialog';
import * as validators from '../../../../components/Validate';

import { getIsOpenTerminationNoticeForm } from '../../../../selectors/document/termination';

import { getAgentSignature } from '../../../../selectors/completion';
import { getUserInfo } from '../../../../selectors/user';

import '../../../../sass/documentModalDialog.scss';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document';
import { parseISO, isValid } from 'date-fns';
import { getAgencyTimezoneDate, getDeliveryDate } from '../../../../utils/dateUtils';
import { isDocumentEditMode, isDocumentReadOnlyMode } from '../../../../utils/generalUtils';
import LocationAndAddressSelection from '../../LocationAndAddressSelection';
import DocumentHeader from '../../DocumentHeader';
import DeliveryTypeComponent from '../../shared/DeliveryTypeComponent';
import TerminationNoticeWithoutLeaseFooter from './TerminationNoticeWithoutLeaseFooter';
import { CloseSharp } from '@material-ui/icons';
import { isLeaseLocationQLD } from '../../../../utils/agreementUtils';
import QldNoticeReason from './QldNoticeReason';
import { values as lodashValues } from 'lodash';

const TERMINATION_API = 'api/document/termination-notice';
const allowedStates = [NSW_STATE, QLD_STATE];

function TerminationNoticeWithoutLeaseForm() {
    const dispatch = useDispatch();
    const formValuesRef = React.useRef(null);

    const isOpenTerminationNoticeForm = useSelector(getIsOpenTerminationNoticeForm);
    const loggedInUser = useSelector(getUserInfo);
    const agentSignature = useSelector(getAgentSignature);
    const currentDocument = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);

    const [isSendingTerminateLease, setIsSendingTerminateLease] = useState(false);
    const [isSendingTerminateLeaseSuccess, setIsSendingTerminateLeaseSuccess] = useState(false);
    const [isSendingTerminateLeaseFail, setIsSendingTerminateLeaseFail] = useState(false);
    const [isCreatingTerminationNotice, setIsCreatingTerminationNotice] = useState(false);

    const [currentDocumentId, setCurrentDocumentId] = useState(currentDocument ? currentDocument.id : null);
    const [location, setLocation] = useState(null);
    const [locationAndAddressSelected, setLocationAndAddressSelected] = useState(false);
    let [addEmailFieldError, setAddEmailFieldError] = useState('');
    let [update, setUpdate] = useState(false);
    let [formData, setFormData] = useState({});

    const [integrationPropertyId, setIntegrationPropertyId] = useState(null);
    const [integration, setIntegration] = useState(null);
    const [addressError, setAddressError] = useState('');

    const [isDirty, setIsDirty] = useState(false);
    const [isSaveDraft, setIsSaveDraft] = useState(false);

    const handleFormDirtyChange = form => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };

    function getResetFormData() {
        setLocation(loggedInUser?.agency?.details?.location || null);
        return {
            location: location,
            agentName: loggedInUser.fullName,
            address: '',
            addressDetails: '',
            tenants: [
                {
                    firstName: '',
                    middleName: '',
                    secondName: '',
                    email: ''
                }
            ],
            emailAddresses: []
        };
    }

    useEffect(() => {
        if (currentDocument && isOpenTerminationNoticeForm) {
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenTerminationNoticeForm]);

    useEffect(() => {
        if (currentDocument && currentDocument.integration && currentDocument.integration.id) {
            setIntegrationPropertyId(currentDocument.integration.id);
            setIntegration(currentDocument.integration);
        } else {
            setIntegrationPropertyId(null);
            setIntegration(null);
        }
        if (currentDocument) {
            setLocation(currentDocument.location);
            setLocationAndAddressSelected(true);
            setCurrentDocumentId(currentDocument.id);
        }
        setFormData(
            currentDocument
                ? {
                      ...currentDocument.terminationWithoutLease,
                      dateOfNotice:
                          currentDocument && currentDocument.terminationWithoutLease.dateOfNotice
                              ? parseISO(currentDocument.terminationWithoutLease.dateOfNotice)
                              : null,
                      saleDate:
                          currentDocument && currentDocument.details.saleDate
                              ? parseISO(currentDocument.details.saleDate)
                              : null,
                      address: currentDocument.address,
                      tenants: currentDocument.tenant.tenants ? currentDocument.tenant.tenants : [],
                      agentName: currentDocument.agent.fullName,
                      addressDetails: currentDocument.addressDetails,
                      vacantPossessionDate:
                          currentDocument && currentDocument.terminationWithoutLease
                              ? parseISO(currentDocument.terminationWithoutLease.vacantPossessionDate)
                              : null,
                      location: currentDocument.location,
                      emailTo:
                          currentDocument.details.deliveryType === DELIVERY_TYPE_EMAIL
                              ? DELIVERY_TO_AGENT_AND_TENANT
                              : null,
                      reasonForTermination: currentDocument.details.reasonForTermination,
                      disabled:
                          (currentDocument.completion || currentDocument.pdf) &&
                          isDocumentReadOnlyMode(documentEditMode),
                      qldNoticeReason: currentDocument.terminationWithoutLease?.qldNoticeReason || {}
                  }
                : getResetFormData()
        );
        if (!currentDocument) {
            setLocationAndAddressSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenTerminationNoticeForm, currentDocument]);

    const closeModal = () => {
        setFormData(getResetFormData());
        setIntegrationPropertyId(null);
        setIntegration(null);
        setCurrentDocumentId(null);
        setIsDirty(false);
        dispatch(closeDocumentForm());
        if (currentDocument) {
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
            );
        }
    };

    const handleCloseModal = () => {
        if (isDirty) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const values = formValuesRef.current;
                            saveDraft(values).then(response => {
                                setIsSendingTerminateLease(false);
                                dispatch(addOrReplaceDocumentInList(response.data.termination));
                            });
                            closeModal();
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            closeModal();
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    const viewPdf = docId => {
        window.open(`/reader/document/termination-notice/${docId}`, '_blank');
    };

    const previewPdf = values => {
        setIsSendingTerminateLease(true);
        setIsSendingTerminateLeaseSuccess(false);
        setIsSendingTerminateLeaseFail(false);
        saveDraft(values).then(response => {
            viewPdf(response.data.termination.id);
            setIsSendingTerminateLease(false);
            setIsDirty(false);
            dispatch(addOrReplaceDocumentInList(response.data.termination));
            setCurrentDocumentId(response.data.termination.id);
        });
    };

    const submitData = values => {
        setIsSendingTerminateLease(true);
        setIsSendingTerminateLeaseSuccess(false);
        setIsSendingTerminateLeaseFail(false);
        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            setIsSaveDraft(false);
            return sendDocument(values);
        } else {
            setIsSaveDraft(true);
            return saveDraft(values).then(response => {
                setIsSendingTerminateLease(false);
                setIsSendingTerminateLeaseSuccess(true);
                dispatch(addOrReplaceDocumentInList(response.data.termination));
                setCurrentDocumentId(response.data.termination.id);
                setIsDirty(false);
            });
        }
    };
    const saveDraft = values => {
        let data = cloneDeep(values);
        if (data.deliveryType !== DELIVERY_TYPE_EMAIL || data.emailTo !== DELIVERY_TO_AGENT_AND_TENANT) {
            data.emailAddresses = [loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        data.integrationPropertyId = integrationPropertyId;
        return axios.post(`${TERMINATION_API}${currentDocumentId ? `/${currentDocumentId}/save` : ''}`, data);
    };

    const sendDocument = values => {
        let data = cloneDeep(values);
        const keyList = [
            'address',
            'deliveryType',
            'reasonForTermination',
            'dateOfNotice',
            'vacantPossessionDate',
            'saleDate',
            'detailsOfBreach',
            'detailsOfGrounds'
        ];
        keyList.forEach(dataKey => {
            if (!data[dataKey]) {
                data[dataKey] = null;
            }
        });
        let tenantError = false;
        data.tenants.forEach(tenant => {
            if (!tenant.firstName) {
                tenant.firstName = null;
                tenantError = true;
            }
            if (!tenant.secondName) {
                tenant.secondName = null;
                tenantError = true;
            }
            if (!tenant.email) {
                tenant.email = null;
                tenantError = true;
            }
        });
        if (
            tenantError ||
            !data.deliveryType ||
            !data.address ||
            !data.dateOfNotice ||
            !data.vacantPossessionDate ||
            (!isLeaseLocationQLD(location) &&
                (!data.reasonForTermination ||
                    (data.reasonForTermination === 'sold' && !data.saleDate) ||
                    (data.reasonForTermination === 'breach' && !data.detailsOfBreach) ||
                    (data.reasonForTermination === 'with_grounds' && !data.detailsOfGrounds)))
        ) {
            setIsSendingTerminateLease(false);
            setFormData(data);
            setUpdate(!update);
            return;
        }
        if (data.deliveryType !== DELIVERY_TYPE_EMAIL || data.emailTo !== DELIVERY_TO_AGENT_AND_TENANT) {
            data.emailAddresses = [loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        if (validateEmailList(data)) {
            if (!isEmpty(agentSignature)) {
                data.integrationPropertyId = integrationPropertyId;
                axios
                    .post(`${TERMINATION_API}${currentDocumentId ? `/${currentDocumentId}/send` : '/send'}`, data)
                    .then(() => {
                        setIsSendingTerminateLease(false);
                        setIsSendingTerminateLeaseSuccess(true);
                        setIsSendingTerminateLeaseFail(false);
                        setIsDirty(false);
                        closeModal(false);
                    })
                    .catch(() => {
                        setIsSendingTerminateLease(false);
                        setIsSendingTerminateLeaseSuccess(false);
                        setIsSendingTerminateLeaseFail(true);
                    });
            } else {
                setIsSendingTerminateLease(false);
                dispatch(openNoSignatureModal());
            }
        } else {
            setIsSendingTerminateLease(false);
        }
    };

    const emailAgentAndTenantHandler = (values, setValue) => {
        values.emailTo = DELIVERY_TO_AGENT_AND_TENANT;
        let emailAddresses = [];
        values.tenants.forEach(tenant => {
            if (tenant.email && validateEmail(tenant.email)) {
                emailAddresses.push(tenant.email);
            }
        });
        values.emailAddresses = uniq(emailAddresses);
        values.ccEmailAddresses = [loggedInUser.email];
        // set form data
        setValue('emailAddresses', values.emailAddresses);
        setValue('ccEmailAddresses', values.ccEmailAddresses);
        // update redux store also
        setFormData(values);
    };

    const validateEmail = value => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }
        setAddEmailFieldError(undefined);
        return true;
    };

    const validateEmailList = values => {
        if (values.deliveryType === DELIVERY_TYPE_EMAIL) {
            if (values.emailAddresses.length === 0) {
                setAddEmailFieldError('Please enter at least one email address');
                return false;
            }
        }
        setAddEmailFieldError('');
        return true;
    };
    const addTenantPerson = values => {
        let data = cloneDeep(values);
        const newTenant = { firstName: '', middleName: '', secondName: '', email: '' };
        if (data.tenants) {
            data.tenants.push(newTenant);
        } else {
            data.tenants = [newTenant];
        }
        setUpdate(!update);
        setFormData(data);
    };
    const removeTenantPerson = (index, values, disabled) => {
        if (!disabled) {
            let data = cloneDeep(values);
            let emailAddresses = remove(data.emailAddresses, function (n) {
                return n !== data.tenants[index].email;
            });
            data.tenants = [...data.tenants.slice(0, index), ...data.tenants.slice(index + 1)];
            data.emailAddresses = emailAddresses;
            setUpdate(!update);
            setFormData(data);
        }
    };
    const handleAddressChange = (setValue, address) => {
        setValue('address', address);
    };
    const setUpdateAddressDetails = (address, details, values) => {
        if (!address) {
            setAddressError('Address is required');
        } else {
            setAddressError('');
        }
        let data = cloneDeep(values);
        data.address = address;
        data.location = location;
        data.addressDetails = details;
        setFormData(data);
        setIntegrationPropertyId(null);
        setIntegration(null);
    };
    const onChangeEmail = (value, index, values, setValue) => {
        // change email list only email to is agentAndTenant
        if (values.emailTo === DELIVERY_TO_AGENT_AND_TENANT) {
            // set changed value to form values
            values.tenants[index].email = value.target.value;
            emailAgentAndTenantHandler(values, setValue);
        }
    };
    const confirmDetails = () => {
        const values = formValuesRef.current;
        setIsCreatingTerminationNotice(true);
        saveDraft(values)
            .then(response => {
                setCurrentDocumentId(response.data.termination.id);
                setIsDirty(false);
                dispatch(addOrReplaceDocumentInList(response.data.termination));
                setLocationAndAddressSelected(true);
            })
            .finally(() => {
                setIsCreatingTerminationNotice(false);
            });
    };
    const setAddressDetails = (address, details, values) => {
        let data = cloneDeep(values);
        data.address = address;
        data.location = location;
        data.addressDetails = details;
        setIntegrationPropertyId(null);
        setIntegration(null);
        setFormData(data);
    };
    const setIntegrationProperty = propertyDetails => {
        if (propertyDetails) {
            setFormData({
                location: location,
                address: propertyDetails.address,
                agentName: loggedInUser.fullName,
                tenants: propertyDetails.tenants
            });
            setIntegrationPropertyId(propertyDetails.id);
            setIntegration(propertyDetails);
        } else {
            setFormData(getResetFormData());
        }
    };

    const setSelectedDate = values => {
        values.dateOfNotice = getDeliveryDate(values.dateOfNotice, loggedInUser, values.deliveryType);
        return values.dateOfNotice;
    };

    return (
        <React.Fragment>
            <ModalDialog
                title="Notice of termination"
                isOpen={isOpenTerminationNoticeForm}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={`document-centre-modal document-modal-dialog tenant ${
                    locationAndAddressSelected && location ? 'with-header' : 'without-header'
                }`}
                hideCloseButton={isSendingTerminateLease}
                // Quick fix for search results being cut off by modal
                allowOverflow={!locationAndAddressSelected}
            >
                <Form
                    initialValues={formData}
                    onSubmit={values => {
                        submitData(values);
                    }}
                    validate={({
                        vacantPossessionDate,
                        deliveryType,
                        reasonForTermination,
                        detailsOfBreach,
                        saleDate,
                        detailsOfGrounds,
                        deliveryTypeDetails,
                        dateOfNotice,
                        emailTo,
                        address,
                        tenants,
                        submitType,
                        qldNoticeReason
                    }) => {
                        if (submitType === FORM_SUBMIT_TYPE_SEND) {
                            let qldNoticeReasonError;
                            if (isLeaseLocationQLD(location)) {
                                qldNoticeReasonError = lodashValues(qldNoticeReason).some(i => i === true)
                                    ? undefined
                                    : 'At lease one or more reasons for termination is required';
                            }
                            let reasonForTerminationMessage;
                            if (!isLeaseLocationQLD(location)) {
                                reasonForTerminationMessage =
                                    reasonForTermination === null ? 'Reason is required' : undefined;
                            }
                            const clientSideErrors = {
                                address: address === null ? 'Address is required' : undefined,
                                vacantPossessionDate: !isValid(vacantPossessionDate)
                                    ? 'Delivery date of vacant possession is required'
                                    : undefined,
                                deliveryType: validators.isEmptyString(deliveryType)
                                    ? 'Delivery type is required'
                                    : undefined,
                                reasonForTermination: reasonForTerminationMessage,
                                deliveryTypeDetails:
                                    deliveryType && deliveryType === 'other' && !deliveryTypeDetails
                                        ? 'Details of the delivery type is required'
                                        : undefined,
                                detailsOfBreach:
                                    reasonForTermination &&
                                    reasonForTermination === 'breach' &&
                                    detailsOfBreach === null
                                        ? 'Details of the breach is required'
                                        : undefined,
                                saleDate:
                                    reasonForTermination && reasonForTermination === 'sold' && saleDate === null
                                        ? 'Sale date is required'
                                        : undefined,
                                detailsOfGrounds:
                                    reasonForTermination &&
                                    reasonForTermination === 'with_grounds' &&
                                    detailsOfGrounds === null
                                        ? 'Details of the grounds is required'
                                        : undefined,
                                dateOfNotice: dateOfNotice === null ? 'Date of notice is required' : undefined,
                                emailTo:
                                    deliveryType && deliveryType === DELIVERY_TYPE_EMAIL && !emailTo
                                        ? 'Please select the receiver of the termination email'
                                        : undefined,
                                qldNoticeReason: {
                                    error: qldNoticeReasonError
                                }
                            };
                            let tenantsError = [];
                            tenants &&
                                tenants.forEach(tenant => {
                                    const tenantObject = {
                                        firstName:
                                            validators.isEmptyString(tenant.firstName) ||
                                            !validators.isCorrectNameOrSurname(tenant.firstName)
                                                ? 'First name is required'
                                                : undefined,
                                        secondName:
                                            validators.isEmptyString(tenant.secondName) ||
                                            !validators.isCorrectNameOrSurname(tenant.secondName)
                                                ? 'Last name is required'
                                                : undefined,
                                        email: !validateEmail(tenant.email) ? 'Email is required' : undefined
                                    };
                                    if (!isEmpty(tenantObject)) {
                                        tenantsError.push(tenantObject);
                                    }
                                });
                            clientSideErrors.tenants = isEmpty(tenantsError) ? undefined : tenantsError;

                            // We should clear successfully save message or error message before show validations
                            if (!isEmpty(pickBy(clientSideErrors, identity))) {
                                setIsSendingTerminateLeaseSuccess(false);
                                setIsSendingTerminateLeaseFail(false);
                            }

                            return clientSideErrors;
                        } else {
                            return {};
                        }
                    }}
                >
                    {({ handleSubmit, values, form }) => {
                        // set the values to the ref so that the close modal can access these values
                        formValuesRef.current = values;
                        return (
                            <form onSubmit={handleSubmit} noValidate id="create-termination-form">
                                <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                                {locationAndAddressSelected && location && (
                                    <div className="terminate-lease">
                                        <DocumentHeader
                                            parentSelector="create-termination-form"
                                            confirmDetails={confirmDetails}
                                            address={values.address}
                                            addressError={addressError}
                                            handleAddressChange={address => handleAddressChange(form.change, address)}
                                            setAddressDetails={(address, details) =>
                                                setUpdateAddressDetails(address, details, values)
                                            }
                                            disabled={isDocumentReadOnlyMode(documentEditMode) || integrationPropertyId}
                                            agentName={values.agentName}
                                            integration={integration}
                                            viewOnlyIntegration={true}
                                            doc={currentDocument}
                                        />
                                        <div>
                                            <div className="tenants">
                                                {formData &&
                                                    formData.tenants &&
                                                    formData.tenants.map((tenant, index) => (
                                                        <DocumentTenantPersonGroup
                                                            key={index}
                                                            index={index}
                                                            removeTenant={index =>
                                                                removeTenantPerson(index, values, values.disabled)
                                                            }
                                                            showHeader={index === 0}
                                                            onChangeEmail={(value, index) =>
                                                                onChangeEmail(value, index, values, form.change)
                                                            }
                                                            disabled={values.disabled}
                                                        />
                                                    ))}
                                                {isDocumentEditMode(documentEditMode) && (
                                                    <div className="button">
                                                        <button
                                                            className="add-tenant"
                                                            type="button"
                                                            onClick={() => addTenantPerson(values)}
                                                        >
                                                            Add tenant
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            {!isLeaseLocationQLD(location) && (
                                                <SelectField
                                                    label="Reason for termination"
                                                    name="reasonForTermination"
                                                    required
                                                    options={leaseTerminationReasonOptions[location]}
                                                    disabled={values.disabled}
                                                />
                                            )}
                                            {isLeaseLocationQLD(location) && (
                                                <QldNoticeReason form={form} disabled={values.disabled} />
                                            )}
                                            {values.reasonForTermination &&
                                                values.reasonForTermination === 'breach' && (
                                                    <FormTextMultiline
                                                        name="detailsOfBreach"
                                                        label="Details of the breach"
                                                        required
                                                        disabled={values.disabled}
                                                        isChatGpt={true}
                                                        gpt_fieldName="detailsOfBreach"
                                                        gpt_isDocument={true}
                                                        gpt_documentId={currentDocumentId}
                                                    />
                                                )}
                                            {values.reasonForTermination && values.reasonForTermination === 'sold' && (
                                                <div className="calendar">
                                                    <DatePickerInAgencyTimeZone
                                                        label="Sale Date"
                                                        name="saleDate"
                                                        required
                                                        selected={values.saleDate}
                                                        disabled={values.disabled}
                                                    />
                                                </div>
                                            )}
                                            {values.reasonForTermination &&
                                                values.reasonForTermination === 'with_grounds' && (
                                                    <FormTextMultiline
                                                        name="detailsOfGrounds"
                                                        label="Details of the grounds"
                                                        disabled={values.disabled}
                                                        isChatGpt={true}
                                                        gpt_fieldName="detailsOfGrounds"
                                                        gpt_isDocument={true}
                                                        gpt_documentId={currentDocumentId}
                                                    />
                                                )}
                                            <div className="calendar">
                                                <DatePickerInAgencyTimeZone
                                                    label="The tenant will deliver vacant possession of the premises on:"
                                                    name="vacantPossessionDate"
                                                    required
                                                    selected={values.vacantPossessionDate}
                                                    minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                                    disabled={values.disabled}
                                                />
                                            </div>
                                            <DeliveryTypeComponent
                                                emailAgentAndTenantHandler={emailAgentAndTenantHandler}
                                                addEmailFieldError={addEmailFieldError}
                                                values={values}
                                                form={form}
                                            />
                                            <div className="calendar">
                                                <DatePickerInAgencyTimeZone
                                                    label="Date of the notice:"
                                                    name="dateOfNotice"
                                                    required
                                                    selected={setSelectedDate(values)}
                                                    minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                                    disabled={values.disabled || values.deliveryType === 'email'}
                                                    popperPlacement="top-start"
                                                />
                                            </div>
                                            <br />
                                            <TerminationNoticeWithoutLeaseFooter
                                                previewPdf={() => previewPdf(values)}
                                                viewPdf={viewPdf}
                                                form={form}
                                                isSendingTerminateLease={isSendingTerminateLease}
                                                isSendingTerminateLeaseSuccess={isSendingTerminateLeaseSuccess}
                                                isSendingTerminateLeaseFail={isSendingTerminateLeaseFail}
                                                isSaveDraft={isSaveDraft}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!locationAndAddressSelected && (
                                    <LocationAndAddressSelection
                                        location={location}
                                        allowedStates={allowedStates}
                                        setLocation={setLocation}
                                        closeModal={closeModal}
                                        confirmDetails={confirmDetails}
                                        address={values.address}
                                        isCreating={isCreatingTerminationNotice}
                                        handleAddressChange={address => handleAddressChange(form.change, address)}
                                        setAddressDetails={(address, details) =>
                                            setAddressDetails(address, details, values)
                                        }
                                        setIntegrationProperty={property => setIntegrationProperty(property)}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        </React.Fragment>
    );
}
export default memo(TerminationNoticeWithoutLeaseForm);
