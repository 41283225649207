import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getPmOutgoings } from '../../../selectors/lease/pmLease';

import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';

import '../../../sass/outgoings.scss';
import plusIcon from '../../../../assets/images/icons/plus.svg';
import { OutgoingGroup } from '../../../components/lease/mainScreen/common/pm/OutgoingGroup';
import { QLD_STATE, VIC_STATE, LEASE_TYPE_PROPERTY_MANAGEMENT } from '../../../config';
import { getLabel } from '../../../utils/labelUtils';

const PM_OUTGOINGS_FORM = 'pmOutgoingsForm';
const initState = {
    councilRates: {
        enabled: false
    },
    waterSewerage: {
        enabled: false
    },
    landTax: {
        enabled: false
    },
    insurancePremiums: {
        enabled: false
    },
    titleLevies: {
        enabled: false
    },
    invoicesForRepairsAndMaintenance: {
        enabled: false
    },
    otherOutgoings: []
};

const PmOutgoings = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmOutgoings = useSelector(getPmOutgoings);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmOutgoings, setPmOutgoings] = useState(reduxPmOutgoings || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_OUTGOINGS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmOutgoings(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmOutgoings')) {
                    return error.response.data.errors.pmOutgoings;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmOutgoings = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-outgoings`, data);
    };

    const addOtherOutgoing = values => {
        let newOutgoings = cloneDeep(values);
        // This ID is used on the phone side to id question, sequence not important just uniqueness
        let newId = 'IO1';
        if (newOutgoings.otherOutgoings && newOutgoings.otherOutgoings.length > 0) {
            const lastId = newOutgoings.otherOutgoings.slice(-1)[0].outGoingId;
            const lastChar = lastId.substring(lastId.length - 1);
            newId = 'IO' + (parseInt(lastChar) + 1);
        }

        if (newOutgoings.otherOutgoings) {
            newOutgoings.otherOutgoings.push({
                outGoingDescription: '',
                enabled: true,
                outGoingId: newId
            });
        } else {
            newOutgoings.otherOutgoings = [];
            newOutgoings.otherOutgoings.push({
                outGoingDescription: '',
                enabled: true,
                outGoingId: newId
            });
        }
        setPmOutgoings(newOutgoings);
    };

    const removeOtherOutgoing = (values, index) => {
        let newOutgoings = cloneDeep(values);

        newOutgoings.otherOutgoings = [
            ...newOutgoings.otherOutgoings.slice(0, index),
            ...newOutgoings.otherOutgoings.slice(index + 1)
        ];
        setPmOutgoings(newOutgoings);
    };

    return (
        <div className="form-container outgoings mobile-multi-select-wrapper">
            <Form
                onSubmit={submitForm}
                initialValues={pmOutgoings}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({ handleSubmit, form, values }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_OUTGOINGS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {location !== VIC_STATE && (
                                <p className="text">
                                    {`What outgoing payments would you like the ${getLabel(
                                        'landlord',
                                        location,
                                        LEASE_TYPE_PROPERTY_MANAGEMENT
                                    )} to give you authorisation on?`}
                                </p>
                            )}
                            <CheckboxCheck
                                name="councilRates.enabled"
                                label="Council Rates"
                                className="councilRates mobile-multi-select-enabled"
                            />
                            <CheckboxCheck
                                name="waterSewerage.enabled"
                                label="Water / Sewerage Charges"
                                className="waterSewerage mobile-multi-select-enabled"
                            />
                            <CheckboxCheck
                                name="landTax.enabled"
                                label="Land Tax"
                                className="landTax mobile-multi-select-enabled"
                            />
                            <CheckboxCheck
                                name="insurancePremiums.enabled"
                                label="Insurance Premiums"
                                className="insurancePremiums mobile-multi-select-enabled"
                            />
                            <CheckboxCheck
                                name="titleLevies.enabled"
                                label="Strata Title / Community Title Levies"
                                className="titleLevies mobile-multi-select-enabled"
                            />
                            {location === QLD_STATE && (
                                <CheckboxCheck
                                    name="invoicesForRepairsAndMaintenance.enabled"
                                    label="Invoices for Repairs and Maintenance"
                                    className="invoicesForRepairsAndMaintenance mobile-multi-select-enabled"
                                />
                            )}
                            <FieldArray name="otherOutgoings" initialValue={pmOutgoings.otherOutgoings}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <OutgoingGroup
                                                    index={index}
                                                    key={`${index}`}
                                                    removeOtherOutgoing={index => removeOtherOutgoing(values, index)}
                                                />
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button add-item-button-container">
                                <button
                                    className="mobile add-item"
                                    type="button"
                                    onClick={() => addOtherOutgoing(values)}
                                >
                                    <img src={plusIcon} className="for-sm-modal" />
                                    Add outgoing
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(PmOutgoings);
