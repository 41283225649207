import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HARNESS_IO_ANON_USER } from '../../constants/constants';
import { omit } from 'lodash';
import { extractUserAndAgencyInfo } from '../../utils/vendorUtils';
import { getUserInfo as userInfoSelector } from '../../selectors/user';
import { getAgency } from '../../selectors/settings/account';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk';

export function HarnessProvider({ children }) {
    const userInfo = useSelector(userInfoSelector);
    const [harnessIOTarget, setHarnessIOTarget] = useState({ identifier: HARNESS_IO_ANON_USER });
    const agencyPopulated = useSelector(getAgency);
    useEffect(() => {
        if (userInfo?.id && agencyPopulated?.id) {
            const user = extractUserAndAgencyInfo(userInfo, agencyPopulated);
            // set harness.io target, omit phone as that should never be needed
            const customUserAttributes = omit(user, ['key', 'name', 'email', 'country', 'phone']);
            setHarnessIOTarget({ name: user.name, identifier: user.key, attributes: customUserAttributes });
        }
    }, [userInfo, agencyPopulated]);

    return (
        <FFContextProvider async apiKey={HARNESS_IO_API_KEY} target={harnessIOTarget}>
            {children}
        </FFContextProvider>
    );
}
