import React, { memo } from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import '../../sass/react-tabs.scss';
import HouseIcon from '@material-ui/icons/HouseSharp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnSharp';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterSharp';

import DefaultDocumentLinks from './documents/DefaultDocumentLinks';
import DefaultDocuments from './documents/DefaultDocuments';
import {
    LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
    LEASE_TYPE_RESIDENTIAL_DISPLAY,
    LEASE_TYPE_SALES_DISPLAY,
    LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY,
    LEASE_TYPE_HOLIDAY_LETTING_DISPLAY
} from './../../config';
import Icon, { Icons } from '../../common/components/Icon';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { NSW_HOLIDAY_LETTING } from '../../constants/featureFlags';

const AgencyDefaultDocumentsTabs = () => {
    const isNswHolidayLettingActive = useFeatureFlag(NSW_HOLIDAY_LETTING);
    return (
        <div className="settings">
            <Tabs>
                <TabList className="react-tabs__tab-list auto-width">
                    <Tab>
                        <HouseIcon>Residential Tenancy</HouseIcon>Residential Tenancy
                    </Tab>
                    <Tab>
                        <MonetizationOnIcon>Sales</MonetizationOnIcon>Sales
                    </Tab>
                    <Tab>
                        <BusinessCenterIcon>Property Management</BusinessCenterIcon>Property management
                    </Tab>
                    <Tab>
                        <BusinessCenterIcon>Commercial Lease</BusinessCenterIcon>Commercial Lease
                    </Tab>
                    {isNswHolidayLettingActive && (
                        <Tab>
                            <Icon icon={Icons.HOLIDAY_HOUSE} /> Holiday Letting
                        </Tab>
                    )}
                </TabList>

                <TabPanel>
                    <React.Fragment>
                        <div className="wrapper">
                            <div className="sideLeft sideLeft-wide">
                                <DefaultDocuments leaseType={LEASE_TYPE_RESIDENTIAL_DISPLAY} />
                            </div>
                            <div className="sideRight sideRight-wide">
                                <DefaultDocumentLinks leaseType={LEASE_TYPE_RESIDENTIAL_DISPLAY} />
                            </div>
                        </div>
                    </React.Fragment>
                </TabPanel>
                <TabPanel>
                    <div className="wrapper">
                        <div className="sideLeft sideLeft-wide">
                            <DefaultDocuments leaseType={LEASE_TYPE_SALES_DISPLAY} />
                        </div>
                        <div className="sideRight sideRight-wide">
                            <DefaultDocumentLinks leaseType={LEASE_TYPE_SALES_DISPLAY} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="wrapper">
                        <div className="sideLeft sideLeft-wide">
                            <DefaultDocuments leaseType={LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY} />
                        </div>
                        <div className="sideRight sideRight-wide">
                            <DefaultDocumentLinks leaseType={LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="wrapper">
                        <div className="sideLeft sideLeft-wide">
                            <DefaultDocuments leaseType={LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY} />
                        </div>
                        <div className="sideRight sideRight-wide">
                            <DefaultDocumentLinks leaseType={LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="wrapper">
                        <div className="sideLeft sideLeft-wide">
                            <DefaultDocuments leaseType={LEASE_TYPE_HOLIDAY_LETTING_DISPLAY} />
                        </div>
                        <div className="sideRight sideRight-wide">
                            <DefaultDocumentLinks leaseType={LEASE_TYPE_HOLIDAY_LETTING_DISPLAY} />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default memo(AgencyDefaultDocumentsTabs);
