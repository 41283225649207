import React, { memo, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getValidationErrors, getLeaseType } from '../../../selectors/lease';
import { getPmLandlord } from '../../../selectors/lease/pmLease';

import LandlordPersonGroup from '../../../components/lease/mainScreen/common/pm/LandlordPersonGroup';
import '../../../sass/signatory.scss';
import plusIcon from '../../../../assets/images/icons/plus.svg';
import { getLabel } from '../../../utils/labelUtils';
import { LEASE_TYPE_PROPERTY_MANAGEMENT } from '../../../config';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';

const maxLandlords = 6;
const PM_LANDLORD_FORM = 'pmLandlordForm';

const initState = {
    persons: [{ firstName: '', secondName: '', isPrimaryLandlord: true }]
};

const Landlord = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const validationErrors = useSelector(getValidationErrors);
    const reduxPmLandlordReducer = useSelector(getPmLandlord);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmLandlord, setPmLandlord] = useState(reduxPmLandlordReducer || initState);

    useEffect(() => {
        if (!pmLandlord?.persons?.length > 0) {
            setPmLandlord({
                ...pmLandlord,
                persons: initState.persons
            });
        }
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_LANDLORD_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const removeLandlordPerson = (values, index) => {
        let newState = cloneDeep(values);
        if (newState.persons[index].isPrimaryLandlord) {
            newState.persons[0].isPrimaryLandlord = true;
        }
        setPmLandlord({
            ...newState,
            persons: [...newState.persons.slice(0, index), ...newState.persons.slice(index + 1)]
        });
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        let pmLandlordValues = cloneDeep(values);
        return updatePmLandlord(pmLandlordValues)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmLandlord')) {
                    return error.response.data.errors.pmLandlord;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmLandlord = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-landlord`, data);
    };

    const handlePrimaryLandlordSelect = (values, index, value) => {
        let newState = cloneDeep(values);
        let length = newState.persons.length;
        if (!value) {
            newState.persons[0].isPrimaryLandlord = true;
            if (index !== 0) {
                newState.persons[index].isPrimaryLandlord = false;
            }
        } else {
            for (let i = 0; i < length; i++) {
                newState.persons[i].isPrimaryLandlord = index === i;
            }
        }
        setPmLandlord({
            ...newState
        });
    };

    return (
        <div className="signatory">
            <Form
                onSubmit={submitForm}
                initialValues={pmLandlord}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    form: {
                        mutators: { push }
                    },
                    values
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_LANDLORD_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="persons">
                                {validationErrors &&
                                    validationErrors.pmLandlord &&
                                    validationErrors.pmLandlord.isPrimaryLandlord && (
                                        <p className="FormInput">
                                            <span className="FormError">
                                                {validationErrors.pmLandlord.isPrimaryLandlord}
                                            </span>
                                        </p>
                                    )}
                                <FieldArray name="persons" initialValue={pmLandlord.persons}>
                                    {({ fields }) => (
                                        <React.Fragment>
                                            {fields.map((name, index) => {
                                                return (
                                                    <LandlordPersonGroup
                                                        push={push}
                                                        key={index}
                                                        index={index}
                                                        landlord={fields.value[index]}
                                                        location={location}
                                                        removePerson={index => removeLandlordPerson(values, index)}
                                                        handlePrimaryLandlordSelect={(index, value) => {
                                                            handlePrimaryLandlordSelect(values, index, value);
                                                        }}
                                                        form={form}
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </div>
                            <div className="button">
                                {pmLandlord && pmLandlord.persons && pmLandlord.persons.length >= maxLandlords ? (
                                    <div className="add-landlord">
                                        <p>A maximum of six landlords may be added to the agreement</p>
                                    </div>
                                ) : (
                                    <button
                                        className="mobile add-item"
                                        type="button"
                                        onClick={() =>
                                            push('persons', {
                                                firstName: '',
                                                secondName: '',
                                                isPrimaryLandlord: false
                                            })
                                        }
                                    >
                                        <img src={plusIcon} className="for-sm-modal" />
                                        {`Add ${getLabel('tenantLower', location, LEASE_TYPE_PROPERTY_MANAGEMENT)}`}
                                    </button>
                                )}
                            </div>
                            <CheckboxCheck
                                name="propertyManagementIdentificationRequired"
                                label={getLabel('identityImages', location, LEASE_TYPE_PROPERTY_MANAGEMENT)}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Landlord);
