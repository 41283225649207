import React from 'react';
import { useDispatch } from 'react-redux';
import '../../../../../sass/dashboardContentTable.scss';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import AgentLogo from '../../../../AgentLogo';
import { openDocumentForm } from '../../../../../actions/document';
import { DOCUMENT_CUSTOM, DOCUMENT_EDIT_MODE, DOCUMENT_READ_ONLY_MODE } from '../../../../../config';
import * as dashboard from '../../../../../actions/dashboard';
import FlowDocumentsMoreOptionBlock from '../../cells/FlowDocumentsMoreOptionBlock';
import { getGeneralAppLabel } from '../../../../../utils/labelUtils';
import { useAccountProvider } from '../../../../providers/AccountProvider';
import {
    BULK_SEND_APPROVAL_STATUS_APPROVED,
    BULK_SEND_APPROVAL_STATUS_DECLINED,
    BULK_SEND_APPROVAL_STATUS_PENDING
} from '../../../../../constants/constants';
import { ReactComponent as EmailImage } from '../../../../../../assets/images/receive-mail.svg';

const CustomDocument = ({ documentList }) => {
    const dispatch = useDispatch();
    const accountType = useAccountProvider();

    function openDocumentFormView(event, doc) {
        event.stopPropagation();
        if (doc.status === dashboard.LEASE_STATUS_DRAFT) {
            dispatch(openDocumentForm(DOCUMENT_CUSTOM, doc, DOCUMENT_EDIT_MODE));
        } else {
            dispatch(openDocumentForm(DOCUMENT_CUSTOM, doc, DOCUMENT_READ_ONLY_MODE));
        }
    }

    function getApprovalStatus(doc) {
        if (doc.requiresApproval) {
            let className;
            let approvalStatus = doc.bulkSendApprovalStatus;
            switch (approvalStatus) {
                case BULK_SEND_APPROVAL_STATUS_PENDING:
                    className = 'icon-document-draft';
                    break;
                case BULK_SEND_APPROVAL_STATUS_APPROVED:
                    className = 'icon-document-sent';
                    break;
                case BULK_SEND_APPROVAL_STATUS_DECLINED:
                    className = 'icon-document-error';
            }
            return <span className={className}>{approvalStatus}</span>;
        }
        return null;
    }

    return (
        <Table className="rent-reduction-table">
            <Thead>
                <Th column="agent">{getGeneralAppLabel('agent', accountType)}</Th>
                <Th column="isEmailDraft">&nbsp;</Th>
                <Th column="name">Signers name</Th>
                <Th column="docTitle">Document title</Th>
                <Th column="confirmationType">Confirmation type</Th>
                <Th column="deliveryType">Delivery type</Th>
                <Th column="approvalStatus">Approval Status</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agent">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="isEmailDraft">
                                {doc.createdViaEmail ? <EmailImage className="email-icon" src={EmailImage} /> : ''}
                            </Td>
                            <Td column="name">
                                <b>{doc?.client?.clientsListText || '-'}</b>
                            </Td>
                            <Td column="docTitle">
                                <b>{doc.documentTitle ? doc.documentTitle : '-'}</b>
                            </Td>
                            <Td column="confirmationType">
                                <b className="upperCase">{doc.confirmationType ? doc.confirmationType : '-'}</b>
                            </Td>
                            <Td column="deliveryType">
                                <b className="upperCase">{doc?.deliveryType || '-'}</b>
                            </Td>
                            <Td column="approvalStatus">
                                <div className="approval-status">{getApprovalStatus(doc)} </div>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <FlowDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        leaseType={doc.docType}
                                        doc={doc}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default CustomDocument;
