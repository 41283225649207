import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './../reducers';
import promiseMiddleware from './../middleware/promiseMiddleware';
import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';

// logger outputs REDUX action calls to console
const logger = createLogger({ duration: true, collapsed: true });

/**
 * Create and configure redux store instance.
 * @return {Store<S>}
 */
export default function configureStore() {
    let composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

    if (process.env.NODE_ENV !== 'production') {
        composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
    }
    return createStore(reducers, composeEnhancers(applyMiddleware(promiseMiddleware, ReduxThunk)));
}
