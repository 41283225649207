import React, { Component } from 'react';
import { Form, FormSpy } from 'react-final-form';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';

import Button from '../../../common/components/Button';

import '../../../sass/createNewLease.scss';
import { customSelectStyle } from '../../../utils/formUtils';
import { connect } from 'react-redux';
import { get, map, debounce, includes, isEmpty } from 'lodash';
import { getAvailableStates } from '../../../config';
import { bindActionCreators } from 'redux';
import { mappingsToBackend } from '../../../utils/agreementUtils';

import nswImage from '../../../../assets/images/content/NSW.svg';
import qldImage from '../../../../assets/images/content/QLD.svg';
import vicImage from '../../../../assets/images/content/VIC.svg';
import actImage from '../../../../assets/images/content/ACT.svg';
import saImage from '../../../../assets/images/content/SA.svg';
import { FormTextRegular } from '../../../components/form/FormText';
import { AsyncSelect } from '../../../components/form/AsyncSelect';
import axios from 'axios';
import logger from '../../../utils/logger';

const stateImagesMap = {
    NSW: nswImage,
    QLD: qldImage,
    VIC: vicImage,
    ACT: actImage,
    SA: saImage
};

const formName = 'createTemplateForm';

class CreateNewTemplate extends Component {
    constructor(props) {
        super(props);
        this.changeForm = debounce(this.changeForm.bind(this), 500);
        this.state = {
            [formName]: {
                templateName: ''
            },
            addressSet: false,
            showStates: false,
            selectedPropertyId: null,
            creatingTemplate: false,
            cloneDocument: null,
            searchQuery: ''
        };
    }
    NoOptionsMessage = props => {
        return (
            <div className="no-option-message" {...props}>
                {this.state.searchQuery
                    ? this.state.searchQuery.length < 3
                        ? 'Your search is too short, keep typing'
                        : 'No result found for your input'
                    : 'Please type to search templates and agreements'}
            </div>
        );
    };

    componentDidMount() {
        const allowedStates = this.getAllowedStates(this.props.leaseType);
        const defaultLocation = this.getDefaultLocation(this.props.leaseType);
        this.setState({
            allowedStates: allowedStates,
            createTemplateForm: {
                ...this.state.createTemplateForm,
                location: this.props.cloneTemplate ? this.props.cloneTemplate.location : defaultLocation
            },
            cloneDocument: this.props.cloneTemplate
        });
    }

    setAddressDetails(address, details) {
        this.setState({
            addressSet: true,
            createTemplateForm: { ...this.state.createTemplateForm, ...details, address: address }
        });
    }

    getDefaultLocation(leaseType) {
        let location = get(this.props, 'agency.details.location', 'NSW');
        // if agency default location not allowed, then get back NSW state
        if (!includes(getAvailableStates(leaseType), location)) {
            location = 'NSW';
        }
        return location;
    }

    selectState = state => {
        this.setState({
            [formName]: {
                ...this.state.createTemplateForm,
                location: state
            },
            showStates: false,
            cloneDocument: null
        });
    };

    toggleShowStates() {
        this.setState({
            showStates: !this.state.showStates
        });
    }

    createDocument = data => {
        const leaseType = this.props.leaseType;
        const newData = {
            ...data,
            leaseType: leaseType,
            cloneDocument: this.state.cloneDocument
        };
        this.props.createDocument(newData);
    };

    changeForm = form => {
        this.setState({
            createTemplateForm: form.values
        });
    };

    getAllowedStates(leaseType) {
        return getAvailableStates(leaseType);
    }

    getTitle(leaseType) {
        let type = `${mappingsToBackend[leaseType]} Template`;
        return (
            <h1>
                Create new <span className="text">{type}</span> for
            </h1>
        );
    }
    inputChange = searchQuery => {
        this.setState({
            searchQuery
        });
    };

    // handle selection
    handleChange = value => {
        this.setState({
            cloneDocument: value
        });
    };

    // load options using API call
    loadOptions = (inputValue, callback) => {
        if (inputValue && inputValue.length > 2) {
            return axios
                .get(
                    `/api/template/search?query=${inputValue}&location=${this.state.createTemplateForm.location}&leaseType=${this.props.leaseType}`
                )
                .then(res => {
                    return res.data.searchResult;
                })
                .catch(error => {
                    logger.push({
                        status: 'error',
                        env: ENVIRONMENT,
                        message: 'Something went wrong getting agreements and templates',
                        error_message: error.message,
                        errorObj: error
                    });
                });
        }
        return callback([]);
    };
    render() {
        const { closeAgreementTemplateForm, createTemplateError } = this.props;

        const { createTemplateForm, allowedStates } = this.state;
        const leaseType = this.props.leaseType ? this.props.leaseType : this.state.leaseType;
        const creatingTemplate = this.state.creatingTemplate;
        let disableButton = false;
        const templateName = this.state[formName].templateName;
        disableButton = !templateName;

        return (
            <div className="container">
                {this.getTitle(leaseType)}
                <div className="create-newLease">
                    <Form onSubmit={values => this.createDocument(values)} initialValues={createTemplateForm}>
                        {({ handleSubmit, values, form }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <FormSpy onChange={state => this.changeForm(state)} />
                                    <div className="column-box">
                                        <div className={`state-image`}>
                                            <img src={stateImagesMap[values.location]} />
                                        </div>
                                        <div className="select-state-container">
                                            <div className="selected-location">{values.location}</div>
                                            <button
                                                type="button"
                                                className="button-change-state"
                                                onClick={() => this.toggleShowStates()}
                                            >
                                                Change state
                                                <ArrowDropDownSharpIcon className="Select-arrow" />
                                            </button>
                                            {this.state.showStates && (
                                                <div className={'showStates'}>
                                                    <div className="state-dropdown">
                                                        {map(allowedStates, state => {
                                                            return (
                                                                <p
                                                                    key={state}
                                                                    className="state-dropdown-item"
                                                                    onClick={() => this.selectState(state)}
                                                                >
                                                                    {state}
                                                                </p>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <FormTextRegular name="templateName" label="Template name" />
                                    <AsyncSelect
                                        defaultOptions
                                        isClearable={true}
                                        value={this.state.cloneDocument}
                                        styles={customSelectStyle}
                                        loadOptions={this.loadOptions}
                                        onChange={this.handleChange}
                                        onInputChange={this.inputChange}
                                        selectClassName="async-select"
                                        placeholder="Clone from..."
                                        components={{
                                            NoOptionsMessage: this.NoOptionsMessage
                                        }}
                                    />

                                    <div className="create-new-lease-button-container">
                                        <div className="button-cancel">
                                            <span onClick={closeAgreementTemplateForm}>Cancel</span>
                                        </div>
                                        <div className="button btn-spinner button-create-new-lease">
                                            {this.getCreateButton(disableButton, creatingTemplate, leaseType)}
                                        </div>
                                    </div>
                                    {createTemplateError && (
                                        <div className="text-danger">
                                            <br />
                                            Something went wrong when creating the template, please try again or contact
                                            us via the live chat
                                        </div>
                                    )}
                                </form>
                            );
                        }}
                    </Form>
                </div>
            </div>
        );
    }

    getCreateButton(disableButton, creatingTemplate) {
        const label = `Create new template`;
        return (
            <Button primary type="submit" disabled={disableButton || creatingTemplate}>
                {creatingTemplate ? <span className="savestatus-spinner-white" /> : null}
                {label}
            </Button>
        );
    }
}

export default connect(
    state => ({
        agency: state.user.agency,
        user: state.user,
        createTemplateError: state.lease.common.createTemplateError,
        cloneTemplate: state.lease.common.cloneTemplate
    }),
    dispatch => bindActionCreators({}, dispatch)
)(CreateNewTemplate);
