import React from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import {
    Root as AccordionRoot,
    Item as AccordionItem,
    Trigger as AccordionTrigger,
    Content as AccordionContent
} from '@radix-ui/react-accordion';
import { createStampAnnotation, createFreeTextAnnotation } from '../utils';
import { Point } from '../types';
import { Tag, TagColor, TagSize } from '../../../common/components/tag/Tag';
import Card, { CardShadow, CardStyles } from '../../../common/components/cards/Card';
import {
    ANNOTATION_SUBJECT_AGENT_NAME,
    ANNOTATION_SUBJECT_CUSTOM_PLACEHOLDER,
    ANNOTATION_SUBJECT_SENDER_NAME,
    AccountType
} from '../../../constants/constants';
import { isAgencyUserAccount } from '../../../utils/userUtils.js';
import senderPlaceHolderImage from '../../../../assets/images/pdf-viewer/Sender signature@4x.png';
import agentPlaceHolderImage from '../../../../assets/images/pdf-viewer/Agent.png';
import Icon, { Icons } from '../../../common/components/Icon';
import { UPLOAD_DOC_SENDER_NAME, CUSTOM_PLACEHOLDERS } from '../../../constants/featureFlags';
import AnnotationButton, { AnnotationButtonTypes } from './AnnotationButton';

import styles from './Sender.module.scss';

type SenderProps = {
    name: string;
    agencyDetails: {
        name: string;
        imageUrl?: string;
    };
    accountType: AccountType;
    webViewerInstance?: WebViewerInstance;
    dropPointRef?: React.MutableRefObject<Point | undefined>;
    agentName: string;
    isOnboarding: boolean;
};
const Sender: React.FC<SenderProps> = ({
    name,
    accountType,
    webViewerInstance,
    agencyDetails,
    dropPointRef,
    agentName,
    isOnboarding
}) => {
    const dragStartOffsetRef = React.useRef({ x: 0, y: 0 });

    const isCustomPlaceholdersActive = useFeatureFlag(CUSTOM_PLACEHOLDERS);
    const isSenderNameActive = useFeatureFlag(UPLOAD_DOC_SENDER_NAME);

    return (
        <Card animate={false} style={CardStyles.SQUARE} className={styles.sender} shadow={CardShadow.NONE}>
            <AccordionRoot type="single" collapsible defaultValue="sender-1">
                <AccordionItem value={`sender-1`}>
                    <AccordionTrigger className={styles.details}>
                        <Icon icon={Icons.CHEVRON_DOWN} className={styles.accordionIcon} />
                        {agencyDetails.imageUrl ? (
                            <img className={styles.logo} src={agencyDetails.imageUrl} />
                        ) : (
                            <Icon className={styles.placeholderIcon} icon={Icons.SENDER_PLACEHOLDER} />
                        )}
                        <Tag text="Sender" color={TagColor.Green} size={TagSize.Small} />
                        <div className={styles.agencyName}>{agencyDetails.name}</div>
                        <div className={styles.senderName}>{name}</div>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className={styles.buttonSection}>
                            <AnnotationButton
                                type={AnnotationButtonTypes.SENDER}
                                webViewerInstance={webViewerInstance}
                                dropPointRef={dropPointRef}
                                createAnnotation={(dropPoint, options) => {
                                    if (webViewerInstance) {
                                        createStampAnnotation(
                                            isAgencyUserAccount(accountType)
                                                ? agentPlaceHolderImage
                                                : senderPlaceHolderImage,
                                            'agent',
                                            webViewerInstance,
                                            dropPoint,
                                            options
                                        );
                                    }
                                }}
                                dragImageSource={
                                    isAgencyUserAccount(accountType) ? agentPlaceHolderImage : senderPlaceHolderImage
                                }
                            >
                                {isAgencyUserAccount(accountType) ? 'Agent Signature' : 'Sender Signature'}
                            </AnnotationButton>
                            {isSenderNameActive && !isOnboarding && (
                                <AnnotationButton
                                    type={AnnotationButtonTypes.SENDER}
                                    icon={<Icon icon={Icons.LABEL} />}
                                    webViewerInstance={webViewerInstance}
                                    dropPointRef={dropPointRef}
                                    createAnnotation={(dropPoint, options) => {
                                        if (webViewerInstance) {
                                            createFreeTextAnnotation(
                                                agentName,
                                                isAgencyUserAccount(accountType)
                                                    ? ANNOTATION_SUBJECT_AGENT_NAME
                                                    : ANNOTATION_SUBJECT_SENDER_NAME,
                                                true,
                                                webViewerInstance,
                                                dropPoint,
                                                options
                                            );
                                        }
                                    }}
                                    dragImageText={agentName}
                                >
                                    {isAgencyUserAccount(accountType) ? 'Agent Name' : 'Sender Name'}
                                </AnnotationButton>
                            )}
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </AccordionRoot>
        </Card>
    );
};

export default Sender;
