import * as React from 'react';
import { Form } from 'react-final-form';
import { has, isEmpty, isString } from 'lodash';
import { updateVendor, getVendorAndProgressSteps } from '../../actions/lease';
import EditVendorPersonGroup from '../lease/mainScreen/common/EditVendorPersonGroup';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import 'sass/tenant.scss';

const EditVendor = () => {
    const dispatch = useDispatch();
    const signatory = useSelector(state => state.lease.signatory);
    const validationErrors = useSelector(state => state.lease.common.validationErrors);
    const agreementInfo = useSelector(state => state.dashboard.agreementInfo);
    const isSubmittingLease = useSelector(state => state.lease.common.isSubmittingLease);

    const isTenantConfirmedError = () => {
        if (
            validationErrors &&
            validationErrors.signatory &&
            validationErrors.signatory.signatories &&
            validationErrors.signatory.signatories[0] &&
            validationErrors.signatory.signatories[0].isEditable
        ) {
            return true;
        }
        return false;
    };
    const isEditable = () => {
        let editable = false;
        signatory.signatories.forEach(user => {
            if (
                isEmpty(user.steps) ||
                !user.steps.steps ||
                !user.steps.steps[1] ||
                user.steps.steps[1].status !== 'confirmed'
            ) {
                editable = true;
            }
        });
        return editable;
    };

    useEffect(() => {
        dispatch(getVendorAndProgressSteps(agreementInfo.id));
    }, []);

    useEffect(() => {
        if (isTenantConfirmedError()) {
            dispatch(getVendorAndProgressSteps(agreementInfo.id));
        }
    }, [validationErrors]);

    const submitValues = values => {
        values.signatories.forEach(vendor => {
            if (typeof vendor.email === 'undefined') {
                vendor.email = '';
            }
        });
        dispatch(updateVendor(agreementInfo.id, values, agreementInfo.deadline));
    };
    return (
        <div className="tenant">
            <Form
                onSubmit={submitValues}
                initialValues={signatory}
                validate={() => {
                    return validationErrors && validationErrors.signatory ? validationErrors.signatory : {};
                }}
            >
                {({ handleSubmit, errors, values }) => {
                    return (
                        <form id="update-tenant-detail-form" onSubmit={handleSubmit} noValidate>
                            <div className="tenants">
                                {validationErrors &&
                                    validationErrors.signatory &&
                                    validationErrors.signatory.signatories &&
                                    validationErrors.signatory.signatories[0] &&
                                    validationErrors.signatory.signatories[0].isEditable && (
                                        <p className="FormInput">
                                            <span className="FormError">
                                                {validationErrors.signatory.signatories[0].isEditable}
                                            </span>
                                        </p>
                                    )}
                                {signatory.signatories.map((vendor, index) => (
                                    <EditVendorPersonGroup key={index} index={index} vendor={vendor} />
                                ))}
                            </div>
                            {has(errors, 'signatories') && isString(errors.signatories) && (
                                <div className="has-error custom-error-message">{errors.signatories}</div>
                            )}
                            <div className="btn-edit-signatory">
                                <button
                                    type="button"
                                    disabled={isSubmittingLease || !isEditable()}
                                    onClick={() => submitValues(values)}
                                >
                                    {isSubmittingLease && <span className="savestatus-spinner-white" />}
                                    Save changes
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default EditVendor;
