import React from 'react';
import cx from 'classnames';
import { capitalize } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';

import AccordionItem from './AccordionItem';
import SelectField from './Select';
import { ClientSection, ClientUiModel } from '../BuildADoc.model';
import { CheckboxCheck } from '../../../../../components/form/FormCheckboxCheck.js';
import ContactSearch from '../../../../../components/search/ContactSearch.js';
import Tooltip from '../../../../../common/components/tooltips/Tooltip';
import useComponentId from '../../../../../hooks/useComponentId';
import Button from '../../../../../common/components/Button.js';
import Icon, { Icons } from '../../../../../common/components/Icon';
import TextInput from './TextInput';
import Listen from './Listen';
import { Tag, TagColor, TagSize } from '../../../../../common/components/tag/Tag';
import { Field } from 'react-final-form';
import CustomLabelTextInput from './CustomLabelTextInput';
import { DOCUMENT_CREATE_A_FLK } from '../../../../../config';

import * as styles from './Client.module.scss';
import { FieldType } from '../BuildADoc.config';
import { MobileInput } from './MobileInput';

type ClientProps = {
    isOpen: boolean;
    id: string;
    namePrefix?: string;
    clientSectionType: ClientSection;
    onRemoveClient: () => void;
    onClearClient: () => void;
    onToggleCorporation: () => void;
    client: ClientUiModel;
    isReadOnly?: boolean;
    onToggleIsPrimary?: (value: boolean) => void;
};

const FROM_TYPE_SELECT_DISABLED_TOOLTIP =
    'To change this, change the first sender, above. Changing one changes all to the same type.';
const TO_TYPE_SELECT_DISABLED_TOOLTIP =
    'To change this, change the first recipient, above. Changing one changes all to the same type.';

const Client: React.FC<ClientProps> = ({
    client: {
        fields: clientFields,
        disableTypeSelect,
        showContactSearch,
        showIsCorporation,
        showIsPrimary,
        showIncludeLandlordAgentDetails,
        typeDisplayName,
        displayName,
        clientNumber,
        tagColor,
        removedFields,
        showClientTypeSelector,
        clientTypeOptions,
        isDeletable,
        sectionDisplayName,
        hasError,
        disableClientDetails
    },
    clientSectionType,
    id = 'test',
    isOpen = true,
    namePrefix,
    onRemoveClient,
    onClearClient,
    onToggleCorporation,
    isReadOnly,
    onToggleIsPrimary
}) => {
    const clientRef = React.useRef<HTMLDivElement>(null);
    // Used to focus on a custom label text input when it is added
    const inputToFocusRef = React.useRef<HTMLInputElement>(null);

    const tooltipId = useComponentId().toString();

    return (
        <AccordionItem
            ref={clientRef}
            className={cx(styles.person)}
            isOpen={isOpen}
            value={id}
            key={id}
            collapsedContent={
                <div className={cx(styles.summary, { [styles.error]: hasError })} data-has-error={hasError && !isOpen}>
                    <span className={styles.title}>{capitalize(typeDisplayName)}</span>
                    <Tag
                        color={hasError ? TagColor.Error : TagColor.Grey}
                        className={cx(styles.number, { [styles.singleDigit]: clientNumber < 10 })}
                        text={clientNumber.toString()}
                    />
                    {displayName && (
                        <Tag color={tagColor} className={styles.displayName} text={displayName} size={TagSize.Small} />
                    )}
                    {hasError && (
                        <Tag
                            className={styles.errorTag}
                            text="Needs Attention"
                            color={TagColor.Error}
                            startIcon={<Icon icon={Icons.ERROR} />}
                            size={TagSize.Small}
                        />
                    )}
                </div>
            }
        >
            <div className={styles.body}>
                <FieldArray name={`${namePrefix}.data`}>
                    {({ fields }) => (
                        <>
                            {showContactSearch && (
                                <ContactSearch
                                    searchBarClassName={cx(styles.searchBar, styles.fullWidth)}
                                    onSelect={(contactData: { [key: string]: string }) => {
                                        fields.forEach((_, fieldIndex) => {
                                            const fieldValue = fields.value[fieldIndex];
                                            const clientField = clientFields[fieldIndex];
                                            if (clientField.mappingName && contactData[clientField.mappingName]) {
                                                fields.update(fieldIndex, {
                                                    ...fieldValue,
                                                    value: contactData[clientField.mappingName]
                                                });
                                            }
                                        });
                                    }}
                                    placeholder="Search contacts or staff"
                                    contactType={DOCUMENT_CREATE_A_FLK}
                                    allowStaffSearch
                                    hideTooltip
                                    disabled={isReadOnly || disableClientDetails}
                                />
                            )}
                            {showClientTypeSelector && (
                                <>
                                    <SelectField
                                        className={cx(styles.typeSelect, styles.fullWidth)}
                                        data-for={tooltipId}
                                        data-tip={
                                            clientSectionType === ClientSection.To
                                                ? TO_TYPE_SELECT_DISABLED_TOOLTIP
                                                : FROM_TYPE_SELECT_DISABLED_TOOLTIP
                                        }
                                        disabled={disableTypeSelect || isReadOnly}
                                        parentRef={clientRef}
                                        label={`${capitalize(sectionDisplayName)} is`}
                                        name={`${clientSectionType}.type`}
                                        options={clientTypeOptions}
                                    />
                                    {disableTypeSelect && (
                                        <Tooltip
                                            className={styles.disabledTypeSelectTooltip}
                                            id={tooltipId}
                                            effect="solid"
                                            place="left"
                                        />
                                    )}
                                </>
                            )}
                            {showIsPrimary && (
                                <>
                                    <CheckboxCheck
                                        containerClassName={styles.fullWidth}
                                        name={`${namePrefix}.isPrimary`}
                                        label={`Is primary ${typeDisplayName}`}
                                        disabled={isReadOnly || disableClientDetails}
                                    />
                                    {onToggleIsPrimary && (
                                        <Listen
                                            to={`${namePrefix}.isPrimary`}
                                            onChange={value => onToggleIsPrimary(value as boolean)}
                                        />
                                    )}
                                </>
                            )}
                            {showIsCorporation && (
                                <>
                                    <CheckboxCheck
                                        containerClassName={styles.fullWidth}
                                        name={`${namePrefix}.isCorporation`}
                                        label={`${capitalize(typeDisplayName)} is a corporation `}
                                        disabled={isReadOnly || disableClientDetails}
                                    />
                                    <Listen to={`${namePrefix}.isCorporation`} onChange={onToggleCorporation} />
                                </>
                            )}
                            {showIncludeLandlordAgentDetails && (
                                <CheckboxCheck
                                    containerClassName={styles.fullWidth}
                                    name={`${clientSectionType}.includeLandlordAgentDetails`}
                                    label="Include agent details"
                                    disabled={isReadOnly}
                                />
                            )}
                            <div className={cx(styles.fields, styles.fullWidth)}>
                                {fields.map((_, index) => {
                                    const fieldName = `${namePrefix}.data[${index}]`;
                                    const fieldValue = fields.value[index];
                                    const clientField = clientFields[index];
                                    const displayLabel = clientField?.displayLabel || fieldValue.label;

                                    if (clientField?.isRemoved) {
                                        return (
                                            <Field
                                                key={fieldName}
                                                name={`${fieldName}.value`}
                                                component="input"
                                                type="hidden"
                                            />
                                        );
                                    } else if (clientField?.isOther) {
                                        return (
                                            <CustomLabelTextInput
                                                validateOnTouch={!hasError}
                                                name={fieldName}
                                                disabled={isReadOnly || disableClientDetails}
                                                showDeleteButton
                                                onDelete={() => {
                                                    fields.remove(index);
                                                }}
                                                labelInputRef={inputToFocusRef}
                                            />
                                        );
                                    } else if (clientField.type === FieldType.Phone) {
                                        return (
                                            <MobileInput
                                                validateOnTouch={!hasError}
                                                key={fieldName}
                                                name={`${fieldName}.value`}
                                                label={displayLabel}
                                                isRequired={clientField?.isRequired}
                                                disabled={isReadOnly || disableClientDetails}
                                            />
                                        );
                                    } else if (
                                        clientField.type === FieldType.Email ||
                                        clientField.type === FieldType.Text
                                    ) {
                                        return (
                                            <TextInput
                                                validateOnTouch={!hasError}
                                                key={fieldName}
                                                name={`${fieldName}.value`}
                                                label={displayLabel}
                                                isRequired={clientField?.isRequired}
                                                disabled={isReadOnly || disableClientDetails}
                                                type={clientField.type === FieldType.Email ? 'email' : 'text'}
                                                showDeleteButton={clientField.isRemovable}
                                                onDelete={() => {
                                                    fields.update(index, {
                                                        ...fieldValue,
                                                        isRemoved: true
                                                    });
                                                }}
                                            />
                                        );
                                    }
                                })}
                            </div>
                            <Button
                                className={styles.addFieldButton}
                                type="button"
                                onClick={() => {
                                    fields.push({
                                        value: '',
                                        isOther: true,
                                        meta: {
                                            order: fields.length
                                        }
                                    });

                                    requestAnimationFrame(() => {
                                        inputToFocusRef.current?.focus();
                                    });
                                }}
                                quaternary
                                disabled={isReadOnly || disableClientDetails}
                            >
                                Add another field
                            </Button>
                            <div className={styles.restorableFields}>
                                {removedFields.map(removedField => (
                                    <Tag
                                        key={removedField.fieldIndex}
                                        className={styles.removedField}
                                        text={removedField.label}
                                        size={TagSize.Small}
                                        startIcon={<Icon icon={Icons.PLUS} className={styles.restoreFieldIcon} />}
                                        onClick={() => {
                                            fields.update(removedField.fieldIndex, {
                                                ...fields.value[removedField.fieldIndex],
                                                isRemoved: false
                                            });
                                        }}
                                        disabled={isReadOnly}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </FieldArray>
                {isDeletable ? (
                    <Button
                        className={styles.removeClientButton}
                        type="button"
                        onClick={onRemoveClient}
                        quaternary
                        startIcon={<Icon icon={Icons.TRASH} className={styles.removeClientIcon} />}
                        disabled={isReadOnly || disableClientDetails}
                    >
                        Delete
                    </Button>
                ) : (
                    <Button
                        onClick={onClearClient}
                        className={styles.removeClientButton}
                        type="button"
                        quaternary
                        disabled={isReadOnly || disableClientDetails}
                    >
                        Clear
                    </Button>
                )}
            </div>
        </AccordionItem>
    );
};

export default Client;
