import React, { Component } from 'react';
import logger from './utils/logger';
import Button from './common/components/Button';

import 'sass/errorPage.scss';
import LiveChatBubble from './common/components/LiveChatBubble';
import { withRouter } from 'react-router';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({ hasError: false });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        logger.push({ status: 'error', env: ENVIRONMENT, message: error.message, errorObj: error, info });
        if (window.rollbar) {
            window.rollbar.critical(
                'Error!',
                {
                    error_message: error.message,
                    status: 'critical',
                    env: ENVIRONMENT
                },
                error
            );
        }
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-page">
                    <div className="white-box">
                        <h1>It looks like something went wrong</h1>

                        <p>Please refresh the page and try again</p>
                        <div className="action-container">
                            <Button
                                primary
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Refresh page
                            </Button>
                            <span>or</span>
                            <Button
                                secondary
                                onClick={() => {
                                    this.props.history.push('/');
                                    window.location.reload();
                                }}
                            >
                                Back to homepage
                            </Button>
                        </div>
                    </div>
                    <p>
                        If this happens again, please contact us via
                        <LiveChatBubble />
                    </p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
