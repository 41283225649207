import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'react-cookie';
import { LEASE_TYPE_PROPERTY_MANAGEMENT } from '../config';
import { getMobileViewEnabled } from '../selectors/dashboard';
import { Link } from 'react-router-dom';
import appHistory from './../AppHistory';
import { openLeaseAgreementForm } from '../actions/lease';
import { toggleMobileView } from '../actions/dashboard';
import Header from './Header';
import agreementIcon from '../../assets/images/icons/agreement-icon.svg';
import dashboardIcon from '../../assets/images/icons/dashboard-icon.svg';
import { useAccountProvider } from '../components/providers/AccountProvider';
import { isAgencyUserAccount } from '../utils/userUtils';
import '../sass/layout.scss';
import '../sass/_welcome.scss';

const Welcome = () => {
    const dispatch = useDispatch();
    const mobileViewEnabled = useSelector(getMobileViewEnabled);
    const [isCreatePmAgreement, setIsCreatePmAgreement] = useState(false);
    const accountType = useAccountProvider();

    useEffect(() => {
        document.title = 'Welcome';
        if (!cookie.load('userFromLogin')) {
            appHistory.push('/');
        }
    }, []);

    const createPmAgreement = () => {
        setIsCreatePmAgreement(true);
        appHistory.push('/dashboard/agreements/property_management/draft');
        dispatch(openLeaseAgreementForm(LEASE_TYPE_PROPERTY_MANAGEMENT, true));
    };

    const toggleView = data => {
        dispatch(toggleMobileView(data));
        appHistory.push('/dashboard/agreements/residential_tenancy/draft');
    };

    return (
        <div className="mobile-view-enabled layout">
            <Header />
            <main className="main">
                <div className="main-content">
                    <div className="content">
                        <div className="welcome">
                            <div className="welcome__header">
                                <h1>Welcome to FLK mobile</h1>
                            </div>
                            <div className="welcome__body welcome__nav">
                                <div className="welcome__nav__subtitle">Create New using Templates</div>
                                <Link to="/dashboard/documents/document_custom/draft?create=document_custom">
                                    <div className="welcome__nav__item">
                                        <div className="welcome__nav__inner">
                                            <div className="welcome__nav__icon">
                                                <img src={agreementIcon} alt="" />
                                            </div>
                                            <div className="welcome__nav__label">
                                                <label>Upload a Doc</label>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                {isAgencyUserAccount(accountType) && (
                                    <div
                                        className={`welcome__nav__item ${
                                            isCreatePmAgreement ? 'welcome__nav__item--active' : ''
                                        }`}
                                        onClick={createPmAgreement}
                                    >
                                        <div className="welcome__nav__inner">
                                            <div className="welcome__nav__icon">
                                                <img src={agreementIcon} alt="Property Management Agreement Icon" />
                                            </div>
                                            <div className="welcome__nav__label">
                                                <label>Property Management Agreement</label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="welcome__nav__subtitle">Go to Dashboard</div>
                                <Link to="/dashboard/documents/document_custom/draft">
                                    <div className="welcome__nav__item">
                                        <div className="welcome__nav__inner">
                                            <div className="welcome__nav__icon">
                                                <img src={dashboardIcon} alt="" />
                                            </div>
                                            <div className="welcome__nav__label">
                                                <label>Upload a Doc</label>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                {isAgencyUserAccount(accountType) && (
                                    <Link to="/dashboard/agreements/property_management/draft">
                                        <div className="welcome__nav__item">
                                            <div className="welcome__nav__inner">
                                                <div className="welcome__nav__icon">
                                                    <img src={dashboardIcon} alt="Property Management Dashboard Icon" />
                                                </div>
                                                <div className="welcome__nav__label">
                                                    <label>Property Management Agreement</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            <div className="welcome__footer">
                                {mobileViewEnabled && (
                                    <div className="welcome__footer--link" onClick={() => toggleView(false)}>
                                        View desktop experience
                                        <br />
                                        for more options
                                    </div>
                                )}
                                {!mobileViewEnabled && (
                                    <div className="welcome__footer--link" onClick={() => toggleView(true)}>
                                        Show mobile experience
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Welcome;
