import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TemplateTable from './components/TemplateTable';
import '../../../sass/settings/templates/templates.scss';
import { getTemplates, deleteAgreementTemplate, editAgreementTemplate } from './../../../actions/template';
import { openDocumentForm } from './../../../actions/document';
import { getTemplatesSorting, getTemplatesRefreshPagination } from '../../../selectors/dashboard/templates';
import { getIsEditingTemplateFinish } from '../../../selectors/lease';
import { isUserRoleAdmin } from '../../../selectors/user';
import { getUser } from '../../../selectors/user';
import { confirmAlert } from 'react-confirm-alert';
import { getTemplatesForLeaseType } from '../../../selectors/dashboard/templates';
import { getTemplatesPaginationByType } from '../../../selectors/dashboard/templates';
import {
    DOCUMENT_CUSTOM,
    DOCUMENT_TEMPLATE_MODE,
    DOCUMENT_TEMPLATE_READ_ONLY_MODE,
    FLK_A_PDF_GLOBAL_TEMPLATE
} from '../../../config';
import { cloneDeep, includes } from 'lodash';

const FlkAPdfTemplate = ({ templateType }) => {
    const dispatch = useDispatch();
    const templateList = useSelector(state => getTemplatesForLeaseType(state, templateType));
    const user = useSelector(getUser);
    const isAdmin = useSelector(isUserRoleAdmin);
    const templatesPagination = useSelector(state => getTemplatesPaginationByType(state, templateType));
    const templatesSorting = useSelector(state => getTemplatesSorting(state, templateType));
    const refreshPagination = useSelector(getTemplatesRefreshPagination);
    const isEditingTemplateFinish = useSelector(getIsEditingTemplateFinish);

    useEffect(() => {
        dispatch(
            getTemplates(
                templateType,
                templatesPagination.page,
                templatesPagination.limit,
                templatesSorting.field,
                templatesSorting.direction
            )
        );
    }, [dispatch, isEditingTemplateFinish, refreshPagination]);

    function openCreateTemplateForm() {
        dispatch(
            openDocumentForm(DOCUMENT_CUSTOM, null, DOCUMENT_TEMPLATE_MODE, templateType === FLK_A_PDF_GLOBAL_TEMPLATE)
        );
    }

    function deleteTemplate(template) {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete this template?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(deleteAgreementTemplate(template.id, templateType));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function editTemplate(template) {
        dispatch(
            openDocumentForm(
                DOCUMENT_CUSTOM,
                template,
                DOCUMENT_TEMPLATE_MODE,
                templateType === FLK_A_PDF_GLOBAL_TEMPLATE
            )
        );
    }

    function cloneTemplate(template) {
        const templateObject = cloneDeep(template);
        templateObject.isCreatedFromTemplate = true;
        templateObject.isCloneFromTemplate = true;
        dispatch(
            openDocumentForm(
                DOCUMENT_CUSTOM,
                templateObject,
                DOCUMENT_TEMPLATE_MODE,
                templateType === FLK_A_PDF_GLOBAL_TEMPLATE
            )
        );
    }

    function changeTemplatePage(page) {
        dispatch(
            getTemplates(
                templateType,
                page,
                templatesPagination.limit,
                templatesSorting.field,
                templatesSorting.direction
            )
        );
    }

    function viewTemplate(template) {
        dispatch(
            openDocumentForm(
                DOCUMENT_CUSTOM,
                template,
                DOCUMENT_TEMPLATE_READ_ONLY_MODE,
                templateType === FLK_A_PDF_GLOBAL_TEMPLATE
            )
        );
    }

    function changeSortOptions(options) {
        let field = Object.keys(options)[0];
        let direction = options[field];
        dispatch(getTemplates(templateType, templatesPagination.page, templatesPagination.limit, field, direction));
    }

    return (
        <div className="settings">
            <div className="templates-page">
                <div className="templates-section">
                    <TemplateTable
                        templateType={templateType}
                        isAdmin={isAdmin}
                        templates={templateList}
                        templatePagination={templatesPagination}
                        templatesSorting={templatesSorting}
                        addVisibilitySwitch={!user.userInfo.isSuperAdmin}
                        createNewTemplate={openCreateTemplateForm}
                        deleteTemplate={deleteTemplate}
                        editTemplate={editTemplate}
                        viewTemplate={viewTemplate}
                        cloneTemplate={cloneTemplate}
                        changePage={changeTemplatePage}
                        changeSortOptions={changeSortOptions}
                        isDocumentTemplate={true}
                        blockActions={templateType === FLK_A_PDF_GLOBAL_TEMPLATE && !user.userInfo.isSuperAdmin}
                    />
                </div>
            </div>
        </div>
    );
};

export default FlkAPdfTemplate;
