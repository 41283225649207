import React from 'react';
import cx from 'classnames';
import Button from '../../common/components/Button.js';
import { ReactComponent as RemoveIcon } from '../../../assets/images/icons/icon_minuseInSquare_gray.svg';
import styles from './FormSection.module.scss';
import Tooltip from '../../common/components/tooltips/Tooltip';
import useComponentId from '../../hooks/useComponentId';

type FormSectionProps = React.PropsWithChildren<{
    className?: string;
    title?: string;
    showRemoveButton?: boolean;
    removeButtonProps?: {
        onClick: () => void;
        label: string;
        icon?: React.ReactNode;
        disabled?: boolean;
        disabledTooltipText?: string;
        type?: string;
        spinnerClassName?: string;
        loading: boolean;
    };
}>;

const FormSection: React.FC<FormSectionProps> = ({
    className,
    title,
    showRemoveButton,
    removeButtonProps,
    children
}) => {
    const tooltipId = useComponentId().toString();
    return (
        <div className={cx(styles.container, className)}>
            <div className={styles.header}>
                {title && <h3 className={styles.title}>{title}</h3>}
                {showRemoveButton && removeButtonProps && (
                    // This div is needed for the tool tip to display correctly on the button
                    <div
                        data-tip={
                            removeButtonProps?.disabled && removeButtonProps?.disabledTooltipText
                                ? removeButtonProps?.disabledTooltipText
                                : ''
                        }
                        data-for={tooltipId}
                    >
                        <Button
                            loading={removeButtonProps.loading}
                            className={cx(styles.removeButton)}
                            onClick={removeButtonProps.onClick}
                            disabled={removeButtonProps.disabled}
                            type={removeButtonProps.type ? removeButtonProps.type : 'button'}
                            spinnerClassName={'button-spinner-form-section'}
                        >
                            <RemoveIcon />
                            {removeButtonProps.label}
                        </Button>
                    </div>
                )}
                <Tooltip effect={'solid'} place={'top'} id={tooltipId} />
            </div>
            <div className={styles.items}>{children}</div>
        </div>
    );
};

export default FormSection;
