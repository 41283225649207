import React from 'react';
import { CLIENT_OTHER, CLIENT_TRADING_NAME, clientDefaultFields } from '../../../../config';
import { getClientTypeFromDoc } from '../../../../utils/documentUtils';
import SummaryItem from './SummaryItem';
import { startCase } from 'lodash';
import { isBusinessUserAccount } from '../../../../utils/userUtils';
import { useAccountProvider } from '../../../../components/providers/AccountProvider';

const ClientSummary = ({ currentDocument, type }) => {
    const accountType = useAccountProvider();
    let clientType = getClientTypeFromDoc(currentDocument, type);
    if ([CLIENT_OTHER, CLIENT_TRADING_NAME].includes(clientType)) {
        clientType = type.toUpperCase();
    }

    const clientTitle = isBusinessUserAccount(accountType) ? clientType : `${startCase(type)}: ${clientType}`;
    return (
        <div className="client-summary summary-item">
            <h2>{clientTitle}</h2>
            <div className="client-item-group">
                {currentDocument[type].clients.map((client, index) => {
                    const items = [
                        {
                            value: client.fullName,
                            isBold: true
                        },
                        {
                            value: client.email
                        },
                        {
                            value: client.mobile
                        }
                    ];

                    const additionalSummaryItems = client.data
                        .filter(field => field.showField && !clientDefaultFields.includes(field.label) && field.value)
                        .map(data => ({
                            value: data.other ? `${data.label}: ${data.value}` : data.value
                        }));
                    return <SummaryItem key={index} items={[...items, ...additionalSummaryItems]} />;
                })}
            </div>
        </div>
    );
};
export default ClientSummary;
