import cx from 'classnames';
import React from 'react';

import Icon, { Icons } from '@app/common/components/Icon';
import Card, { CardShadow, CardStyles } from '@app/common/components/cards/Card';
import { Tag, TagColor, TagSize } from '@app/common/components/tag/Tag';
import { Client as UploadADocClient } from '@app/types/UploadADoc';
import { getTagColour } from './utils';

import styles from './RecipientSummary.module.scss';

type Client = Partial<Pick<UploadADocClient, 'name' | 'email' | 'phone' | 'source' | 'signingOrder'>>;

type RecipientSummaryProps = {
    item: Client;
    index: number;
    hasError?: boolean;
};

export const RecipientSummaryContent: React.FC<RecipientSummaryProps> = ({ item, index, hasError }) => {
    const { name, email, phone } = item;
    const itemNumber = index + 1;
    return (
        <div
            className={cx(styles.recipientSummary, {
                [styles.error]: hasError,
                [styles.withEmail]: !!email
            })}
            data-has-error={hasError}
        >
            <Tag
                color={getTagColour(index)}
                className={cx(styles.number, {
                    [styles.singleDigit]: itemNumber < 10
                })}
                text={itemNumber.toString()}
                size={TagSize.X_Small}
            />
            {name && <div className={styles.name}>{name}</div>}
            {email && <div className={styles.email}>{email}</div>}
            {phone && <div className={styles.phone}>{phone}</div>}
            {hasError && (
                <Tag
                    className={styles.errorTag}
                    text="Needs Attention"
                    color={TagColor.Error}
                    startIcon={<Icon icon={Icons.ERROR} />}
                    size={TagSize.Small}
                />
            )}
        </div>
    );
};

export const RecipientSummary: React.FC<RecipientSummaryProps> = ({ item, index, hasError }) => {
    return (
        <Card key={index} style={CardStyles.SQUARE} shadow={CardShadow.SMALL}>
            <RecipientSummaryContent item={item} index={index} hasError={hasError} />
        </Card>
    );
};
