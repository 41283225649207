import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TemplateTable from './components/TemplateTable';
import '../../../sass/settings/templates/templates.scss';
import {
    openAgreementTemplateForm,
    getTemplates,
    deleteAgreementTemplate,
    editAgreementTemplate
} from './../../../actions/template';
import { getTemplatesSorting, getTemplatesRefreshPagination } from '../../../selectors/dashboard/templates';
import { getIsEditingTemplateFinish } from '../../../selectors/lease';
import { isUserRoleAdmin } from '../../../selectors/user';
import { getUser } from '../../../selectors/user';
import { confirmAlert } from 'react-confirm-alert';
import { getTemplatesForLeaseType } from '../../../selectors/dashboard/templates';
import { getTemplatesPaginationByType } from '../../../selectors/dashboard/templates';
import { TEMPLATE_TYPE_HOLIDAY_LETTING } from '../../../config';
import { openSubapp } from '../../../utils/openSubapp';
import {
    HOLIDAY_LETTING_TEMPLATE_SUBAPP_PATH,
    HOLIDAY_LETTING_EDIT_NSW_TEMPLATE_SUBAPP_PATH
} from '../../../constants/constants';

const Templates = ({ leaseType }) => {
    const dispatch = useDispatch();
    const templateList = useSelector(state => getTemplatesForLeaseType(state, leaseType));
    const user = useSelector(getUser);
    const isAdmin = useSelector(isUserRoleAdmin);
    const templatesPagination = useSelector(state => getTemplatesPaginationByType(state, leaseType));
    const templatesSorting = useSelector(state => getTemplatesSorting(state, leaseType));
    const refreshPagination = useSelector(getTemplatesRefreshPagination);
    const isEditingTemplateFinish = useSelector(getIsEditingTemplateFinish);

    useEffect(() => {
        dispatch(
            getTemplates(
                leaseType,
                templatesPagination.page,
                templatesPagination.limit,
                templatesSorting.field,
                templatesSorting.direction
            )
        );
    }, [dispatch, isEditingTemplateFinish, refreshPagination]);

    function openCreateTemplateForm() {
        // Open holiday letting template from the new app if holiday letting template is selected
        if (leaseType === TEMPLATE_TYPE_HOLIDAY_LETTING) {
            openSubapp(HOLIDAY_LETTING_TEMPLATE_SUBAPP_PATH);
        } else {
            dispatch(openAgreementTemplateForm(leaseType));
        }
    }

    function deleteTemplate(template) {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete this template?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(deleteAgreementTemplate(template.id, leaseType));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function editTemplate(template) {
        // Open holiday letting template from the new app if holiday letting template is selected
        if (leaseType === TEMPLATE_TYPE_HOLIDAY_LETTING) {
            openSubapp(`${HOLIDAY_LETTING_EDIT_NSW_TEMPLATE_SUBAPP_PATH}/${template.id}`);
        } else {
            dispatch(editAgreementTemplate(user, template));
        }
    }

    function cloneTemplate(template) {
        dispatch(
            openAgreementTemplateForm(leaseType, {
                label: template.templateName,
                value: template.id,
                type: 'template',
                location: template.location
            })
        );
    }

    function changeTemplatePage(page) {
        dispatch(
            getTemplates(leaseType, page, templatesPagination.limit, templatesSorting.field, templatesSorting.direction)
        );
    }

    function changeSortOptions(options) {
        let field = Object.keys(options)[0];
        let direction = options[field];
        dispatch(getTemplates(leaseType, templatesPagination.page, templatesPagination.limit, field, direction));
    }

    return (
        <div className="settings">
            <div className="templates-page">
                <div className="templates-section">
                    <TemplateTable
                        templateType={leaseType}
                        isAdmin={isAdmin}
                        templates={templateList}
                        templatePagination={templatesPagination}
                        templatesSorting={templatesSorting}
                        createNewTemplate={openCreateTemplateForm}
                        deleteTemplate={deleteTemplate}
                        editTemplate={editTemplate}
                        cloneTemplate={cloneTemplate}
                        changePage={changeTemplatePage}
                        changeSortOptions={changeSortOptions}
                    />
                </div>
            </div>
        </div>
    );
};

export default Templates;
