import React from 'react';
import { useUpdate } from '../providers/UpdateProvider';
import HeaderBanner from './HeaderBanner';
import styles from './UpdateBanner.module.scss';
import Button from '../../common/components/Button';

export default function UpdateBanner() {
    const hasUpdate = useUpdate();

    return hasUpdate ? (
        <HeaderBanner>
            <div className={styles.bannerBody}>
                <div className={styles.emoji}>
                    <span role="img" aria-label="waving hand">
                        &#x1F44B;
                    </span>
                </div>
                <div className={styles.text}>
                    There is a new version of <strong>FLK it over</strong> available, please refresh your browser to
                    update.
                </div>
                <Button primary className={styles.bannerButton} height="40" onClick={() => window.location.reload()}>
                    Update
                </Button>
            </div>
        </HeaderBanner>
    ) : null;
}
