import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import React from 'react';
import { useEffect } from 'react';
import { HUBSPOT } from '../../constants/featureFlags';

const scriptID = 'hs-script-loader';

export function HubspotProvider({ children }) {
    const isHubspotEnabled = useFeatureFlag(HUBSPOT);
    useEffect(() => {
        let script = document.getElementById(scriptID);
        // eslint-disable-next-line no-undef
        if (HUBSPOT_TRACKING_CODE && !script && isHubspotEnabled) {
            // eslint-disable-next-line no-undef
            const scriptSource = `//js.hs-scripts.com/${HUBSPOT_TRACKING_CODE}.js`;
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = scriptSource;
            script.id = scriptID;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            // only provide a cleanup if we set have created the script
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [isHubspotEnabled]);

    return <>{children}</>;
}
