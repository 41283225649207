import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../form/Select';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Button from '../../common/components/Button';
import { deleteUser } from '../../actions/joinTeam';
import ModalDialog from '../../common/components/ModalDialog';

import styles from './ConfirmModal.module.scss';
import { PreLoader } from '../../common/components/PreLoader';
import { formatDateWithTimezoneAndFormat, getAgencyTimezoneFromUser } from '../../utils/dateUtils';
import { getUserInfo } from '../../selectors/user';
import { STANDARD_DATE_DAY_FORMAT_WITH_TH } from '../../config';

function DeleteUserConfirmModal({ isOpen, setIsOpenDeleteUserModal, team, user }) {
    const dispatch = useDispatch();

    const loggedInUser = useSelector(getUserInfo);

    const [selectedPopupUser, setSelectedPopupUser] = useState(false);
    const [defaultOption, setDefaultOption] = useState(false);
    const [popupUserOptions, setPopupUserOptions] = useState([]);

    useEffect(() => {
        const options = [];

        team.forEach(teamUser => {
            if (teamUser.status === 'active' && teamUser.id !== user.id) {
                options.push({ value: teamUser.id, label: teamUser.fullName });
            }
        });
        setDefaultOption(getDefaultOption(options));
        setSelectedPopupUser(getDefaultOption(options));
        setPopupUserOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team]);

    const checkUserScheduledLeases = useQuery(
        ['checkUserScheduledLeases', user],
        async () => {
            const { data } = await axios.get(`/api/user/scheduled-leases/${user.id}`);
            return data;
        },
        // We need updated lease list. Therefore i changed the default 5 min cache and stale to 5s
        { cacheTime: 5000, staleTime: 5000 }
    );

    function handleChange(selectedPopupUser) {
        setSelectedPopupUser(selectedPopupUser);
    }

    function getDefaultOption(popupUserOptions) {
        return popupUserOptions.find(option => {
            return option.value === loggedInUser.id;
        });
    }

    return (
        <ModalDialog
            isOpen={isOpen}
            title="Delete User"
            closeModal={() => setIsOpenDeleteUserModal(false)}
            shouldCloseOnOverlayClick={false}
            className={styles.confirmModal}
        >
            <div className={styles.minHeightSection}>
                Please select which user should inherit all agreements and everything that is assigned to the user you
                are deleting:
                <br />
                <br />
                <div>
                    <Select
                        name="users"
                        id="users"
                        isSearchable={false}
                        options={popupUserOptions}
                        onChange={handleChange}
                        defaultValue={defaultOption}
                    />
                </div>
                {checkUserScheduledLeases.isLoading && (
                    <div className={styles.loaderSection}>
                        <PreLoader />
                        <p>Fetching document information…</p>
                    </div>
                )}
                {checkUserScheduledLeases?.data?.agreementList?.length > 0 && (
                    <div>
                        <br />
                        <p>
                            The user you are deleting has future Notice to Leave documents scheduled to send. These will
                            be assigned to you and you will be the signing agent on these documents when sent.
                        </p>
                        <br />
                        <table className={styles.agreementDetails}>
                            {checkUserScheduledLeases.data.agreementList.map((lease, index) => (
                                <tr key={index}>
                                    <td>{lease.address}</td>
                                    <td> - </td>
                                    <td>
                                        {formatDateWithTimezoneAndFormat(
                                            lease.renewalNoticeToLeaveDetails.noticeToLeaveDate,
                                            getAgencyTimezoneFromUser(loggedInUser),
                                            STANDARD_DATE_DAY_FORMAT_WITH_TH
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </table>
                        <br />
                    </div>
                )}
            </div>
            <div className={styles.footerButtons}>
                <Button
                    type="button"
                    className={styles.actionButton}
                    onClick={() => {
                        dispatch(deleteUser(user.id, selectedPopupUser.value));
                        setIsOpenDeleteUserModal(false);
                    }}
                    danger
                    loading={checkUserScheduledLeases.isLoading}
                >
                    Delete user
                </Button>
                <Button
                    type="button"
                    className={styles.actionButton}
                    onClick={() => setIsOpenDeleteUserModal(false)}
                    primary
                >
                    Cancel
                </Button>
            </div>
        </ModalDialog>
    );
}

export default DeleteUserConfirmModal;
