import React from 'react';
import useCountry from '@app/hooks/useCountry';

import styles from './Integration.module.scss';

const ListNowIntegration = () => {
    const { isNewZealand } = useCountry();

    return isNewZealand ? (
        <div className={`panel ${styles.container}`}>
            <h2 className="panel-header">LIST NOW</h2>
            <div className="panel-body">
                <p className={styles.apiKey}>Connection instructions:</p>
                <ol>
                    <li>
                        Submit a request to turn on the integration with List Now{' '}
                        <a
                            href="https://realflowfinance.atlassian.net/servicedesk/customer/portal/4/group/6/create/35"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                    </li>
                    <li>List Now and FLK it over will set up the integration within one business day</li>
                    <li>
                        FLK it over will send you an email confirmation once the integration set up has been completed
                    </li>
                </ol>
                <p>
                    <i>
                        Please note, you will be able to create & send documents from FLK it over while the integration
                        is being set up.
                    </i>
                </p>
                <p>
                    Not a List Now customer? Contact <a href="mailto:karen@listnow.co.nz">karen@listnow.co.nz</a> to
                    enquire.
                </p>
            </div>
        </div>
    ) : null;
};

export default ListNowIntegration;
